<template>
  <div class="readable">
    <div class="mb-4">
      <p class="mozzarella opacity-48">
        Описание работы
      </p>
      <p class="feta opacity-72 mt-1">
        {{ work.details.description || '-' }}
      </p>
    </div>
    <div class="mb-4">
      <p class="mozzarella opacity-48">
        Вид работы
      </p>
      <p class="feta opacity-72 mt-1">
        {{ work.details.isIncident ? 'Аварийная' : 'Плановая' }}
      </p>
    </div>
    <div class="mb-4">
      <p class="mozzarella opacity-48">
        Фото до начала работ
      </p>
      <p class="feta opacity-72 mt-2">
        <template v-if="!!work.details.images.length">
          <r-file
            v-for="file of work.details.images"
            :key="file.url"
            :file="{
              type: file.contentType,
              ...file,
            }"
            :title="file.filename"
            type-view="MINI"
          />
        </template>
        <template v-else>
          -
        </template>
      </p>
    </div>
    <div class="mb-4">
      <p class="mozzarella opacity-48">
        Общий срок проведения работ
      </p>
      <p class="feta opacity-72 mt-1">
        {{
          new Date(work.details.dateFrom).toLocaleDateString()
        }}–{{ new Date(work.details.dateTo).toLocaleDateString() }}
      </p>
    </div>
    <div class="mb-4">
      <p class="mozzarella opacity-48">
        Включая восстановление благоустройства
      </p>
      <p class="feta opacity-72 mt-1">
        {{
          new Date(work.details.dateFrom2).toLocaleDateString()
        }}–{{ new Date(work.details.dateTo2).toLocaleDateString() }}
      </p>
    </div>
    <div class="mb-8">
      <p class="mozzarella opacity-48">
        Продление производства работ
      </p>
      <p class="feta opacity-72 mt-1">
        {{
          new Date(work.details.dateContinuedFrom).toLocaleDateString()
        }}–{{ new Date(work.details.dateContinuedTo).toLocaleDateString() }}
      </p>
    </div>
    <h3 class="bryndza mb-4">
      Разрешение на производство земляных работ
    </h3>
    <div class="mb-4">
      <p class="mozzarella opacity-48">
        Вид разрешения
      </p>
      <p class="feta opacity-72 mt-1">
        {{ work.details.woOrder ? 'Без производства' : 'С производством' }}
      </p>
    </div>
    <div class="mb-6">
      <p class="mozzarella opacity-48">
        Номер разрешения
      </p>
      <p class="feta opacity-72 mt-1">
        {{ work.details.orderWriteNumber + ' от ' + work.details.orderDate || '-' }}
      </p>
    </div>
    <div class="mb-4">
      <p class="mozzarella opacity-48">
        Приказ на продление сроков работ
      </p>
      <p class="feta opacity-72 mt-1">
        {{
          work.details.continuationWorkOrderNo + ' от ' + work.details.continuationWorkOrderDate || '-'
        }}
      </p>
    </div>
    <div class="mb-4">
      <p class="mozzarella opacity-48">
        Приказ на закрытие работ
      </p>
      <p class="feta opacity-72 mt-1">
        {{ work.details.endWorkOrderNo + ' от ' + work.details.endWorkOrderDate || '-' }}
      </p>
    </div>
    <h3 class="bryndza mb-4">
      Заявитель
    </h3>
    <div class="mb-4">
      <p class="mozzarella opacity-48">
        Юридический статус
      </p>
      <p class="feta opacity-72 mt-1">
        {{ work.details.applicantStatus === 0 ? 'Физическое лицо' : 'Юридическое лицо' }}
      </p>
    </div>
    <div
      class="mb-8"
      v-if="work.details.applicantStatus === 0"
    >
      <div class="mb-4">
        <p class="mozzarella opacity-48">
          Фамилия
        </p>
        <p class="feta opacity-72 mt-1">
          {{ work.details.applicantSurname }}
        </p>
      </div>

      <div class="flex mb-4">
        <div class="mr-8">
          <p class="mozzarella opacity-48">
            Имя
          </p>
          <p class="feta opacity-72 mt-1">
            {{ work.details.applicantName }}
          </p>
        </div>
        <div>
          <p class="mozzarella opacity-48">
            Отчество
          </p>
          <p class="feta opacity-72 mt-1">
            {{ work.details.applicantPatronymic }}
          </p>
        </div>
      </div>
      <div class="mb-4">
        <p class="mozzarella opacity-48">
          Адрес регистрации
        </p>
        <p class="feta opacity-72 mt-1">
          {{ work.details.applicantAddress }}
        </p>
      </div>
      <div class="flex mb-4">
        <div class="mr-8">
          <p class="mozzarella opacity-48">
            Телефон
          </p>
          <p class="feta opacity-72 mt-1">
            {{ work.details.firmPhone }}
          </p>
        </div>
        <div>
          <p class="mozzarella opacity-48">
            Имейл
          </p>
          <p class="feta opacity-72 mt-1">
            {{ work.details.applicantEmail }}
          </p>
        </div>
      </div>
    </div>
    <div
      class="mb-8"
      v-if="work.details.applicantStatus === 1"
    >
      <div class="mb-4">
        <p class="mozzarella opacity-48">
          Наименование
        </p>
        <p class="feta opacity-72 mt-1">
          {{ work.details.firmId }}
        </p>
      </div>
      <div class="mb-4">
        <p class="mozzarella opacity-48">
          ИНН
        </p>
        <p class="feta opacity-72 mt-1">
          {{ work.details.inn }}
        </p>
      </div>
      <div class="flex mb-4">
        <div class="mr-8">
          <p class="mozzarella opacity-48">
            Телефон
          </p>
          <p class="feta opacity-72 mt-1">
            {{ work.details.firmPhone }}
          </p>
        </div>
        <div>
          <p class="mozzarella opacity-48">
            Имейл
          </p>
          <p class="feta opacity-72 mt-1">
            {{ work.details.applicantEmail }}
          </p>
        </div>
      </div>
      <div class="mb-4">
        <p class="mozzarella opacity-48">
          Доверенное лицо
        </p>
        <p class="feta opacity-72 mt-1">
          {{ work.details.firmDir }}
        </p>
      </div>
      <div class="mb-4">
        <p class="mozzarella opacity-48">
          Почтовый адрес
        </p>
        <p class="feta opacity-72 mt-1">
          {{ work.details.postAddress }}
        </p>
      </div>
    </div>
    <h3 class="bryndza mb-4">
      Рекультивация и восстановление
    </h3>
    <div class="mb-4">
      <p class="mozzarella opacity-48">
        Работы по восстановлению
      </p>
      <p class="feta opacity-72 mt-1">
        {{ work.details.revegetation || '-' }}
      </p>
    </div>
    <div class="mb-4">
      <p class="mozzarella opacity-48">
        Способ ведения земляных работ
      </p>
      <p class="feta opacity-72 mt-1">
        {{ work.details.methodOfWork || '-' }}
      </p>
    </div>
    <div class="mb-4">
      <p class="mozzarella opacity-48">
        Гарантийный срок устранения дефектов
      </p>
      <p class="feta opacity-72 mt-1">
        {{ work.details.guarantee || '-' }}
      </p>
    </div>
    <div class="mb-8 grid">
      <div
        v-for="item of work.details.improvements"
        :key="item.id"
      >
        <p class="mozzarella opacity-48">
          {{ item.name }}, м2
        </p>
        <p class="feta opacity-72 mt-1">
          {{ item.square }}
        </p>
      </div>
    </div>

    <div class="mb-4">
      <p class="mozzarella opacity-48">
        Ограничение движения
      </p>
      <p class="feta opacity-72 mt-1">
        {{
          new Date(work.details.dateFromFence).toLocaleDateString()
        }}–{{ new Date(work.details.dateToFence).toLocaleDateString() }}
      </p>
    </div>
  </div>
</template>

<script>

export default {
  name: 'WorkOnlyReadable',
  props: {
    work: {
      type: Object,
      required: true
    }
  }
};
</script>

<style scoped lang="scss">
.grid {
  grid-template-columns: 1fr 1fr;
  grid-column-gap: 32px;
  grid-row-gap: 16px;
  justify-content: space-between;
}

.dash {
  width: 8px;
  height: 1px;
  background-color: var(--rir-rocky);
  margin: auto;
}

.m-auto {
  margin: auto;
}

.improvement {
  display: grid;
  grid-gap: 24px;

  &__item {
    padding: 24px;
    background: #F6F9FE;
    border-radius: 16px;
    display: flex;
    align-items: center;
    width: 100%;
  }
}

</style>
