var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{ref:"table",staticClass:"table"},[_c('scroll-bar',{attrs:{"options":{ alwaysShowTracks: true }},on:{"scroll":_vm.scrollHandler}},[(_vm.headings.length > 0)?_c('div',{ref:"tableHead",staticClass:"table_head"},[_c('div',{staticClass:"table_row mozzarella",class:{ hidden: !_vm.checkboxes },style:(!!_vm.columnsGrid
          ? ('grid-template-columns:' + (_vm.checkboxes ? '16px ' : '') + (_vm.draggable ? '115px ' : '') + _vm.columnsGrid.join(' '))
          : 'grid-auto-flow:column;')},[(_vm.checkboxes)?_c('div',{staticClass:"table_col"},[_c('r-checkbox',{staticClass:"mr-2",attrs:{"val":_vm.rows,"return-object":""},on:{"input":_vm.choiceAll},model:{value:(_vm.allChoice),callback:function ($$v) {_vm.allChoice=$$v},expression:"allChoice"}})],1):_vm._e(),(_vm.draggable)?_c('div',{staticClass:"table_col opacity-48"},[_vm._v(" Позиция в списке ")]):_vm._e(),_vm._l((_vm.headings),function(head,index){return _c('div',{key:index,staticClass:"table_col opacity-48"},[_vm._v(" "+_vm._s(head)+" ")])})],2)]):_vm._e(),_c('draggable',_vm._b({staticClass:"table_body",class:{ 'pb-100px': _vm.checkboxes },attrs:{"list":_vm.filteredRows,"handle":".drag_handle","move":_vm.dragMove},on:{"start":function($event){_vm.drag = true},"end":_vm.dragEnd}},'draggable',_vm.dragOptions,false),[_c('transition-group',{attrs:{"type":"transition","name":!_vm.drag ? 'flip-list' : null,"tag":"div"}},_vm._l((_vm.filteredRows),function(row,index){return _c('div',{key:index + '1',staticClass:"table_row",class:{ error: _vm.checkboxes ? (row.errored_fields.length > 0) : false, hidden: !_vm.checkboxes },style:(!!_vm.columnsGrid
            ? ('grid-template-columns:' + (_vm.checkboxes ? '16px ' : '') + (_vm.draggable ? '115px ' : '') + _vm.columnsGrid.join(' '))
            : 'grid-auto-flow:column;')},[(_vm.checkboxes)?_c('div',{staticClass:"table_col"},[_c('section',{staticClass:"r-tooltip"},[_c('r-checkbox',{staticClass:"mr-2",attrs:{"val":row,"return-object":"","disabled":row.errored_fields.length > 0},on:{"input":function($event){return _vm.$emit('changed', _vm.choicest)},"click":_vm.clickCheckbox},model:{value:(_vm.choicest),callback:function ($$v) {_vm.choicest=$$v},expression:"choicest"}}),(row.errored_fields.length > 0)?_c('section',{staticClass:"r-tooltip__wrapper color-titanic mozzarella"},[_vm._v(" Чтобы выбрать этого участника согласования, "),_c('span',{staticClass:"color-fargo mozzarella"},[_vm._v("укажите "+_vm._s(_vm.errorFields(row.errored_fields)))]),_vm._v(" в карточке организации "),_c('div',{staticClass:"r-tooltip__wrapper--arrow"})]):_vm._e()],1)]):_vm._e(),(_vm.draggable)?_c('div',{staticClass:"table_col feta opacity-48 flex align-items-center"},[_c('div',{staticClass:"mr-7 inline-flex draggable align-items-center"},[_c('r-icon',{staticClass:"opacity-32 drag_handle",attrs:{"size":"16","icon":"menu","fill":"titanic"}})],1),_vm._v(" "+_vm._s(index + 1)+" ")]):_vm._e(),_vm._l((row.details),function(col,index){return _c('div',{staticClass:"table_col",class:{
              feta: index === 'title',
              mozzarella: index !== 'title',
              'color-fargo': !col && _vm.checkboxes && index === 'ceoName',
            }},[_vm._v(" "+_vm._s(!!col ? col : _vm.checkboxes ? index === 'ceoName' ? 'Необходимо заполнить' : '-' : '-')+" ")])}),(!_vm.checkboxes)?_c('div',{directives:[{name:"show",rawName:"v-show",value:(!_vm.drag),expression:"!drag"}],staticClass:"table_row__delete",style:('right:' + _vm.position + 'px'),on:{"click":function($event){return _vm.DeleteItem($event, row.id)}}},[_c('r-icon',{staticClass:"opacity-72",attrs:{"size":"16","icon":"delete","fill":"fargo"}})],1):_vm._e()],2)}),0)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }