<template>
  <div class="modal_report">
    <h3 class="camembert">
      {{ title }}
    </h3>
    <p
      class="feta opacity-72 mt-3"
      v-if="!!street"
    >
      {{ street }}
    </p>
    <p
      class="feta  mt-3"
      v-if="type === 'APPROVAL_NO'"
    >
      В согласовании заявления «{{ projectName }}» будет отказано
    </p>
    <p
      class="feta  mt-3"
      v-if="type === 'APPROVAL_YES'"
    >
      Заявление «{{ projectName }}» будет согласовано
    </p>
    <h6
      class="bryndza mt-6 mb-4"
      v-if="coordinationETSP"
    >
      Электронная цифровая подпись
    </h6>
    <div
      class="field_wrapper"
      v-if="coordinationETSP"
    >
      <upload-file
        :accept-file="[
          'image/jpeg',
          'image/png',
          'image/gif',
          'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
          'application/pdf',
          'application/msword',
          'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
          'text/csv',
        ]"
        :count-file="5"
        upload-url="/uploadfile/index.php"
        :value="alignmentPersonalSign"
        @input="loadPersonalSign"
        :params-body="{ upload_url: 'excavators', privateUserId: $store.state.userId || -1 }"
        sub-title="или перетяните сюда файл общим объёмом не более 50 МБайт"
        :class="{ field_error: $v.alignmentPersonalSign.$error }"
      />
      <div
        class="error mozzarella color-fargo bottom"
        v-if="$v.alignmentPersonalSign.$error && !$v.alignmentPersonalSign.required"
      >
        Поле обязательно для заполнения
      </div>
    </div>

    <div class="field_wrapper">
      <r-textarea
        class="mt-8"
        :rows="type === 'APPROVAL_NO' || type === 'APPROVAL_YES' ? 7 : 3"
        label="Комментарий"
        v-model.trim="comment"
        :class="{ field_error: $v.comment.$error }"
      />
      <div
        class="error mozzarella color-fargo bottom"
        v-if="$v.comment.$error && !$v.comment.required"
      >
        Поле обязательно для заполнения
      </div>
    </div>

    <h6 class="bryndza mt-6 mb-4">
      Дополнительные фото или документ
    </h6>
    <upload-file
      :accept-file="[
        'image/jpeg',
        'image/png',
        'image/gif',
        'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
        'application/pdf',
        'application/msword',
        'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
        'text/csv',
      ]"
      :count-file="5"
      upload-url="/uploadfile/index.php"
      :value="images"
      @input="loadFiles"
      :params-body="{ upload_url: 'excavators', privateUserId: $store.state.userId || -1 }"
      :count-line="2"
      sub-title="или перетяните сюда до 5 фотографий, общим объёмом не более 50 МБайт"
    />
    <div class="mt-10 buttons">
      <r-button
        class="flex-1"
        color="secondary"
        @click.native="$rir.modal.close()"
        :title="buttonsText[0]"
        width="wide"
      />
      <r-button
        class="flex-1"
        @click.native="save"
        :disabled="!!!comment && (coordinationETSP ? !!!alignmentPersonalSign : false)"
        :title="buttonsText[1]"
        width="wide"
      />
    </div>
  </div>
</template>

<script>
import { required, requiredIf } from 'vuelidate/lib/validators';
import { formattedFiles } from '../helpers/utils';
import UploadFile from './UploadFile';

export default {
  name: 'ModalReport',
  components: {
    UploadFile
  },
  props: {
    saveFunc: Function,
    title: String,
    street: String,
    type: String,
    casse: Number,
    coordinationETSP: Boolean,
    projectName: String

  },
  data() {
    return {
      images: [],
      comment: null,
      alignmentPersonalSign: []
    };
  },
  validations: {
    comment: {
      required
    },
    alignmentPersonalSign: {
      required: requiredIf(data => data.coordinationETSP)
    }
  },
  computed: {
    buttonsText() {
      const { type } = this.$props;
      let text = [];
      if (type === 'COMPLETED') {
        text = ['Нет, не завершать', 'Да, завершить'];
      }
      if (type === 'RESUME') {
        text = ['Нет, не возобновлять', 'Да, возобновить'];
      }
      if (type === 'REPORT') {
        text = ['Нет, не отчитываться', 'Да, отчитаться'];
      }
      if (type === 'APPROVAL_NO') {
        text = ['Нет, вернуться назад', 'Да, отказать'];
      }
      if (type === 'APPROVAL_YES') {
        text = ['Нет, вернуться назад', 'Да, согласовать'];
      }
      return text;
    }
  },
  methods: {
    loadFiles(files) {
      if (!files?.length) {
        this.images = [];
        return;
      }
      this.images = formattedFiles(files);
    },
    loadPersonalSign(file) {
      this.alignmentPersonalSign = formattedFiles(file);
    },
    async save() {
      this.$v.$touch();
      if (this.$v.$invalid) {

      } else {
        if (this.type === 'APPROVAL_NO' || this.type === 'APPROVAL_YES') {
          this.saveFunc({
            casse: this.casse,
            alignmentComments: this.comment,
            alignmentAttachment: this.images,
            alignmentPersonalSign: this.alignmentPersonalSign
          });
        } else {
          this.saveFunc({
            comment: this.comment,
            images: this.images,
            type: this.$props.type
          });
        }

        this.$rir.modal.close();
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.modal_report {
  width: 100%;
  max-width: 790px;
  margin: 0 auto;
}

.buttons {
  display: grid;
  grid-template-columns: 380px 380px;
  grid-gap: 32px;
  @media(max-width: 792px) {
    grid-template-columns: 1fr;
  }
}

.field_wrapper {
  position: relative;

  .field_error {

    &.r-input {
      background: rgba(#E14761, 0.08);
    }

    &.r-textarea {
      background: rgba(#E14761, 0.08);
    }

    ::v-deep .r-input {
      background: rgba(#E14761, 0.08);
    }

    ::v-deep .r-drag-upload-file {
      border-color: #E14761;
    }
  }

  .error {
    position: absolute;
    font-size: 12px;
    top: 1px;
    right: 16px;
    pointer-events: none;

    &.bottom {
      top: calc(100% + 1px);
      right: unset;
      left: 16px;
    }
  }
}

</style>
