<template>
  <div class="r-map-balloon">
    <img
      v-if="img"
      class="r-map-balloon__img"
      :src="'/cityProperty/rest/' + img"
      :alt="title"
    >
    <div class="r-map-balloon__info">
      <p class="roquefort">
        {{ title }}
      </p>
      <p class="mt-1 сaprino opacity-72">
        {{ address }}
      </p>
      <p class="mt-2 briscola">
        <r-icon
          size="16"
          fill="rocky"
          class="mr-2"
          icon="avatar"
        />{{ manager }}
      </p>
      <div class="mt-3">
        <slot />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'BalloonCard',
  props: {
    title: {
      type: String
    },
    address: {
      type: String
    },
    manager: {
      type: String
    },
    img: {
      type: String
    }
  }
};
</script>

<style lang="scss" scoped>
.r-map-balloon {
  width: 244px;
  &__img {
    width: 100%;
    aspect-ratio: 16/10;
    object-fit: cover;
  }
  &__info {
    font-family: 'Golos UI';
    padding: 12px 16px 0;
  }
}
</style>
