<template>
  <div>
    <div class="flex mb-6">
      <router-link
        class="flex align-items-center sulguni color-rocky mr-6"
        :to="{ name: `${activeTabId}` }"
        v-if="this.$store.getters.userIsRoot"
      >
        <r-icon
          size="16"
          fill="rocky"
          class="mr-2"
          icon="add"
        />
        Добавить вручную
      </router-link>
      <!--        <a-->
      <!--          v-show="activeTabId === 'work'"-->
      <!--          class="flex align-items-center sulguni ml-6 color-rocky"-->
      <!--          href="#"-->
      <!--          @click.prevent="downloadPDF"-->
      <!--          ><r-icon size="16" fill="rocky" class="mr-2" icon="load" />Скачать список в PDF</a-->
      <!--        >-->

      <r-popover content-class="data-download">
        <template #activator="{ on }">
          <r-button-action
            transparent
            @click="on.click"
            title="Скачать отчёт"
            icon="load"
            is-arrow
          />
        </template>
        <template #content>
          <div class="data-download__wrapper">
            <r-button-action
              class="button"
              color="titanic"
              @click="downloadFile('excavatorOrderAllPDF')"
              transparent
              title="PDF"
            />
            <r-button-action
              class="button"
              color="titanic"
              @click="downloadFile('excavatorOrderAllCSV')"
              transparent
              title="XLS"
            />
            <r-button-action
              class="button"
              color="titanic"
              @click="downloadFile('excavatorOrderAllDOCX')"
              transparent
              title="DOC"
            />
          </div>
        </template>
      </r-popover>
    </div>
    <div class="objects__filter">
      <div class="flex">
        <div class="icon-div ">
          <r-date-picker
            click-close
            label="Работы с"
            v-model="filterStartDate"
          />
          <div
            style="display: table"
            @click="deleteStart"
          >
            <r-icon
              size="16"
              v-show="filterStartDate"
              icon="close"
              fill="titanic"
              class="anie icon-close"
            />
          </div>
        </div>
        <div class="icon-div">
          <r-date-picker
            class="ml-4"
            click-close
            label="Работы по"
            v-model="filterEndDate"
          />
          <div
            style="display: table"
            @click="deleteEnd"
          >
            <r-icon
              size="16"
              v-show="filterEndDate"
              icon="close"
              fill="titanic"
              class="anie icon-close"
            />
          </div>
        </div>
      </div>
      <r-select
        class="ml-4 mr-4"
        :items="sortTypes"
        label="Вид работ"
        v-model="selectedSortType"
      />

      <r-input
        class="flex-1 mr-6"
        label="Поиск"
        v-model.trim="search"
        before-icon="search"
      />

      <r-button-simple
        size="larishae"
        icon="menu"
        icon-size="20"
        @click="selectedView = 'table'"
        :type="selectedView === 'map' ? 'secondary' : 'primary'"
      />
      <r-button-simple
        size="larishae"
        class="ml-2"
        icon="geopoint"
        :type="selectedView === 'map' ? 'primary' : 'secondary'"
        icon-size="20"
        @click="selectedView = 'map'"
      />
    </div>
    <loader v-if="isLoading" />
    <template v-else>
      <div class="mt-6 flex justify-content-between">
        <div class="flex justify-content-between">
          <tag-carousel @select="onChangeType" />
        </div>

        <div class="flex align-items-center">
          <!-- <r-checkbox v-model="isShowTraffic"
                         v-if="selectedView === 'map'"
                         label="Пробки"/>
 -->
          <div class="ml-10 parmigiano color-titanic opacity-48">
            {{ !!searchText ? 'Найдено' : 'Всего' }} {{ countRow }}
          </div>
        </div>
      </div>

      <div
        v-if="selectedView === 'map'"
        class="objects__map mt-6"
        ref="map"
      >
        <!-- v-if="filteredWorks.length && isShowMap" -->
        <rir-map
          v-if="isShowMap"
          show-type
          @click="onMapClick"
          :controls="['trafficControl']"
          :show-traffic="isShowTraffic"
        >
          <div v-if="filteredWorks.length">
            <ymap-marker
              v-for="marker in filteredWorks"
              :key="`work_${marker.id}`"
              :coords="marker.coords"
              :marker-id="`marker_${marker.id}`"
              :icon="marker.completed_info.isComplete ? $markerIcon('mapPinGray') : (marker.incident ? $markerIcon('mapPinIncident') : $markerIcon('mapPin'))"
              :options="{ hideIconOnBalloonOpen: false }"
              cluster-name="main"
              @click="onMarkerClick(marker.id)"
              @balloonopen="bindListener"
              @balloonclose="unbindListener"
            >
              <excavation-balloon
                slot="balloon"
                v-bind="marker"
                :data-id="marker.id"
              />
            </ymap-marker>
          </div>
        </rir-map>
      </div>
      <div
        v-else
        class="objects__list mt-6 "
      >
        <excavation-card
          v-for="work in filteredWorks"
          :active-tab-id="activeTabId"
          :key="work.id"
          v-bind="work"
        />
      </div>
      <not-found v-if="countRow < 1" />
    </template>
  </div>
</template>

<script>
import ExcavationCard from '@/components/ExcavationCard.vue';
import ExcavationBalloon from '@/components/ExcavationBalloon.vue';
import notFound from '../components/notFound';
import RirMap from '@/components/RirMap.vue';
import DropDownDatePicker from '@/components/DropDownDatePicker.vue';
import Api from '../api/Api';
import { getNumberOfDays } from '@/helpers/utils';
import TagCarousel from '../components/TagCarousel';
import Loader from './Loader';

export default {
  name: 'WorkTab',
  components: {
    Loader,
    DropDownDatePicker,
    RirMap,
    ExcavationCard,
    ExcavationBalloon,
    notFound,
    TagCarousel
  },
  props: ['activeTabId'],
  data() {
    return {
      count: 0,
      isShowTraffic: false,
      isShowCompleted: false,
      filterStartDate: null,
      filterEndDate: null,
      filterDoneStartDate: null,
      filterDoneEndDate: null,
      sortTypes: [
        {
          id: 0,
          title: 'Все'
        },
        {
          id: 1,
          title: 'Плановая'
        },
        {
          id: 2,
          title: 'Аварийная'
        }
      ],
      orgTypes: [
        { id: 0, title: 'Все' },
        { id: 1, title: 'Согласующие' },
        { id: 2, title: 'Заявители' }
      ],
      selectedView: 'table',
      selectedSortType: 0,
      selectedOrgType: 0,
      searchText: null,
      timeoutId: null,
      selectedMarkerId: null,
      isShowMap: false,
      selectedTagsTypes: 'all'
    };
  },
  computed: {
    addButtonTitle() {
      if (this.activeTabId !== 'organizations') {
        return 'Добавить вручную';
      }
      return 'Новая организация';
    },
    countRow() {
      return this.filteredWorks.length;
    },
    // Список зем. работ
    excavationList() {
      // const works = [...this.$store.state.excavationList];
      const works = this.$store.state.excavationList.map(el => {
        const camelCaseObj = {};
        for (const elem in el.details) {
          if (elem === 'INN') {
            camelCaseObj[elem.toLowerCase()] = el.details[elem];
          } else {
            camelCaseObj[elem.split('')[0].toLowerCase() + elem.slice(1)] = el.details[elem];
          }
        }
        return {
          ...el,
          details: camelCaseObj
        };
      });
      const checkDate = (el, days) => {
        const dateEnd = el.details?.dateContinuedTo ? el.details?.dateContinuedTo : el.details.dateTo;
        if (getNumberOfDays(dateEnd) <= days && !el.details.completed_info?.isComplete) {
          return { flag: true };
        }
        return { flag: false };
      };
      return works.map(el => ({
        id: el.id,
        active: el.hidden !== '1',
        address: el.details.address,
        description: el.details.description,
        organization: el.details.applicantStatus === 0
          ? `${el.details.applicantName} ${el.details.applicantPatronymic} ${el.details.applicantSurname}`
          : el.details.firmName || '-',
        dateFrom: el?.dateFrom || el.details?.dateFrom,
        dateTo: el?.dateTo  || el.details?.dateTo,
        dateContinuedTo: el?.dateContinuedTo,
        continueDate: el.details.orderContinue,
        completed_info: el.details?.completed_info || {},
        isOverdue: checkDate(el, -1).flag, // просрочен
        isNearEnd: checkDate(el, -1).flag ? false : checkDate(el, this.$store.getters.getDays).flag, // подходит срок
        isDone: el.details?.completed_info?.isComplete || !!el.dateClosed || false,
        incident: el.details.isIncident,
        coords: [el.details.lng, el.details.lat],
        orderNo: el.details.orderNo

      }));
    },
    search: {
      get() {
        return this.searchText;
      },
      set(val) {
        if (this.timeoutId) clearTimeout(this.timeoutId);
        this.timeoutId = setTimeout(() => {
          this.searchText = typeof val === 'string' ? val.toLowerCase() : null;
        }, 420);
      }
    },
    filteredWorks() {
      const allWorks = [...this.excavationList];
      let works = [];

      works.push(...allWorks);

      // Если заполнены даты
      if (!!this.filterStartDate || !!this.filterEndDate) {
        let start = new Date(this.filterStartDate || new Date(0));
        // start.setDate(start.getDate() - 1);
        let end = new Date(this.filterEndDate || '01.01.3000');

        const dateFilter = date => {
          start = new Date(start).getTime();
          end = new Date(end).getTime();
          date = new Date(date).getTime();

          return date >= start && end >= date;
        };

        works = works.filter(el => dateFilter(el.dateFrom) && dateFilter(el.dateTo));
      }

      if (this.selectedSortType !== 0) {
        works = works.filter(el => (this.selectedSortType === 2 ? el.incident : !el.incident));
      }
      // Если заполнена строка поиска
      if (this.searchText) {
        works = works.filter(
          el => el?.address?.toLowerCase().indexOf(this.searchText) > -1
            || el?.orderNo?.toLowerCase().indexOf(this.searchText) > -1
            || el?.description?.toLowerCase().indexOf(this.searchText) > -1
            || el?.organization?.toLowerCase().indexOf(this.searchText) > -1
        );
      }

      // фильтрация по тегам
      if (this.selectedTagsTypes !== 'all') {
        works = works.filter(el => el[this.selectedTagsTypes] === true);
      }

      // console.log('WORKS', works);

      return works.flat();
    },
    isLoading() {
      return false;
      return this.$store.getters.getLoading;
    }
  },
  watch: {
    selectedView(val) {
      if (val === 'table' && this.isShowTraffic) {
        this.isShowTraffic = false;
      }
    }
  },
  async mounted() {
    await this.$store.dispatch('fetchExcavations', {
      currentMainMenuNumber: 18,
      casse: 0,
      onlyMine: this.$store.getters.userIsRoot ? 0 : 1
    });
  },
  activated() {
    this.isShowMap = true;
  },
  deactivated() {
    this.isShowMap = false;
  },
  methods: {
    onChangeType(type) {
      this.selectedTagsTypes = type.id;
    },
    deleteStart() {
      this.filterStartDate = null;
      this.count++;
    },
    deleteEnd() {
      this.filterEndDate = null;
      this.count++;
    },
    async downloadFile(extension) {
      const res = await new Api().printExcavators({
        document: extension,
        version: 2
      });
      if (res?.url) {
        window.open(res.url, '_blank');
      }
    },

    onChangeStartDate(date) {
      if (typeof date === 'string') {
        this.filterDoneStartDate = date;
      } else {
        this.filterDoneStartDate = null;
      }
    },

    onChangeEndDate(date) {
      if (typeof date === 'string') {
        this.filterDoneEndDate = date;
      } else {
        this.filterDoneEndDate = null;
      }
    },
    onMarkerClick(id) {
      this.selectedMarkerId = id;
    },

    onMapClick() {
      this.selectedMarkerId = null;
    },

    onBalloonClick() {
      if (!this.selectedMarkerId) return;

      this.$router.push({
        name: this.activeTabId,
        query: { id: this.selectedMarkerId }
      });
    },

    // Обрабочики для Balloon'a карты
    bindListener() {
      if (!this.selectedMarkerId) return;

      const balloon = this.$refs.map.querySelector(
        `.r-map-balloon[data-id="${this.selectedMarkerId}"]`
      );

      balloon.addEventListener('click', this.onBalloonClick);
    },
    unbindListener() {
      if (!this.selectedMarkerId) return;
      const balloon = this.$refs.map.querySelector(
        `.r-map-balloon[data-id="${this.selectedMarkerId}"]`
      );

      balloon.addEventListener('click', this.onBalloonClick);
    }
  }
};
</script>

<style scoped lang="scss">
.icon-close {
  cursor: pointer;
  position: absolute;
  margin-left: calc(100% - 60px);
  margin-top: -28px;
  z-index: 1;
}

.icon-div {
  display: table;
  position: relative;
}

.objects {
  &__filter {
    display: flex;
  }

  &__list {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 4px;

    > * {
      min-width: 0;
    }
  }

  &__map {
    height: 700px;
    border-radius: 24px;
    overflow: hidden;
  }
}

.r-popover {
  display: flex;
  align-items: center;

  .r-icon {
    &.add-icon {
      margin-right: 8px;
    }

    &.data-download__arrow {
      margin-left: 8px;
    }
  }
}

.data-download {
  &::v-deep {
    border-radius: 8px;
  }

  &__wrapper {
    padding: 12px 0;

    .button {
      display: flex;
      align-items: center;
      color: #04153E;
      opacity: 0.72;
      padding: 12px 26px 12px 16px !important;
      border-left: 4px solid transparent;
      border-radius: 0 !important;

      &:hover {
        opacity: 1;
        border-left-color: #3D75E4;
      }

      .r-icon {
        margin-right: 8px;
      }
    }
  }
}

::v-deep .ya-map-point {
  position: absolute;
  left: -4px;
  top: -4px;
  display: block;
  width: 8px;
  height: 8px;
  background-color: #fff;
  border: 2px solid var(--rir-rocky);
  border-radius: 50%;
}

::v-deep .ya-map-point--edge {
  cursor: pointer !important;
  position: absolute;
  left: -3px;
  top: -3px;
  display: block;
  width: 6px;
  height: 6px;
  background-color: var(--rir-rocky);
  border-radius: 50%;
  opacity: 0.8;
}

</style>
