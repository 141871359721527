<template>
  <div>
    <router-link
      :to="{
        name: activeTabId,
        query: { id: id },
      }"
    >
      <div class="card">
        <div class="card__info">
          <div class="card__header mozzarella">
            <r-icon
              icon="emergency-stop"
              size="16"
              fill="fargo"
              v-if="incident"
              class="mr-2"
            />

            <div>
              <span class="opacity-48">{{ date.from || 'Не указана' }}-</span>
              <span
                :class="{
                  'color-fargo': isOverdue && !!!dateContinuedTo,
                  'color-lebowski': isNearEnd && !!!dateContinuedTo && !isOverdue,
                  'opacity-48': !isNearEnd && !isOverdue || !!dateContinuedTo,
                }"
              >{{ date.to || 'Не указана' }}</span>
            </div>

            <svg
              class="opacity-48 mr-2 ml-2"
              v-if="dateContinuedTo"
              width="4"
              height="8"
              viewBox="0 0 4 8"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <g opacity="0.48">
                <path
                  d="M1.82999 3.99847L0.172416 1.54823C-0.135128 1.09361 -0.014819 0.476491 0.441133 0.169849C0.897084 -0.136791 1.51602 -0.0168361 1.82356 0.437779L3.82965 3.40321C3.95824 3.59329 4.01203 3.81179 3.99776 4.02412C4.00141 4.22108 3.94646 4.42108 3.82758 4.59679L1.8215 7.56222C1.51395 8.01684 0.895018 8.13679 0.439066 7.83015C-0.0168856 7.52351 -0.137194 6.90639 0.170349 6.45177L1.82999 3.99847Z"
                  fill="#04153E"
                />
              </g>
            </svg>
            <div v-if="dateContinuedTo">
              <span class="opacity-48">Продление до </span>
              <span
                :class="{
                  'color-fargo': isOverdue,
                  'color-lebowski': isNearEnd && !isOverdue,
                  'opacity-48': !isNearEnd && !isOverdue,
                }"
              >{{
                formatDate(dateContinuedTo) || 'Не указана'
              }}</span>
            </div>

            <svg
              class="opacity-48 mr-2 ml-2"
              v-if="completed_info.isComplete"
              width="4"
              height="8"
              viewBox="0 0 4 8"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <g opacity="0.48">
                <path
                  d="M1.82999 3.99847L0.172416 1.54823C-0.135128 1.09361 -0.014819 0.476491 0.441133 0.169849C0.897084 -0.136791 1.51602 -0.0168361 1.82356 0.437779L3.82965 3.40321C3.95824 3.59329 4.01203 3.81179 3.99776 4.02412C4.00141 4.22108 3.94646 4.42108 3.82758 4.59679L1.8215 7.56222C1.51395 8.01684 0.895018 8.13679 0.439066 7.83015C-0.0168856 7.52351 -0.137194 6.90639 0.170349 6.45177L1.82999 3.99847Z"
                  fill="#04153E"
                />
              </g>
            </svg>
            <div
              class="opacity-48"
              v-if="completed_info.isComplete"
            >
              Завершено
            </div>
          </div>

          <p class="sulguni mt-2 truncate">
            {{ address || 'Не указан' }}
          </p>

          <p class="mozzarella mt-1 opacity-72 truncate">
            {{ description || '-' }}
          </p>

          <div class="flex mt-2">
            <r-icon
              size="16"
              fill="rocky"
              class="card__icon"
              icon="mayor"
            />
            <p class="briscola truncate">
              {{ organization || '-' }}
            </p>
          </div>
        </div>
        <div
          class="flex align-items-center"
          v-if="this.$store.getters.userIsRoot"
        >
          <additional-menu>
            <ul class="card__menu">
              <li
                class="sulguni flex pointer opacity-72"
                @click.stop.prevent="onDelete"
              >
                <r-icon
                  size="16"
                  class="mr-3"
                  icon="delete"
                  fill="fargo"
                />
                Удалить
              </li>
            </ul>
          </additional-menu>
        </div>
      </div>
    </router-link>
  </div>
</template>

<script>
import AdditionalMenu from './AdditionalMenu.vue';
import Api from '../api/Api';
import DeleteModal from './DeleteModal';

export default {
  name: 'ExcavationCard',
  components: { AdditionalMenu },
  props: {
    activeTabId: {
      type: String
    },
    completed_info: {
      type: Object,
      required: true
    },
    organization: {
      type: String,
      default: ''
    },

    description: {
      type: String,
      default: ''
    },
    address: {
      type: String,
      default: ''
    },
    dateFrom: {
      type: String,
      default: ''
    },
    dateTo: {
      type: String,
      default: ''
    },
    dateContinuedTo: {
      type: String,
      default: ''
    },

    continueDate: {
      type: String,
      default: ''
    },
    id: {
      type: String,
      required: true
    },
    // active: {
    //   type: Boolean,
    //   required: true
    // },
    incident: {
      type: Boolean,
      default: false
    },
    isOverdue: {
      type: Boolean,
      default: false
    },
    isNearEnd: {
      type: Boolean,
      default: false
    }
  },

  data() {
    return {
      isShowPopover: false
    };
  },

  computed: {
    date() {
      const options = {
        month: 'numeric',
        day: 'numeric'
      };

      const stringDate = date => {
        const isSame = date.getFullYear() === new Date().getFullYear();
        return date.toLocaleString('Ru-ru', isSame ? options : { ...options, year: 'numeric' });
      };

      const from = stringDate(new Date(this.dateFrom));
      const to = !this.dateTo ? 'не указана' : stringDate(new Date(this.dateTo));

      return {
        from,
        to
      };
    }
  },
  methods: {
    async onDelete() {
      await this.$rir.modal.open(DeleteModal, {
        id: this.$props.id,
        actionPostfix: 'Excavation'
      });
    },
    formatDate(date) {
      const options = {
        month: 'numeric',
        day: 'numeric'
      };

      const stringDate = date => {
        const isSame = date.getFullYear() === new Date().getFullYear();
        return date.toLocaleString('Ru-ru', isSame ? options : { ...options, year: 'numeric' });
      };

      return stringDate(new Date(date));
    },
    async onRestore() {
      const api = new Api();
      await api.restoreExcavation(this.id);
    },
    copyItem() {
      this.$router.push({ name: 'work', query: { id: this.id, copy: true } });
    }
  }
};
</script>

<style lang="scss" scoped>
.card {
  position: relative;
  display: flex;
  justify-content: space-between;
  background-color: #fff;
  border: 1px solid #e4edfb;
  padding: 16px;
  border-radius: 16px;

  &:hover {
    box-shadow: 0 4px 16px rgb(4 21 62 / 16%);
  }

  &__header {
    overflow: hidden;
    white-space: nowrap;
    display: flex;
    align-items: center;
  }

  &__icon {
    margin-right: 6px;
  }

  &__info {
    display: flex;
    flex-direction: column;
    overflow: hidden;
  }

  &__restore {
    background-color: transparent;
    cursor: pointer;
  }

  .pointer {
    cursor: pointer;
  }
}

.popover {
  display: inline-block;
  position: relative;
  cursor: pointer;
}

.popover__content {
  overflow: visible !important;
  position: absolute;
  right: 4px;
  top: calc(100% + 4px);
  padding: 4px 8px;
  border-radius: 12px;
  background-color: #fff;
  -webkit-filter: drop-shadow(0px 1px 2px #ccc);
  filter: drop-shadow(0px 1px 2px #ccc);
  z-index: 100;
}
</style>
