<template>
  <div class="readable">
    <div class="mb-4">
      <p class="mozzarella opacity-48">
        Обложка
      </p>
      <p class="feta opacity-72 mt-2">
        <template v-if="!!organization.details.imgUrl">
          <r-file
            v-for="file of organization.details.imgUrl"
            :key="file.url"
            :file="{
              type: file.contentType,
              ...file,
            }"
            :title="file.filename"
            type-view="MINI"
            @clickPreview="(e)=>{ window.open(e.url, '_blank') }"
          />
        </template>
        <template v-else>
          -
        </template>
      </p>
    </div>
    <h3 class="bryndza mb-4">
      Руководитель
    </h3>
    <div class="mb-4">
      <p class="mozzarella opacity-48">
        ФИО
      </p>
      <p class="feta opacity-72 mt-1">
        {{ organization.details.ceoName || '-' }}
      </p>
    </div>
    <div class="mb-8">
      <p class="mozzarella opacity-48">
        Должность
      </p>
      <p class="feta opacity-72 mt-1">
        {{ organization.details.ceoPosition || '-' }}
      </p>
    </div>

    <h3 class="bryndza mb-4">
      Доверенное лицо
    </h3>
    <div class="mb-4">
      <p class="mozzarella opacity-48">
        ФИО
      </p>
      <p class="feta opacity-72 mt-1">
        {{ organization.details.confidant.fio || '-' }}
      </p>
    </div>
    <div class="mb-4">
      <p class="mozzarella opacity-48">
        Должность
      </p>
      <p class="feta opacity-72 mt-1">
        {{ organization.details.confidant.job || '-' }}
      </p>
    </div>
    <div class="mb-4">
      <p class="mozzarella opacity-48">
        Организация
      </p>
      <p class="feta opacity-72 mt-1">
        {{ organization.details.confidant.firm || '-' }}
      </p>
    </div>
    <div class="flex mb-8">
      <div class="mr-8 ">
        <p class="mozzarella opacity-48">
          Телефон
        </p>
        <p class="feta opacity-72 mt-1">
          {{ organization.details.confidant.phone || '-' }}
        </p>
      </div>
      <div>
        <p class="mozzarella opacity-48">
          Имейл
        </p>
        <p class="feta opacity-72 mt-1">
          {{ organization.details.confidant.email || '-' }}
        </p>
      </div>
    </div>

    <h3 class="bryndza mb-4">
      Контакты организации
    </h3>
    <div class="flex mb-8">
      <div class="mr-8 ">
        <p class="mozzarella opacity-48">
          Телефон
        </p>
        <template v-if="organization.details.contacts.length > 0">
          <p
            class="feta opacity-72 mt-1"
            v-for="phone of organization.details.contacts"
          >
            {{ phone }}
          </p>
        </template>
        <template v-else>
          -
        </template>
      </div>
      <div>
        <p class="mozzarella opacity-48">
          Имейл
        </p>
        <template v-if="organization.details.emails.length > 0">
          <p
            class="feta opacity-72 mt-1"
            v-for="email of organization.details.emails"
          >
            {{ email }}
          </p>
        </template>
        <template v-else>
          -
        </template>
      </div>
    </div>
    <h3 class="bryndza mb-4">
      Реквизиты
    </h3>
    <div class="flex mb-4">
      <div
        class="mr-6"
        style="min-width: 95px;"
      >
        <p class="mozzarella opacity-48">
          ИНН
        </p>
        <p class="feta opacity-72 mt-1">
          {{ organization.details.inn || '-' }}
        </p>
      </div>
      <div class="mr-6">
        <p class="mozzarella opacity-48">
          Расчетный счет организации
        </p>
        <p class="feta opacity-72 mt-1">
          {{ organization.details.bankAccount || '-' }}
        </p>
      </div>
      <div class="">
        <p class="mozzarella opacity-48">
          ОГРН
        </p>
        <p class="feta opacity-72 mt-1">
          {{ organization.details.ogrn || '-' }}
        </p>
      </div>
    </div>
    <div class="mb-4">
      <p class="mozzarella opacity-48">
        Банк
      </p>
      <p class="feta opacity-72 mt-1">
        {{ organization.details.bankName || '-' }}
      </p>
    </div>
  </div>
</template>

<script>
export default {
  name: 'OrganizationOnlyReadable',
  props: {
    organization: {
      type: Object,
      required: true
    }
  }
};
</script>

<style scoped>

</style>
