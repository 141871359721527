export const getNumberOfDays = (endDate) => {
  const date1 = new Date();
  const date2 = new Date(endDate);

  const oneDay = 1000 * 60 * 60 * 24;
  const diffInTime = date2.getTime() - date1.getTime();
  const diffInDays = Math.round(diffInTime / oneDay);
  //console.log('diffInDays',diffInDays, 'endDate', endDate)
  return diffInDays;
}
export const getEmptyField = (details, arr = []) => {
  for (let elem in details) {
    if (!details[elem]) arr.push(elem)
  }
  return arr
}
export const mappedTableList = (arr) => {
  return [...arr].map(item => ({
    id: item.id,
    organizationId: item.id,
    status: '0',
    isAlignment: item.alignment === 'checked',
    details: {
      title: item.details?.title,
     // inn: item.details.inn,
      phone: item.details?.confidant?.phone || '',
      email: item.details?.confidant?.email || '',
      ceoName: item.details?.confidant?.fio,
      address: item.details?.address,
    },
    dragIndex: item.details?.dragIndex,
    errored_fields: getEmptyField({
      ceoName: item.details?.confidant?.fio,
    }) // названия пустых полей
  }));
}
export const getDayAddition = (num) => {
  let preLastDigit = num % 100 / 10;
  if (preLastDigit == 1) {
    return "дней";
  }
  switch (num % 10) {
    case 1:
      return "день";
    case 2:
    case 3:
    case 4:
      return "дня";
    default:
      return "дней";
  }
}
export const formattedFiles = (files)=>{
  const formattedFiles = [];

  files.forEach(el => {
    if (el?.files?.length) {
      const fileList = el.files.map(el => ({
        type: el.type,
        name: el.name,//.split('.')[0]
        icon: false,
        url: el.url
      }));
      formattedFiles.push(...fileList);
    } else {
      formattedFiles.push(el);
    }
  });
  return formattedFiles
}
