var render = function render(){var _vm=this,_c=_vm._self._c;return (!!_vm.report)?_c('div',{staticClass:"report mt-6"},[_c('time',{staticClass:"mozzarella mb-2 opacity-48 block"},[_vm._v(" "+_vm._s(_vm.report.createdF)+" ")]),_c('p',{staticClass:"parmigiano"},[_vm._v(" "+_vm._s(_vm.report.details.title)+" ")]),(_vm.report.details.comment)?_c('div',{staticClass:"report_info mt-4"},[_c('p',{staticClass:"parmigiano"},[_vm._v(" "+_vm._s(_vm.report.details.comment)+" ")]),(_vm.report.details.images.length > 0)?_c('div',{staticClass:"images mt-4"},_vm._l((_vm.report.details.images),function(image){return _c('r-file',{key:image.url,attrs:{"file":{
          type: image.contentType,
          ...image,
        },"title":image.filename,"button-simple-option":{
          color: 'rocky',
          icon: 'load',
        }}})}),1):_vm._e()]):_vm._e()]):_vm._e()
}
var staticRenderFns = []

export { render, staticRenderFns }