<template>
  <!-- :class="['obj-card', { 'obj-card--disabled': !active }] - для полу-прозрачности -->
  <div :class="['obj-card']">
    <div class="obj-card__img">
      <img
        v-if="img"
        :src="'/cityProperty/rest/' + img"
        :alt="title"
        loading="lazy"
      >
      <r-icon
        v-else
        :icon="icon ? icon : 'mayor'"
        size="32"
        fill="alien"
      />
    </div>
    <div class="obj-card__info flex-1">
      <div class="obj-card__title sulguni">
        {{ title }}
      </div>
      <div class="obj-card__address mozzarella mt-1">
        <span
          v-if="!active"
          class="opacity-72 color-fargo mr-2"
        >Скрытая</span>{{ address }}
      </div>

      <div class="obj-card__counters flex align-items-center">
        <slot />
      </div>
    </div>

    <!-- <div class="flex align-items-center">
      <additional-menu v-if="active">
        <ul class="obj-card__menu">
          <li class="sulguni flex pointer" @click.stop.prevent="copyItem">
            <r-icon size="16" fill="rocky" class="mr-3" icon="copy" />Дублировать
          </li>
          <li class="sulguni flex mt-6 pointer" @click.stop.prevent="onDeleteItem">
            <r-icon size="16" fill="rocky" class="mr-3" icon="delete" fill="fargo" />Удалить
          </li>
        </ul>
      </additional-menu>
      <button
        class="obj-card__restore"
        v-else
        @click.stop.prevent="onDeleteItem"
        title="Восстановить"
      >
        <r-icon size="16" fill="rocky" icon="restore" />
      </button>
    </div> -->
  </div>
</template>

<script>
import AdditionalMenu from './AdditionalMenu.vue';

export default {
  components: { AdditionalMenu },
  props: {
    uid: {
      type: String,
      required: true
    },
    img: {
      type: [String, null],
      default: null
    },
    icon: {
      type: [String, null],
      default: null
    },
    title: {
      type: String,
      required: true
    },
    address: {
      type: String,
      required: true
    },
    active: {
      type: Boolean,
      required: true
    }
  },
  computed: {
    thumbImg() {
      if (!this.img) return null;

      const arr = this.img.split('/');
      arr.splice(arr.length - 1, 0, 'thumbnail');
      const thumb = arr.join('/');

      return thumb;
    }
  }
  // computed: {
  //   actionPostfix() {
  //     let postfix = null;
  //     if (this.currentTab === 'districts') {
  //       postfix = 'District';
  //     } else if (this.currentTab === 'staff') {
  //       postfix = 'Staff';
  //     } else if (this.currentTab === 'institutions') {
  //       postfix = 'Institution';
  //     }
  //     return postfix;
  //   }
  // },
  // methods: {
  //   // async onRestore() {
  //   //   console.log('restore', this.uid);

  //   //   if (this.actionPostfix) {
  //   //     const res = await this.$store.dispatch(`set${this.actionPostfix}`, {
  //   //       id: this.uid,
  //   //       hidden: 0
  //   //     });
  //   //     res && this.$store.dispatch(`get${this.actionPostfix}`);
  //   //   }

  //   //   // this.actionPostfix &&
  //   //   // this.$store.dispatch(`set${this.actionPostfix}`, { id: this.uid, hidden: 0 });
  //   //   // if (!res.error) {
  //   //   //   this.$store.dispatch('loadObjects');
  //   //   // }
  //   // },
  //   // copyItem() {
  //   //   this.$router.push({ name: 'organizations', query: { id: this.uid, copy: true } });
  //   // },
  //   // async onDeleteItem() {
  //   //   const res = await this.$store.dispatch('deleteOrganization', this.uid);
  //   //   if (res?.updated) {
  //   //     this.$store.dispatch('getOrganizations');
  //   //     this.$router.push({ name: 'admin' });
  //   //   }

  //   // }
  // }
};
</script>

<style lang="scss" scoped>
.obj-card {
  position: relative;
  display: flex;
  background-color: #fff;
  border: 1px solid #e4edfb;
  padding: 15px 21px 15px 15px;
  border-radius: 16px;
  &--disabled {
    > * {
      opacity: 0.48;
    }
  }
  &:hover {
    box-shadow: 0 4px 16px rgb(4 21 62 / 16%);
  }
  &__img {
    display: flex;
    align-items: center;
    justify-content: center;
    overflow: hidden;
    background-color: var(--rir-arrival);
    margin-right: 12px;
    width: 72px;
    height: 72px;
    border-radius: 8px;
    > img {
      height: 100%;
      object-fit: cover;
      width: 100%;
    }
  }
  &__info {
    display: flex;
    flex-direction: column;
    overflow: hidden;
  }
  &__title {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
  &__address {
    opacity: 0.72;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
  &__counters {
    margin-top: 14px;
  }
  &__restore {
    background-color: transparent;
    cursor: pointer;
  }
  .pointer {
    cursor: pointer;
  }
}
.opacity-32 {
  opacity: 0.32;
}
</style>
