var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"history_tab"},[_c('div',{staticClass:"history_item_wrap"},_vm._l((_vm.statement.log),function(item){return _c('div',{key:item.id,staticClass:"history_item mb-6"},[_c('p',{staticClass:"mozzarella opacity-48 mb-2"},[_vm._v(" "+_vm._s(item.createdF)+" ")]),_c('p',{staticClass:"parmigiano"},[_c('span',{class:{
            'color-matrix': _vm.isAgreed && item.alignmentStatus === '2',
            'color-fargo': _vm.isRefuse && item.alignmentStatus === '1',
          }},[_vm._v(" "+_vm._s(item.comment)+" ")])]),(!!item.alignmentComments || !!item.alignmentAttachment)?_c('div',{staticClass:"rush mt-4",class:{
          shawshank: (_vm.isAgreed || _vm.isRefuse) && item.alignmentStatus === '2',
          joker: (_vm.isAgreed || _vm.isRefuse) && item.alignmentStatus === '1',
        }},[(!!item.alignmentComments)?_c('p',{staticClass:"opacity-72 parmigiano",class:{
            'text-oldboy': (_vm.isAgreed || _vm.isRefuse) && item.alignmentStatus === '2',
            'text-prestige': (_vm.isAgreed || _vm.isRefuse) && item.alignmentStatus === '1',
          }},[_vm._v(" "+_vm._s(item.alignmentComments)+" ")]):_vm._e(),(item.alignmentAttachment.length > 0)?_vm._l((item.alignmentAttachment),function(file){return _c('r-file',{key:file.url,attrs:{"file":{
              type: file.contentType,
              ...file,
            },"title":file.filename,"type-view":"MINI"},on:{"clickPreview":(e)=>{ _vm.window.open(e.url, '_blank') }}})}):[_vm._v(" - ")]],2):_vm._e()])}),0),_c('div',{staticClass:"alignments"},[_c('h3',{staticClass:"taleggio"},[_vm._v(" Согласованты ")]),(_vm.checkAlignments.waitCount > 0)?_c('p',{staticClass:"mozzarella opacity-48 mt-1"},[_vm._v(" Осталось "+_vm._s(_vm.checkAlignments.waitCount)+" из "+_vm._s(_vm.checkAlignments.allLength)+" ")]):_c('p',{staticClass:"mozzarella mt-1 flex align-items-center"},[_c('r-icon',{staticClass:"mr-1",attrs:{"size":"16","icon":"selection","fill":"matrix"}}),_c('span',{staticClass:"opacity-48"},[_vm._v(" "+_vm._s(_vm.checkAlignments.allLength)+" из "+_vm._s(_vm.checkAlignments.allLength)+" ")])],1),_c('scroll-bar',{staticStyle:{"width":"100%"},attrs:{"options":{ alwaysShowTracks: true }}},_vm._l((_vm.filteredAlignments),function(item){return _c('div',{key:item.alignmentId,staticClass:"alignments__item mt-6 flex align-items-center justify-content-between",staticStyle:{"width":"100%"}},[_c('div',[_c('p',{staticClass:"feta mb-1"},[_vm._v(" "+_vm._s(item.organizationDetails.title)+" ")]),_c('p',{staticClass:"mozzarella",class:[_vm.statuses[item.status].color || 'color-titanic', { 'opacity-48': item.status === '0' }]},[_vm._v(" "+_vm._s(_vm.statuses[item.status].title)+_vm._s(!!item.hmac ? ' c Факсимиле' : null)+" ")])]),(_vm.$store.getters.userIsRoot)?_c('r-button-simple',{staticClass:"opacity-72 ml-4 icon",attrs:{"transparent":"","icon":"delete","fill":'fargo'},on:{"click":function($event){return _vm.deleteItem(item.alignmentId, item.organizationId)}}}):_vm._e()],1)}),0)],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }