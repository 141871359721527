<template>
  <r-popover class="relative">
    <template #activator="{ on }">
      <r-button-simple
        icon="layers"
        fill="rocky"
        icon-size="20"
        type="light"
        size="larishae"
        title=""
        v-on="on"
      />
    </template>
    <template #content>
      <div class="list">
        <div
          :class="['item', { 'item--active': item.active }]"
          v-for="item in items"
          :key="item.id"
          @click="select(item)"
        >
          <r-icon
            v-if="item.icon"
            class="mr-3"
            :icon="item.icon"
          />
          <span class="sulguni">{{ item.value }}</span>
        </div>
      </div>
    </template>
  </r-popover>
</template>

<script>
export default {
  name: 'ButtonDropdown',
  props: {
    items: {
      tyep: Array,
      required: true
    }
  },
  computed: {
    selected() {
      return this.items.filter(el => el.active)?.[0] || null;
    }
  },

  methods: {
    select(item) {
      this.$emit('select', item);
    }
  }
};
</script>

<style lang="scss" scoped>
.relative {
  position: relative;
}
// .disabled {
//   opacity: 0.72;
// }
.list {

  display: inline-flex;
  flex-direction: column;
  background-color: #fff;

  box-shadow: 0 9px 24px rgba(17, 48, 121, 0.18);
  z-index: 10000;
  border-radius: 8px;
}
.item {
  display: flex;
  white-space: nowrap;
  align-items: center;
  opacity: 0.72;
  padding: 14px 16px;
  position: relative;
  &:hover {
    opacity: 1;
    cursor: pointer;
  }
}
.item--active {
  opacity: 1;
  &::before {
    content: '';
    position: absolute;
    height: 100%;
    left: 0;
    top: 0;
    border-left: 4px solid var(--rir-rocky);
  }
}
</style>

<style>
.r-drop-down__content {
  position: absolute;
  top: 0;
  left: -100px;
}
.r-drop-down__activator > i {
  display: none;
}
</style>
