<template>
  <div>
    <div class="tags-wrapper">
      <button
        class="scroll-btn-prev"
        @click="scroll('prev')"
        v-show="scrollLeft > 0"
      />
      <ul
        class="tags-scroll"
        ref="slider"
      >
        <li
          v-for="tag in tags"
          :key="tag.id"
          @click="selectTag(tag)"
        >
          <tag
            :title="tag.title"
            :active="tag.active"
            :color="tag.color"
          />
        </li>
      </ul>
      <button
        class="scroll-btn-next"
        @click="scroll('next')"
        v-show="isShowNextBtn"
      />
    </div>
  </div>
</template>

<script>
import Tag from './Tag.vue';

export default {
  components: {
    Tag
  },

  props: ['items'],

  data: () => ({
    scrollLeft: 0,
    offsetWidth: 0,
    scrollWidth: 0,
    tags: [
      { title: 'Все', active: true, id: 'all', color: 'rocky' },
      { title: 'Просроченные', active: false, id: 'isOverdue', color: 'fargo' },
      { title: 'Подходит срок', active: false, id: 'isNearEnd', color: 'lebowski' },
      { title: 'Завершённые', active: false, id: 'isDone', color: 'metropolis' }
    ]
  }),

  computed: {
    isShowNextBtn() {
      return this.scrollLeft + this.offsetWidth < this.scrollWidth;
    }
  },
  watch: {
    items(val) {
      this.tags = [...val];
    }

    // items: {
    //   deep: true,
    //   handler(val) {
    //     console.log('newVal', val);
    //     this.tags = [...val];
    //   }
    // }
  },

  updated() {
    const carousel = this.$refs.slider;
    this.scrollWidth = carousel.scrollWidth;
    this.offsetWidth = carousel.offsetWidth;
    this.scrollLeft = carousel.scrollLeft;
  },

  mounted() {
    // this.tags = [...this.items];
    const carousel = this.$refs.slider;

    carousel.addEventListener('scroll', () => {
      this.scrollLeft = carousel.scrollLeft;
      this.scrollWidth = carousel.scrollWidth;
    });

    const { slider } = this.$refs;
    let isDown = false;
    let startX;
    let scrollLeft;

    slider.addEventListener('mousedown', e => {
      isDown = true;
      slider.classList.add('active');
      startX = e.pageX - slider.offsetLeft;
      scrollLeft = slider.scrollLeft;
    });
    slider.addEventListener('mouseleave', _ => {
      isDown = false;
      slider.classList.remove('active');
    });
    slider.addEventListener('mouseup', _ => {
      isDown = false;
      slider.classList.remove('active');
    });
    slider.addEventListener('mousemove', e => {
      if (!isDown) return;
      e.preventDefault();
      const x = e.pageX - slider.offsetLeft;
      const SCROLL_SPEED = 1;
      const walk = (x - startX) * SCROLL_SPEED;
      slider.scrollLeft = scrollLeft - walk;
    });
  },

  methods: {
    scroll(direction) {
      const carousel = this.$refs.slider;
      // Ширина прокрутки
      const scrollWidth = 200;
      const width = direction === 'next' ? scrollWidth : scrollWidth * -1;
      carousel.scrollTo(carousel.scrollLeft + width, 0);
    },
    selectTag(el) {
      this.tags = this.tags.map(tag => ({
        ...tag,
        active: tag.title === el.title
      }));
      this.$emit('select', { ...el, active: true });
    }
  }
};
</script>

<style scoped>
.tags-wrapper {
  padding: 0;
  margin: 0;
  position: relative;
}
.tags-scroll {
  display: flex;
  flex-direction: row;
  column-gap: 8px;
  overflow: scroll;
  scroll-behavior: smooth;
  -webkit-overflow-scrolling: touch; /* Important for iOS devices */
  scroll-snap-type: both mandatory;
  scroll-padding: 1rem;
  scrollbar-width: none;
  -ms-overflow-style: none;
}
.tags-scroll::-webkit-scrollbar {
  display: none;
}

.active {
  scroll-snap-type: unset;
}

.tags-scroll > li {
  list-style: none;
}

.tags-scroll > * {
  /* scroll-snap-align: start; */
  /* display: inline-block; */
  /* border-radius: 3px; */
  /* font-size: 0; */
}

.scroll-btn-prev,
.scroll-btn-next {
  background: transparent;
  box-shadow: 0px 0px 0px transparent;
  border: 0px solid transparent;
  text-shadow: 0px 0px 0px transparent;
  appearance: none;
  -moz-appearance: none;
  -webkit-appearance: none;
  position: absolute;
  left: -20px;
  top: 0;
  width: 20px;
  height: 100%;
  background-image: url('/img/icons/arrow-left_16.svg');
  background-repeat: no-repeat;
  background-position: left center;
  z-index: 10;
  cursor: pointer;
}
.scroll-btn-next {
  left: unset;
  right: -20px;
  background-image: url('/img/icons/arrow-right_16.svg');
  background-position: right center;
}
</style>
