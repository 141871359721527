<template>
  <div class="history_tab">
    <div class="history_item_wrap">
      <div
        class="history_item mb-6"
        v-for="item of statement.log"
        :key="item.id"
      >
        <p class="mozzarella opacity-48 mb-2">
          {{ item.createdF }}
        </p>
        <p class="parmigiano">
          <span
            :class="{
              'color-matrix': isAgreed && item.alignmentStatus === '2',
              'color-fargo': isRefuse && item.alignmentStatus === '1',
            }"
          >
            {{ item.comment }}
          </span>
          <!-- {{ organizationName(item.organizationId) }}-->
        </p>
        <div
          class="rush mt-4"
          :class="{
            shawshank: (isAgreed || isRefuse) && item.alignmentStatus === '2',
            joker: (isAgreed || isRefuse) && item.alignmentStatus === '1',
          }"
          v-if="!!item.alignmentComments || !!item.alignmentAttachment"
        >
          <p
            class="opacity-72 parmigiano"
            :class="{
              'text-oldboy': (isAgreed || isRefuse) && item.alignmentStatus === '2',
              'text-prestige': (isAgreed || isRefuse) && item.alignmentStatus === '1',
            }"
            v-if="!!item.alignmentComments"
          >
            {{ item.alignmentComments }}
          </p>
          <template v-if="item.alignmentAttachment.length > 0">
            <r-file
              v-for="file of item.alignmentAttachment"
              :key="file.url"
              :file="{
                type: file.contentType,
                ...file,
              }"
              :title="file.filename"
              type-view="MINI"
              @clickPreview="(e)=>{ window.open(e.url, '_blank') }"
            />
          </template>
          <template v-else>
            -
          </template>
        </div>
      </div>
    </div>

    <div class="alignments">
      <h3 class="taleggio">
        Согласованты
      </h3>
      <p
        class="mozzarella opacity-48 mt-1"
        v-if="checkAlignments.waitCount > 0"
      >
        Осталось
        {{ checkAlignments.waitCount }} из
        {{ checkAlignments.allLength }}
      </p>
      <p
        class="mozzarella mt-1 flex align-items-center"
        v-else
      >
        <r-icon
          size="16"
          class="mr-1"
          icon="selection"
          fill="matrix"
        />
        <span class="opacity-48">
          {{ checkAlignments.allLength }} из
          {{ checkAlignments.allLength }}
        </span>
      </p>

      <scroll-bar
        :options="{ alwaysShowTracks: true }"
        style="width: 100%"
      >
        <div
          class="alignments__item mt-6 flex align-items-center justify-content-between"
          style="width: 100%;"
          v-for="item of filteredAlignments"
          :key="item.alignmentId"
        >
          <div>
            <p class="feta mb-1">
              {{ item.organizationDetails.title }}
            </p>
            <p
              class="mozzarella"
              :class="[statuses[item.status].color || 'color-titanic', { 'opacity-48': item.status === '0' }]"
            >
              {{ statuses[item.status].title }}{{ !!item.hmac ? ' c Факсимиле' : null }}
            </p>
          </div>
          <r-button-simple
            transparent
            icon="delete"
            class="opacity-72 ml-4 icon"
            v-if="$store.getters.userIsRoot"
            @click="deleteItem(item.alignmentId, item.organizationId)"
            :fill="'fargo'"
          />
        </div>
      </scroll-bar>
    </div>
  </div>
</template>

<script>
import ScrollBar from '@blackbp/vue-smooth-scrollbar';
import reportItem from './ReportItem';
import Api from '../api/Api';

export default {
  name: 'HistoryTab',
  components: {
    reportItem,
    ScrollBar
  },
  props: {
    statement: {
      type: Object
    },
    statementInfo: {
      type: Object
    },
    isAgreed: {
      type: Boolean,
      default: false
    },
    isRefuse: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      statuses: {
        '-1': {
          title: 'Отозван',
          color: 'color-titanic'
        },
        0: {
          title: 'Ожидает согласования',
          color: 'color-titanic'
        },
        1: {
          title: 'Отказано в согласовании',
          color: 'color-fargo'
        },
        2: {
          title: 'Согласовано',
          color: 'color-matrix'
        },
        '-2': {
          title: 'sadsaddsdsa',
          color: 'color-matrix'
        }
      }
    };
  },
  computed: {
    checkAlignments() {
      if (this.$props.statementInfo?.all) {
        const statementInfo = this.$props.statementInfo.all;
        const waitCount = statementInfo.filter(item => item.status === '0')?.length;
        return {
          waitCount,
          allLength: this.filteredAlignments?.length
        };
      }
      return {
        waitCount: 0,
        allLength: 0
      };
    },
    filteredAlignments() {
      if (this.$props.statementInfo?.all) {
        return this.statementInfo.all.filter(item => item.status !== '-1' && item.status !== '-2');
      }
      return [];
    }
  },
  methods: {
    organizationName(id) {
      if (this.statementInfo.all) {
        return this.statementInfo?.all.find(item => item.organizationId === id)?.organizationDetails.title;
      }
      return '';
    },
    deleteItem(alignmentId, organizationId) {
      new Api().saveStatement({
        casse: 10,
        id: this.statement.id,
        action: 'deleteItem',
        alignment: [
          {
            organizationId,
            alignmentId
          }
        ]
      }).then(res => {
        window.location.reload();
      });
    }
  }
};
</script>

<style scoped lang="scss">
.history_tab {
  display: grid;
  grid-template-columns: 1fr 300px;
  grid-gap: 24px;
}

.alignments__item {
  p {
    transition: .2s ease-in-out;
  }

  .icon {
    opacity: 0;
    transition: .2s ease-in-out;

    ::v-deep svg {
      fill: #E14761 !important;

      path {
        fill: #E14761 !important;
      }
    }
  }

  &:hover {
    p {
      color: #113079;
    }

    .icon {
      opacity: .72;
    }
  }
}

.history_item {
  .rush {
    padding: 16px;
    border-radius: 8px;
  }
}

.text-oldboy {
  color: #142202;
}

.text-prestige {
  color: #34040F;
}
::v-deep .r-file-list__info{
  opacity: .72;
  margin-bottom: 0;
}
</style>
