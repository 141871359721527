<template>
  <div class="choice_organization">
    <h3 class="camembert mb-7">
      Выбор организации-участника согласования
    </h3>
    <r-input
      class="flex-1"
      label="Поиск"
      v-model.trim="searchText"
      @input="update"
      before-icon="search"
    />
    <drag-drop-table
      :headings="headings"
      :list="filteredList"
      class="mt-6 max-width95"
      :columns-grid="columnsGrid"
      :checkboxes="true"
      v-show="filteredList.length > 0"
    />
    <not-found v-if="filteredList.length < 1" />
    <div class="button_container">
      <r-button
        @click="save"
        title="Сохранить"
        width="wide"
      />
      <r-button-action
        class="color-lebowski opacity-72 ml-6"
        color="lebowski"
        title="Очистить"
        icon="clean"
        @click="ResetChoicest"
      />
    </div>
  </div>
</template>

<script>
import _ from 'lodash';
import dragDropTable from './DragDropTable';
import notFound from './notFound';
import { mappedTableList } from '../helpers/utils';

export default {
  name: 'ChoiceOrganizationModal',
  components: {
    dragDropTable,
    notFound
  },
  props: {
    headings: {
      required: true,
      type: Array
    },
    columnsGrid: {
      required: true,
      type: Array
    }
  },
  data() {
    return {
      tableList: [],
      searchText: null,
      flyButton: false
    };
  },
  computed: {
    filteredList() {
      this.tableList = mappedTableList(this.$store.getters.getNotHiddenOrganizations);
      if (!this.searchText) return this.tableList;
      return this.tableList
        .filter(x => {
          const titleLower = x.details.title.toLowerCase();
          // const addressLower = x.details?.address.toLowerCase();
          const searchLower = this.searchText.toLowerCase();
          return titleLower.includes(searchLower); /* ||
            addressLower.includes(searchLower); */
        });
    }
  },
  async mounted() {

  },
  methods: {
    update: _.debounce(function (e) {
      this.searchText = e;
    }, 300),
    ResetChoicest() {
      this.$store.commit('setAlignmentOrganizations', []);
    },
    async save() { // нужно подумать как сделать лучше
      this.$store.getters.getNotHiddenOrganizations.forEach(org => {
        const { id } = org;
        let data = {
          id,
          action: 'updateAlignment',
          alignment: 0
        };
        this.$store.state.participants.choicestOrganizations.forEach(item => {
          if (org.id === item.id) {
            data = {
              id: item.id,
              action: 'updateAlignment',
              alignment: 1
            };
          }
        });
        this.$store.dispatch('setOrganization', data);
      });

      this.$store.dispatch('fetchOrganizations').then(() => {
        this.$nextTick(() => {
          this.$rir.modal.close();
        });
      });
    }
  }
};
</script>

<style scoped lang="scss">
.max-width95{
}
.choice_organization {
  max-width: 1300px;
  width: 95%;
  padding-bottom: 96px;
  margin: 0 auto;
  padding-left: 10px;
  padding-right: 10px;
}

.button_container {
  position: fixed;
  bottom: 0;
  right: 0;
  left: 0;
  display: flex;
  align-items: center;
  z-index: 15;
  background: #FFFFFF;
  box-shadow: 0px 8px 32px rgba(4, 21, 62, 0.16);
  padding-left: 40px;
  padding-right: 80px;
  padding-bottom: 32px;
  padding-top: 24px;
}

::v-deep .r-modal__content-r-icon_close {
  z-index: 1010;
}
</style>
