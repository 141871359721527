var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"modal_report"},[_c('h3',{staticClass:"camembert"},[_vm._v(" "+_vm._s(_vm.title)+" ")]),(!!_vm.street)?_c('p',{staticClass:"feta opacity-72 mt-3"},[_vm._v(" "+_vm._s(_vm.street)+" ")]):_vm._e(),(_vm.type === 'APPROVAL_NO')?_c('p',{staticClass:"feta mt-3"},[_vm._v(" В согласовании заявления «"+_vm._s(_vm.projectName)+"» будет отказано ")]):_vm._e(),(_vm.type === 'APPROVAL_YES')?_c('p',{staticClass:"feta mt-3"},[_vm._v(" Заявление «"+_vm._s(_vm.projectName)+"» будет согласовано ")]):_vm._e(),(_vm.coordinationETSP)?_c('h6',{staticClass:"bryndza mt-6 mb-4"},[_vm._v(" Электронная цифровая подпись ")]):_vm._e(),(_vm.coordinationETSP)?_c('div',{staticClass:"field_wrapper"},[_c('upload-file',{class:{ field_error: _vm.$v.alignmentPersonalSign.$error },attrs:{"accept-file":[
        'image/jpeg',
        'image/png',
        'image/gif',
        'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
        'application/pdf',
        'application/msword',
        'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
        'text/csv',
      ],"count-file":5,"upload-url":"/uploadfile/index.php","value":_vm.alignmentPersonalSign,"params-body":{ upload_url: 'excavators', privateUserId: _vm.$store.state.userId || -1 },"sub-title":"или перетяните сюда файл общим объёмом не более 50 МБайт"},on:{"input":_vm.loadPersonalSign}}),(_vm.$v.alignmentPersonalSign.$error && !_vm.$v.alignmentPersonalSign.required)?_c('div',{staticClass:"error mozzarella color-fargo bottom"},[_vm._v(" Поле обязательно для заполнения ")]):_vm._e()],1):_vm._e(),_c('div',{staticClass:"field_wrapper"},[_c('r-textarea',{staticClass:"mt-8",class:{ field_error: _vm.$v.comment.$error },attrs:{"rows":_vm.type === 'APPROVAL_NO' || _vm.type === 'APPROVAL_YES' ? 7 : 3,"label":"Комментарий"},model:{value:(_vm.comment),callback:function ($$v) {_vm.comment=(typeof $$v === 'string'? $$v.trim(): $$v)},expression:"comment"}}),(_vm.$v.comment.$error && !_vm.$v.comment.required)?_c('div',{staticClass:"error mozzarella color-fargo bottom"},[_vm._v(" Поле обязательно для заполнения ")]):_vm._e()],1),_c('h6',{staticClass:"bryndza mt-6 mb-4"},[_vm._v(" Дополнительные фото или документ ")]),_c('upload-file',{attrs:{"accept-file":[
      'image/jpeg',
      'image/png',
      'image/gif',
      'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
      'application/pdf',
      'application/msword',
      'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
      'text/csv',
    ],"count-file":5,"upload-url":"/uploadfile/index.php","value":_vm.images,"params-body":{ upload_url: 'excavators', privateUserId: _vm.$store.state.userId || -1 },"count-line":2,"sub-title":"или перетяните сюда до 5 фотографий, общим объёмом не более 50 МБайт"},on:{"input":_vm.loadFiles}}),_c('div',{staticClass:"mt-10 buttons"},[_c('r-button',{staticClass:"flex-1",attrs:{"color":"secondary","title":_vm.buttonsText[0],"width":"wide"},nativeOn:{"click":function($event){return _vm.$rir.modal.close()}}}),_c('r-button',{staticClass:"flex-1",attrs:{"disabled":!!!_vm.comment && (_vm.coordinationETSP ? !!!_vm.alignmentPersonalSign : false),"title":_vm.buttonsText[1],"width":"wide"},nativeOn:{"click":function($event){return _vm.save.apply(null, arguments)}}})],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }