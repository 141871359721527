import Vue from 'vue';
import Vuex from 'vuex';
import Api from '@/api/Api';
import participants from './modules/participants'
import statements from './modules/statements'
import approvals from "./modules/approvals";
const api = new Api();
import {mappedTableList} from "../helpers/utils";

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    excavationList: [],
    organizations: [],

    // ----------
    problems: null,
    documents: [],
    districts: [],
    isDocumentsLoading: true,
    isOrganizationsLoading: true,
    isProblemLoading: true,
    isDistrictsLoading: true,
    user: null,
    alignmentInfo: null,
    excavatorStatuses:{},
    userAccesses:{},
    userId: null,
    cityId: null,
    cases: null
  },
  mutations: {
    setWorkList(state, data) {

      data.forEach(item=>{
        let camelCaseObj = {}
        if(!!item.details?.Address){
          for (let elem in item.details) {
            if(elem === 'INN'){
              camelCaseObj[elem.toLowerCase()] = item.details[elem]
            }else{
              camelCaseObj[elem.split('')[0].toLowerCase() + elem.slice(1)] = item.details[elem]
            }
          }
          item.details = camelCaseObj
        }
      })

      state.excavationList = data;
    },
    setOrganizations(state, orgs) {
      state.organizations = orgs;

    },
    setCases(state, cases) {
      state.cases = cases;
    },
    // -------
    setProblems(state, problems) {
      state.problems = problems;
    },
    setDocuments(state, documents) {
      state.documents = documents;
    },
    setDistricts(state, districts) {
      state.districts = districts;
    },

    setDistrictsLoading(state, status) {
      state.isDistrictsLoading = status;
    },
    setDocsLoading(state, status) {
      state.isDocumentsLoading = status;
    },
    setOrgsLoading(state, status) {
      state.isOrganizationsLoading = status;
    },
    setProblemsLoading(state, status) {
      state.isProblemLoading = status;
    },
    setUser(state, user) {
      state.user = user;
    },
    setUserAccesses(state, user) {
      state.userAccesses = user;
      state.user = user.user;
    },
    setAlignmentInfo(state, user) {
      state.alignmentInfo = user;
    },
    setExcavatorStatuses(state, user) {
      state.excavatorStatuses = user;
    },
    setUserId(state, user) {
      state.userId = user.userId;
    },
    setCityId(state, cityId) {
      state.cityId = cityId;
    },
  },
  getters: {
    getProjectStatus(state){
      return state.excavatorStatuses.excavatorProjectStatuses
    },
    getProjectStatusColor(state){
      return state.excavatorStatuses.excavatorProjectStatusColors
    },
    userAccesses(state){
      return state.userAccesses
    },
    userIsRoot(state){
      return state.userAccesses.access.role === "ADMIN"
    },
    getWorkById: state => id => {
      return state.excavationList.find(el => +el.id === +id);
    },
    getFirmNameById: state => id => {
      return state.organizations.find(el => +el.id === +id)?.details?.title;
    },

    // ----
    getDocumentById: state => id => {
      return state.documents.find(el => +el.id === +id);
    },
    getLoading: state => {
      return state.isDocumentsLoading || state.isOrganizationsLoading;
    },
    getUser: state => {
      return state.user || {};
    },
    getDays(state) {
      return state.cases;
    },
    getOrganizationById: state => id => {
      return state.organizations.find(el => String(el.id) === String(id));
    },
    getNotHiddenOrganizations(state) {
      return state.organizations/*.filter(org => org.details.hidden === "0")*/
    },
    getChoicestOrganizations(state) {
      return state.organizations.filter(org => org.alignment === 'checked')
    }
  },
  actions: {
    async fetchExcavations(ctx, payload) {
      // ctx.commit('setDistrictsLoading', true);
      const res = await api.getExcavations(payload);
      ctx.commit('setWorkList', res.all);
      ctx.commit('setCases', res.excavatorOrderDeadline);
      // ctx.commit('setDistrictsLoading', false);
      return res
    },
    async setExcavation(ctx, data) {
      // ctx.commit('setDistrictsLoading', true);
      const res = await api.setExcavation(data);
      // ctx.commit('setDistrictsLoading', false);
      return res
    },
    async fetchOrganizations(ctx, data) {
      ctx.commit('setOrgsLoading', true);
      const res = await api.fetchOrganizations(data);
      // console.log('orgs', res);
      ctx.commit('setOrganizations', res.all);
      ctx.commit('setOrgsLoading', false);


      ctx.commit('setAlignmentOrganizations',
        mappedTableList(res.all.filter(org => org.alignment === 'checked'))
      )
      return res
    },
    async setOrganization(ctx, data) {
      const res = await api.setOrganization(data);
      // console.log('serOrganization', res);
    },

    // ==__==__++

    async getDistricts(ctx) {
      ctx.commit('setDistrictsLoading', true);
      const res = await api.getDistricts();
      // console.log('districts___', res);
      ctx.commit('setDistricts', res.features);
      ctx.commit('setDistrictsLoading', false);
    },
    async getProblems(ctx, data) {
      ctx.commit('setProblemsLoading', true);
      const res = await api.getProblems(data);
      // console.log('problems---------', res.all);
      ctx.commit('setProblemsLoading', false);
      ctx.commit('setProblems', res.all);
    },
    async getOrganizations(ctx) {
      ctx.commit('setOrgsLoading', true);
      const res = await api.getOrganizations();
      // console.log('orgs', res);
      ctx.commit('setOrganizations', res);
      ctx.commit('setOrgsLoading', false);
    },
    async getDocuments(ctx) {
      ctx.commit('setDocsLoading', true);
      const res = await api.getDocuments();
      // console.log('getDocuments', res);
      // ctx.commit('setDocuments', res.all);
      ctx.commit('setDocuments', res);
      ctx.commit('setDocsLoading', false);
    },
    async getUserInfo(ctx) {
      const res = await api.getUserInfo();
      ctx.commit('setUserId', res);
      // console.log('userInfo', res, ctx.state.user);
    },
    async setDocument({dispatch}, data) {
      const res = await api.setDocument(data);
      // console.log('setDocument', res);
      return res;
    },
    async getUserAccesses(ctx) {
      const res = await api.getUserAccesses();
      ctx.commit('setUserAccesses', res);
      // console.log('userInfo', res, ctx.state.user);
    },

    async editDocument({dispatch}, data) {
      const res = await api.editDocument(data);
      // console.log('editDocument', res);
      return res;
    },
    async editOrganization({dispatch}, data) {
      const res = await api.editOrganization(data);
      // console.log('editDocument', res);
      return res;
    },

    async deleteOrganization(ctx, id) {
      const res = await api.deleteOrganization(id);
      // console.log('deleteOrg', res);
      return res;
    },

    async deleteDocument(ctx, id) {
      const res = await api.deleteDocument(id);
      // console.log('deleteDoc', res);
      return res;
    },
    async getUsers() {
      const res = await api.getUsers();
      return res.all.filter(item => !!item.fio)
      /*
      * return res.users.filter(item => item.active && (!!item?.firstName || !!item?.middleName || !!item?.lastName)).map(el => ({
        ...el,
        fio: (el?.firstName || '') +' '+ (el?.middleName || '') +' '+ (el?.lastName || ''),
        firm: el.organisations || '',
        job:el.orgPosition || ''
      }));*/
    },
    async checkExcavatorIsAlignment(ctx){
      const res = await api.checkExcavatorIsAlignment();
      ctx.commit('setAlignmentInfo', res);
      return res
    },
    async getExcavatorStatuses(ctx){
      const res = await api.getExcavatorStatuses();
      ctx.commit('setExcavatorStatuses', res);
      return res
    },
    async getProjectDocs(ctx, projectId){
      const res = await api.getProjectDocs(projectId);
      return res
    },
    async getCityId(ctx){
      const res = await api.getCityId();
      ctx.commit('setCityId', res.cityId);
    }

  },
  modules: {
    participants,
    statements,
    approvals
  }
});
