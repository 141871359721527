<template>
  <div>
    <r-button-action
      title="Выбрать из списка организаций"
      icon="add"
      @click="OpenChoiceOrganizationModal"
    />
    <!-- <div class="objects__filter mt-6">
      <r-input
        class="flex-1"
        label="Поиск"
        v-model="search"
      >
        <template #before>
          <r-icon size="16" fill="rocky" icon="search" fill="titanic" class="anie"/>
        </template>
      </r-input>
    </div>-->
    <loader v-if="isLoading" />
    <template v-else>
      <div class="mt-6 flex">
        <div class="ml-auto parmigiano color-titanic opacity-48">
          {{ !!searchText ? 'Найдено' : 'Всего' }} {{ countRow }}
        </div>
      </div>
      <drag-drop-table
        :headings="headings"
        :draggable="true"
        :list="filteredElements"
        class="mt-6"
        :columns-grid="tableGridColumns"
        v-if="filteredElements.length"
        @onDelete="onDelete"
      />
      <not-found v-else />
    </template>

  </div>
</template>

<script>
import DragDropTable from './DragDropTable';
import ChoiceOrganizationModal from './ChoiceOrganizationModal';
import notFound from './notFound';
import { mappedTableList } from '../helpers/utils';
import Loader from "./Loader";

export default {
  name: 'ParticipantsAgreementTab',
  components: {
    Loader,
    DragDropTable,
    notFound
  },
  data() {
    return {
      searchText: null,
      timeoutId: null,
      headings: [
        'Наименование',
        // 'ИНН',
        'Телефон',
        'Имейл',
        'Доверенное лицо',
        'Почтовый адрес'
      ],
      tableList: [],
      tableGridColumns: [
        '256px',
        // '80px',
        '105px',
        '195px',
        '185px',
        '240px'
      ]
    };
  },
  computed: {
    isLoading() {
      return false;
      return this.$store.getters.getLoading;
    },
    countRow() {
      return this.filteredElements.length;
    },
    search: {
      get() {
        return this.searchText;
      },
      set(val) {
        if (this.timeoutId) clearTimeout(this.timeoutId);
        this.timeoutId = setTimeout(() => {
          this.searchText = typeof val === 'string' ? val.toLowerCase() : null;
        }, 420);
      }
    },
    filteredElements() {
      const tableList = mappedTableList(this.$store.getters.getChoicestOrganizations);
      return tableList;
    }
  },
  async created() {
    await this.$store.dispatch('fetchOrganizations');
  },
  mounted() {
  },
  methods: {
    async onDelete() {
      await this.$store.dispatch('fetchOrganizations');
    },
    async OpenChoiceOrganizationModal() {
      await this.$rir.modal.open(ChoiceOrganizationModal, {
        headings: this.headings,
        columnsGrid: this.tableGridColumns
      });
    }
  }
};
</script>

<style scoped>
::v-deep .r-modal__content {
  align-items: start !important;
}
</style>
