<template>
  <div class="work-form flex flex-direction-column">
    <admin-header
      :title="!isEditPage ? 'Новая работа' : 'Работа'"
      :to="$attrs.isStatement
        ? { name: 'statements', params: { query: { id: $route.query.id } } } : { name: 'admin' }"
    >
      <sup
        class="text-c13 color-fargo"
        v-if="!isChangeComp && isEditPage"
      >Не сохранено</sup>
    </admin-header>

    <!-- <r-notification ref="notify" />
    <r-button
      @click="
        $refs.notify.pushNotification({
          icon: 'layers',
          type: 'warning',
          text: 'Message'
        })
      "
      >Click</r-button
    > -->

    <!-- <r-circle-progress text="" :progress="progressValue" :size="32" /> -->

    <loader v-if="isLoading && isEditPage" />
    <template v-else>
      <div
        v-if="isEditPage"
        class="mt-3"
      >
        <p class="feta opacity-48 inline-flex align-items-center">
          Создана
          {{ created }}
        </p>
        <p
          class="feta opacity-48 inline-flex align-items-center"
          v-if="details.completed_info.isComplete"
        >
          &nbsp;•&nbsp;Завершена
          {{ ended }}
        </p>
        <drop-down-button
          class="mt-2"
          :items="fileDownloadMenu"
          @select="onFileDownload"
          v-if="$store.getters.userIsRoot"
        />
        <r-tabs
          class="mt-7"
          :items="editTabs"
          v-model="activeTabId"
          v-if="isEditPage && details.reports.length > 0"
        />
        <r-tabs
          class="mt-7"
          :items="[
            {
              id: 'information',
              title: 'Информация',
            },
            {
              id: 'workingProcess',
              title: 'Ход работы',
              disabled: true,
            },
          ]"
          v-model="activeTabId"
          v-if="isEditPage && details.reports.length <= 0"
        />
      </div>
      <div
        class="edit_tab"
        v-show="activeTabId.id === 'information'"
      >
        <div class="mt-6 flex flex-1">
          <div class="flex flex-1 flex-direction-column">
            <work-only-readable
              :work="work"
              v-if="!this.$store.getters.userIsRoot"
            />
            <div
              v-else
              class="inputable"
            >
              <r-textarea
                @input="isChange('description')"
                :rows="3"
                :class="{ field_error: $v.details.description.$error }"
                label="Описание работы"
                v-model.trim="$v.details.description.$model"
                :error="$v.details.description.$error && !$v.details.description.required"
                error-message="Поле обязательно для заполнения"
              />
              <r-checkbox
                v-model="details.isIncident"
                class="mt-6"
                title="Аварийная"
                @input="isChange('isIncident')"
              />
              <h2 class="bryndza mt-6 mb-4">
                Фото до начала работ
              </h2>
              <upload-file
                multiple
                :count-file="5"
                upload-url="/uploadfile/index.php"
                :value="details.images"
                @input="loadFiles"
                :params-body="{ upload_url: 'excavators' }"
                :count-line="2"
                sub-title="или перетяните сюда до 5 фотографий, общим объёмом не более 50 МБайт"
              />
              <h2 class="bryndza mt-10">
                Общий срок проведения работ
              </h2>
              <div class="mt-6 work-form__dashed-cell">
                <r-date-picker
                  is-clear-model
                  click-close
                  :class="{ field_error: $v.details.dateFrom.$error }"
                  label="Начало"
                  v-model="$v.details.dateFrom.$model"
                  @input="isChange('dateFrom')"
                  :error="$v.details.dateFrom.$error && !$v.details.dateFrom.required"
                  error-message="Поле обязательно для заполнения"
                />
                <i class="dash" />
                <r-date-picker
                  is-clear-model
                  click-close
                  label="Окончание"
                  :readonly="!details.dateFrom"
                  :start-date="String(details.dateFrom)"
                  @input="isChange('dateTo')"
                  v-model="details.dateTo"
                />
              </div>
              <p class="feta mt-6 opacity-72">
                Включая восстановление благоустройства
              </p>
              <div class="mt-4 work-form__dashed-cell">
                <r-date-picker
                  is-clear-model
                  click-close
                  label="Начало"
                  :readonly="!details.dateFrom"
                  :start-date="String(details.dateFrom)"
                  @input="isChange('dateFrom2')"
                  v-model="details.dateFrom2"
                />
                <i class="dash" />
                <r-date-picker
                  is-clear-model
                  click-close
                  label="Окончание"
                  :readonly="!details.dateFrom2"
                  :start-date="String(details.dateFrom2)"
                  @input="isChange('dateTo2')"
                  v-model="details.dateTo2"
                />
              </div>
              <p class="feta mt-6 opacity-72">
                Продление производства работ
              </p>
              <div class="mt-4 work-form__dashed-cell">
                <r-date-picker
                  is-clear-model
                  :error="isWorkPeriodError"
                  click-close
                  label="Начало"
                  :readonly="!details.dateFrom"
                  :start-date="String(details.dateFrom)"
                  v-model="details.dateContinuedFrom"
                  @input="isChange('dateContinuedFrom')"
                />
                <i class="dash" />
                <r-date-picker
                  is-clear-model
                  :error="isWorkPeriodError"
                  click-close
                  :readonly="!details.dateContinuedFrom"
                  :start-date="String(details.dateContinuedFrom)"
                  label="Окончание"
                  v-model="details.dateContinuedTo"
                  @input="isChange('dateContinuedTo')"
                />
              </div>

              <r-checkbox
                class="mt-6"
                v-model="details.endPerDate"
                @input="isChange('endPerDate')"
                title="Завершать работу по выставленному сроку"
              />

              <h2 class="bryndza mt-8">
                Разрешение на производство земляных работ
              </h2>
              <r-checkbox
                v-model="details.woOrder"
                title="Без производства"
                class="mt-6"
                @input="isChange('woOrder')"
              />

              <div class="work-form__dashed-cell mt-6 align-items-center">
                <r-input
                  @blur="isChange('orderWriteNumber')"
                  :readonly="details.woOrder"
                  v-model="details.orderWriteNumber"
                  label="Номер разрешения"
                />
                <span class="m-auto briscola opacity-48">от</span>
                <r-date-picker
                  is-clear-model
                  click-close
                  label="Дата разрешения"
                  :readonly="details.woOrder"
                  v-model="details.orderDate"
                  @input="isChange('orderDate')"
                />
              </div>
              <!--
                            <r-input @blur="isChange('orderNo')"
                                       class="mt-6"
                                       label="Номер производства"
                                       :readonly="details.woOrder"
                                       v-model="details.orderNo"
                            />
              -->
              <p class="feta mt-6 opacity-72">
                Продление сроков работ
              </p>
              <div class="work-form__dashed-cell mt-4 align-items-center">
                <r-input
                  @blur="isChange('continuationWorkOrderNo')"
                  label="Номер приказа"
                  :readonly="details.woOrder"
                  v-model="details.continuationWorkOrderNo"
                />
                <span class="m-auto briscola opacity-48">от</span>
                <r-date-picker
                  is-clear-model
                  click-close
                  label="Дата приказа"
                  :readonly="details.woOrder"
                  v-model="details.continuationWorkOrderDate"
                  @input="isChange('continuationWorkOrderDate')"
                />
                <!-- <r-date-picker
                   clickClose
                   label="Продлить до"
                   :readonly="details.woOrder"
                   v-model="details.orderContinue"
                 />-->
              </div>

              <p class="feta mt-6 opacity-72">
                Закрытие работ
              </p>
              <div class="work-form__dashed-cell mt-4 align-items-center">
                <r-input
                  @blur="isChange('endWorkOrderNo')"
                  label="Номер приказа"
                  :readonly="details.woOrder"
                  v-model="details.endWorkOrderNo"
                />
                <span class="m-auto briscola opacity-48">от</span>
                <r-date-picker
                  is-clear-model
                  click-close
                  label="Дата приказа"
                  :readonly="details.woOrder"
                  v-model="details.endWorkOrderDate"
                  @input="isChange('endWorkOrderDate')"
                />
              </div>

              <h2 class="bryndza mt-8">
                Заявитель
              </h2>
              <r-select
                class="mt-3"
                :items="[
                  {
                    id: 0,
                    title: 'Физическое лицо',
                  },
                  {
                    id: 1,
                    title: 'Юридическое лицо',
                  },
                ]"
                @change="onChangeApplicant"
                label="Статус заявителя"
                v-model="details.applicantStatus"
                error-message="Поле обязательно для заполнения"
              />
              <div
                class="mt-6"
                v-if="details.applicantStatus === 0"
              >
                <r-input
                  class="flex-1"
                  @blur="isChange('applicantSurname')"
                  label="Фамилия"
                  v-model="details.applicantSurname"
                  error-message="Поле обязательно для заполнения"
                />
                <div class="flex align-items-center mt-6">
                  <r-input
                    class="flex-1 mr-6"
                    @blur="isChange('applicantName')"
                    label="Имя"
                    v-model="details.applicantName"
                    error-message="Поле обязательно для заполнения"
                  />
                  <r-input
                    class="flex-1"
                    @blur="isChange('applicantPatronymic')"
                    label="Отчество"
                    v-model="details.applicantPatronymic"
                    error-message="Поле обязательно для заполнения"
                  />
                </div>
                <r-input
                  class="mt-6"
                  @blur="isChange('applicantAddress')"
                  label="Адрес регистрации"
                  v-model="details.applicantAddress"
                  error-message="Поле обязательно для заполнения"
                />

                <div class="flex align-items-center mt-6">
                  <r-input
                    class="mr-6 flex-1"
                    @blur="isChange('firmPhone')"
                    label="Телефон"
                    v-model="details.firmPhone"
                    error-message="Поле обязательно для заполнения"
                  />
                  <r-input
                    class="flex-1"
                    @blur="isChange('applicantEmail')"
                    label="Имейл"
                    v-model="details.applicantEmail"
                    error-message="Поле обязательно для заполнения"
                  />
                </div>
              </div>
              <div
                class="mt-6"
                v-if="details.applicantStatus === 1"
              >
                <p
                  class="sulguni color-rocky pointer"
                  @click="showOrganization = true"
                >
                  {{
                    !!details.firmId ? 'Выбрать другую организацию' : 'Выбрать организацию из списка'
                  }}
                </p>
                <r-select
                  class="mt-6"
                  :items="orgList"
                  v-if="showOrganization"
                  label="Наименование организации"
                  v-model="details.firmId"
                  @input="onChangeOrganization"
                  error-message="Поле обязательно для заполнения"
                />
                <div
                  class="mt-7"
                  v-if="!!details.firmId"
                >
                  <div>
                    <p class="mozzarella opacity-48">
                      Наименование
                    </p>
                    <p class="feta opacity-72 mt-1">
                      {{ details.firmName || '-' }}
                    </p>
                  </div>
                  <div class="mt-4">
                    <p class="mozzarella opacity-48">
                      ИНН
                    </p>
                    <p class="feta opacity-72 mt-1">
                      {{ details.inn || '-' }}
                    </p>
                  </div>
                  <div class="flex mt-4">
                    <div class="mr-8">
                      <p class="mozzarella opacity-48">
                        Телефон
                      </p>
                      <p class="feta opacity-72 mt-1">
                        {{ details.firmPhone || '-' }}
                      </p>
                    </div>
                    <div>
                      <p class="mozzarella opacity-48">
                        Имейл
                      </p>
                      <p class="feta opacity-72 mt-1">
                        {{ details.applicantEmail || '-' }}
                      </p>
                    </div>
                  </div>
                  <div class="mt-4">
                    <p class="mozzarella opacity-48">
                      Доверенное лицо
                    </p>
                    <p class="feta opacity-72 mt-1">
                      {{ details.firmDir || '-' }}
                    </p>
                  </div>
                  <div class="mt-4">
                    <p class="mozzarella opacity-48">
                      Почтовый адрес
                    </p>
                    <p class="feta opacity-72 mt-1">
                      {{ details.postAddress || '-' }}
                    </p>
                  </div>
                </div>
              </div>

              <h2 class="bryndza mt-8 mb-4">
                Рекультивация и восстановление
              </h2>
              <r-textarea
                @input="isChange('description')"
                :rows="3"
                label="Работы по восстановлению"
                v-model="details.revegetation"
              />
              <r-input
                @blur="isChange('methodOfWork')"
                class="mt-6"
                label="Способ ведения земляных работ"
                v-model="details.methodOfWork"
              />
              <r-input
                @blur="isChange('guarantee')"
                class="mt-6"
                label="Гарантийный срок устранения дефектов"
                v-model="details.guarantee"
              >
                <template #after>
                  <r-tooltip
                    activator-hover
                    color="amelie"
                  >
                    <template #activator>
                      <r-icon
                        size="16"
                        icon="help"
                        fill="titanic"
                        class="pointer"
                        style="margin-top: 2px;"
                      />
                    </template>
                    <div class="mozzarella color-titanic">
                      Укажите срок в годах<br> или месяцах
                    </div>
                  </r-tooltip>
                </template>
              </r-input>
              <r-button-action
                class="mt-7"
                title="Добавить элемент благоустройства"
                icon="add"
                @click.native="onAddImprovement"
              />
              <div
                class="improvement mt-4"
                v-if="details.improvements.length > 0"
              >
                <div
                  class="improvement__item"
                  v-for="item of details.improvements"
                  :key="item.id"
                >
                  <div class="mr-7 flex-1">
                    <r-input
                      label="Наименование благоустройства"
                      v-model="item.name"
                    />
                    <r-input
                      class="mt-6"
                      label="Площадь, м2"
                      :params-input="{ type: 'number' }"
                      v-model="item.square"
                    />
                  </div>
                  <div @click="onDeleteImprovement(item.id)">
                    <r-icon
                      size="16"
                      icon="delete"
                      fill="fargo"
                      class="pointer"
                    />
                  </div>
                </div>
              </div>

              <h3 class="bryndza mt-8">
                Ограничение движения
              </h3>
              <div class="work-form__dashed-cell mt-4 align-items-center">
                <r-date-picker
                  is-clear-model
                  click-close
                  label="Начало"
                  v-model="details.dateFromFence"
                />
                <i class="dash" />
                <r-date-picker
                  is-clear-model
                  click-close
                  label="Окончание"
                  v-model="details.dateToFence"
                />
              </div>

              <div class="flex mt-10 align-items-center">
                <r-button-action
                  class=" color-rocky"
                  :disabled="!isResetForm"
                  @click="resetForm"
                  title="Очистить все поля"
                  icon="clean"
                />
                <r-button-action
                  v-if="isEditPage"
                  class="ml-6 color-fargo sulguni"
                  color="fargo"
                  title="Удалить земляную работу"
                  icon="delete"
                  @click="onDeleteWork"
                />
              </div>
            </div>
          </div>

          <div class="ml-6 flex-1 overflow-hidden">
            <div class="work-form__map">
              <rir-map
                v-if="isMapReady"
                collapse-btn
                @click="onMapClick"
              >
                <ymap-marker
                  v-if="markerCoords"
                  :coords="markerCoords"
                  :marker-id="'marker'"
                  :icon="$markerIcon('mapPin')"
                  :options="{ draggable: true }"
                  @dragend="onMarkerDragEnd"
                />
              </rir-map>
            </div>
            <div
              class="readable mt-6"
              v-if="!this.$store.getters.userIsRoot"
            >
              <div class="mb-4">
                <p class="mozzarella opacity-48">
                  Адрес проведения работ
                </p>
                <p class="feta opacity-72 mt-1">
                  {{ details.address }}
                </p>
              </div>
              <div class="flex mb-4">
                <div class="mr-8">
                  <p class="mozzarella opacity-48">
                    Широта
                  </p>
                  <p class="feta opacity-72 mt-1">
                    {{ details.lat }}
                  </p>
                </div>
                <div>
                  <p class="mozzarella opacity-48">
                    Долгота
                  </p>
                  <p class="feta opacity-72 mt-1">
                    {{ details.lng }}
                  </p>
                </div>
              </div>
            </div>

            <div
              class="inputable"
              v-else
            >
              <div class="work-form__dashed-cell mt-4 align-items-center">
                <r-input
                  label="Широта"
                  :params-input="{ type: 'number' }"
                  @blur="isChange('lat')"
                  :class="{ field_error: $v.details.lat.$error }"
                  v-model=" $v.details.lat.$model"
                  :error="$v.details.lat.$error && !$v.details.lat.required"
                  error-message="Поле обязательно для заполнения"
                />
                <i class="dash" />
                <r-input
                  label="Долгота"
                  :params-input="{ type: 'number' }"
                  @blur="isChange('lng')"
                  :class="{ field_error: $v.details.lng.$error }"
                  v-model="$v.details.lng.$model"
                  :error="$v.details.lng.$error && !$v.details.lng.required"
                  error-message="Поле обязательно для заполнения"
                />
              </div>

              <r-input
                class="mt-6"
                label="Адрес"
                :class="{ field_error: $v.details.address.$error }"
                v-model="$v.details.address.$model"
                @blur="searchAddress(details.address)"
                @onPressEnter="searchAddress(details.address)"
                after-icon="search"
                :error="$v.details.address.$error && !$v.details.address.required"
                error-message="Поле обязательно для заполнения"
              />
            </div>
          </div>
        </div>
      </div>
      <div
        class="edit_tab"
        v-show="activeTabId.id === 'workingProcess'"
        v-if="details.reports.length > 0"
      >
        <report-tab :reports="details.reports" />
      </div>
      <div
        class="work-form__btn-cont"
        v-if="!this.$store.getters.userIsRoot"
      >
        <r-button
          @click="openModalReport({
            type: 'REPORT',
            title: 'Отчитаться по земляной работе?',
          })"
          title="Отчитаться о результатах работы"
          width="wide"
        />
      </div>
      <div
        class="work-form__btn-cont"
        v-else
      >
        <!-- <r-button @click="save">{{ -->
        <r-button
          @click="submit"
          v-if="$attrs.isStatement"
          title="Добавить"
          width="wide"
        />
        <template v-else>
          <r-button
            @click="submit"
            v-if="!isEditPage"
            title="Добавить"
            width="wide"
          />
          <div
            v-else
            class="flex align-items-center"
          >
            <r-button-action
              v-if="$store.getters.userIsRoot"
              class="color-rocky"
              :disabled="isChangeComp"
              @click.native="submit"
              icon="save"
              title="Сохранить"
            />
            <r-button-action
              class="color-rocky ml-6"
              icon="load"
              title="Отчитаться о результатах"
              @click.native="openModalReport({
                type: 'REPORT',
                title: 'Отчитаться по земляной работе?',
              })"
            />
            <r-button-action
              class="color-rocky ml-6"
              icon="selected"
              :title="details.completed_info.isComplete ? 'Возобновить работу' : 'Завершить работу'"
              @click.native="openModalReport(details.completed_info.isComplete
                ? {
                  type: 'RESUME',
                  title: 'Возобновить земляную работу?',
                } : {
                  type: 'COMPLETED',
                  title: 'Завершить земляную работу?',
                })"
            />
          </div>
        </template>
      </div>
      <delete
        target="work"
        ref="Delete"
      />
    </template>
    <message ref="message" />
  </div>
</template>

<script>
import { required, minLength, requiredIf } from 'vuelidate/lib/validators';
import _ from 'lodash';
import ContactItems from '@/components/ContactItems.vue';
import AdminHeader from '@/components/AdminHeader.vue';
import RirMap from '@/components/RirMap.vue';
import Api from '../api/Api';
import DropDownButton from '../components/DropDownButton.vue';
import Delete from '../components/Delete';
import SaveModal from '../components/SaveModal';
import { getNumberOfDays } from '@/helpers/utils';
import ModalReport from '../components/ModalReport';
import reportTab from '../components/ReportTab';
import WorkOnlyReadable from '../components/WorkOnlyReadable';
import { formattedFiles } from '../helpers/utils';
import Loader from '../components/Loader';
import UploadFile from '../components/UploadFile';
import Message from '../components/Message';

export default {
  components: {
    Loader,
    Delete,
    ContactItems,
    AdminHeader,
    RirMap,
    DropDownButton,
    reportTab,
    WorkOnlyReadable,
    UploadFile,
    Message
  },
  validations: {
    details: {
      description: {
        required
        // minLength: minLength(20)
      },
      dateFrom: {
        required
      },
      address: {
        required
      },
      lng: {
        required
      },
      lat: {
        required
      }
      /* applicantStatus: {
        required
      },
      applicantSurname: {
        required: requiredIf(details => details.applicantStatus === 0)
      },
      applicantName: {
        required: requiredIf(details => details.applicantStatus === 0)
      },
      applicantPatronymic: {
        required: requiredIf(details => details.applicantStatus === 0)
      },
      applicantAddress: {
        required: requiredIf(details => details.applicantStatus === 0)
      },
      firmPhone: {
        required: requiredIf(details => details.applicantStatus === 0)
      },
      applicantEmail: {
        required: requiredIf(details => details.applicantStatus === 0)
      },
      firmId: {
        required: requiredIf(details => details.applicantStatus === 1)
      } */
    }
  },
  data() {
    return {
      modalKey: 0,
      user: {},
      // intervalID: null,
      // progressValue: 0,
      isMapReady: false,
      isResetForm: false,
      isWasEdited: false, // Форма была отредактирована
      created: null, // Дата создания работы
      ended: null, // Дата окончания работы
      equalObject: {}, // Объект для сравнения изменений
      showOrganization: false,
      emptyDetails: {},
      details: {
        Description: '',
        completed_info: {
          isComplete: false,
          date: null,
          completed_type: '', // 'AUTO' - автоматически или 'MANUALLY' вручную,
          completed_count: 0
        },
        reports: [],
        endPerDate: false,
        images: [],
        dateFrom: null, // Общий срок (начало)
        dateTo: null, // Общий срок (окончание)
        address: null, // Адрес разрытия
        lat: null, // Широта
        lng: null, // Долгота
        description: null, // Описание работы

        // Ордер
        woOrder: false, // Без ордера
        isIncident: false, // Авария
        orderWriteNumber: null, // Номер приказа
        orderDate: null, // Дата приказа
        orderNo: null, // № ордера
        continuationWorkOrderNo: null, // № приказа продление сроков работ
        continuationWorkOrderDate: null, // Дата приказа продление сроков работ
        orderContinue: null, // Продление работ до
        endWorkOrderNo: null, // № приказа закрытия работ
        endWorkOrderDate: null, // Дата приказа закрытия работ

        applicantStatus: null,
        isPetitionerIndividual: false,
        // ContactIndividual: null, // ФИО заявителя (Физ. лицо)
        applicantSurname: null, // фамилия заявителя
        applicantName: null, // имя заявителя
        applicantPatronymic: null, // отчество заявителя
        applicantAddress: null, // aдрес заявителя (Физ. лицо)
        applicantEmail: null,
        inn: null, // ИНН
        firmAccount: null, // Расчетный счет
        bank: null, // Банк
        firmId: null, // id организации
        firmName: null, // Наименование организации
        isAddressesMatch: false, // Совпадение юр. и почтового адреса
        firmAddress: null, // Адрес организации (Юридический) ???
        postAddress: null, // Почтовый адрес организации
        firmDir: null, // ФИО руководителя организации
        firmPhone: null, // Тел. ответственного   ???

        // Рекультивация и восстановление
        // revegetationWorks: null, // Работы по восстановлению ???
        // revegetationRoad: null, // Проезжая часть ???
        // revegetationSidewalk: null, // Тротуар ???
        // revegetationTile: null, // Плитка ???
        // revegetationGrass: null, // Газон ???
        // guarantee: null, // Гарантийный срок устранения дефектов

        dateFrom2: null, // Работы по восстановлению
        dateTo2: null, // Работы по восстановлению
        dateFrom3: null, // Даты производства работ
        dateTo3: null, // Даты производства работ
        dateContinuedFrom: null, // Даты продления производства работ
        dateContinuedTo: null, // Даты продления производства работ

        // Восстановление
        revegetation: null, // Описание
        revegetationRoad: null, // Дорога
        revegetationTiles: null, // Плитка
        revegetationTree: null, // Газон
        revegetationFootway: null, // Тротуар

        // Гарантийный срок
        guarantee: null,
        // Благоустройства
        improvements: [],
        // Габариты разрытия
        length: null, // Длина разрытия
        width: null, // Ширина разрытия
        depth: null, // Глубина разрытия

        methodOfWork: null, // Способ ведения зем. работ

        wareHouseAddress: null, // Адрес складирования ???
        ownerDeadline: null, // Срок исполнительной съемки
        isExtraSoil: false, // Излишний грунт ???
        isSastes: false, // Отходы ???
        dateFromFence: null,
        dateToFence: null
      },
      isLoading: true,
      showError: true,
      isSave: true,
      initialData: {},
      activeTabId: {
        id: 'information',
        title: 'Информация'
      },
      editTabs: [
        {
          id: 'information',
          title: 'Информация'
        },
        {
          id: 'workingProcess',
          title: 'Ход работы'
        }
      ],
      work: {}
    };
  },
  computed: {
    isChangeComp() {
      return _.isEqual(this.details, this.initialData);
    },
    isEqual() {
      if (!this.isEditPage) return false;
      return JSON.stringify(this.equalObject) === JSON.stringify(this.details);
    },
    fileDownloadMenu() {
      let menu = [];
      if (this.$store.getters.userIsRoot) {
        menu = [
          /* {
            id: 0,
            active: false,
            value: 'Заявление о выдаче разрешения на производство земляных работ',
            document: 'excavatorRequestDOCX'
          },
          {
            id: 1,
            active: false,
            value: 'Согласие на обработку персональных данных'
          },
          {
            id: 2,
            active: false,
            value: 'Акт о наличии, объемах и техническом состоянии элементов благоустройства',

          }, */
          {
            id: 3,
            active: false,
            value: 'Разрешение на производство земляных работ',
            document: 'excavatorOrderDOCX'
          }
          //  {id: 4, active: false, value: 'Акт о неисполнении правил восстановления благоустройства'},
        ];
      }
      /* const menu = [{id: 0, active: false, value: 'Пустой шаблон карточки'},
         {id: 2, active: false, value: 'Редактирование шаблона'}];

       this.isEditPage && menu.unshift({id: 1, active: true, value: 'Карточку этой работы'});
 */
      return menu;
    },
    isCanSave() {
      if (this.isEditPage && this.isEqual) {
        return false;
      }

      const {
        description,
        dateFrom,
        // dateTo,
        address,
        // woOrder,
        // orderWritNumber,
        // orderDate,
        // orderNo,
        isPetitionerIndividual,
        ContactIndividual,
        // FirmAddressIndividual,
        firmId
        // inn,
        // firmAccount,
        // bank,
        // firmAddress,
        // postAddress,
        // firmDir,
        // firmPhone
      } = this.details;
      const firmData = isPetitionerIndividual ? ContactIndividual : firmId;
      // &&
      //   inn &&
      //   firmAccount &&
      //   bank &&
      //   firmAddress &&
      //   postAddress &&
      //   firmDir &&
      //   firmPhone;
      const isNotEmpty = description && dateFrom && address && firmData;
      // dateTo &&
      // (woOrder ? true : orderWritNumber && orderDate && orderNo)

      return !!isNotEmpty && !this.isWorkPeriodError;
    },
    isWorkPeriodError() {
      if (
        this.details.dateFrom
        && this.details.dateTo
        && this.details.dateFrom3
        && this.details.dateTo3
      ) {
        return (
          new Date(this.details.dateFrom3) < new Date(this.details.dateFrom)
          || new Date(this.details.dateFrom3) > new Date(this.details.dateTo)
          || new Date(this.details.dateTo3) > new Date(this.details.dateTo)
          || new Date(this.details.dateTo3) < new Date(this.details.dateFrom)
        );
      }

      return false;
    },
    badgeProps() {
      const props = {
        color: 'drive',
        text: 'Плановая'
      };
      if (this.details.isIncident) {
        props.color = 'indiana';
        props.text = 'Авария';
      } else if (this.details.endWorkOrderNo) {
        props.color = 'pianist';
        props.text = 'Завершенная';
      }

      return props;
    },
    markerCoords() {
      const { lat } = this.details;
      const { lng } = this.details;
      if (lat && lng) {
        return [lng, lat];
      }

      return null;
    },
    // приказ
    orderNo() {
      return `${this.details.orderWriteNumber} от ${this.details.orderDate}`;
    },
    orgList() {
      const orgList = [...this.$store.state.organizations]
        .filter(org => org.details.isActive);
        /* .map(el => ({
          id: el.id,
          value: el.details.title
        })); */

      return orgList;
    },

    isEditPage() {
      return !!this.$route.query.id && !this.isCopy && !this.$attrs.isStatement;
    },
    isCopy() {
      return !!this.$route.query.copy;
    }
  },
  watch: {
    // progressValue(val) {
    //   if (val >= 100) {
    //     clearInterval(this.intervalID);
    //     this.intervalID = null;
    //     this.progressValue = 0;
    //   }
    // },
    details: {
      handler(val) {
        if (val.isPetitionerIndividual) {
          this.details.inn = null;
          this.details.firmAccount = null;
          this.details.bank = null;
          this.details.firmAddress = null;
          this.details.postAddress = null;
          this.details.firmDir = null;
          this.details.firmPhone = null;
          this.details.firmId = null;
        } else {
          this.details.ContactIndividual = null;
          this.details.FirmAddressIndividual = null;
        }

        if (val.IsAddressesMatch) {
          this.details.postAddress = this.details.firmAddress;
        }

        if (val.woOrder) {
          this.details.orderWriteNumber = null;
          this.details.orderDate = null;
          this.details.orderNo = null;
          this.details.continuationWorkOrderNo = null;
          this.details.continuationWorkOrderDate = null;
          this.details.orderContinue = null;
          this.details.endWorkOrderNo = null;
          this.details.endWorkOrderDate = null;
        }

        if (this.isResetForm) {
          return;
        }
        if (Object.values(val).some(el => !!el)) {
          this.isResetForm = true;
          return true;
        }

        return false;
      },
      deep: true
    }
  },
  beforeMount() {
  },
  activated() {
    this.resetData();
    (this.isEditPage || this.isCopy || this.$attrs.isStatement) && this.setData();

    this.isMapReady = true;
  },
  deactivated() {
    // this.intervalID && clearInterval(this.intervalID);
    this.isMapReady = false;
    this.activeTabId = {
      id: 'information',
      title: 'Информация'
    };
  },
  async mounted() {
    if (this.$store.state.organizations.length === 0) {
      await this.$store.dispatch('fetchOrganizations');
    }

    if (this.$store.state.excavationList.length === 0) {
      await this.$store.dispatch('fetchExcavations');
    }

    // (this.isEditPage || this.isCopy) && this.setData();
    this.isMapReady = true;
  },
  created() {
    const _this = this;
    this.emptyDetails = this.details;
    new Api().getUsers().then(resp => {
      _this.user = resp.all;
    });
  },
  methods: {
    loadFiles(files) {
      this.details.images = formattedFiles(files);
      this.isChange('images');
    },
    onDeleteImprovement(id) {
      const index = this.details.improvements.findIndex(item => item.id === id);
      this.details.improvements.splice(index, 1);
      this.isChange('improvements');
    },
    onAddImprovement() {
      this.details.improvements.push(
        {
          id: this.details.improvements?.length + 1,
          name: null,
          square: null
        }
      );
      this.isChange('improvements');
    },
    submit() {
      this.$v.$touch();
      if (this.$v.$invalid) {
        this.$nextTick(() => {
          document.querySelector('.field_error').scrollIntoView({
            behavior: 'smooth',
            block: 'center'
          });
        });
      } else if (this.isEditPage && !this.$attrs.isStatement) {
        this.OpenSaveModal();
      } else {
        this.save();
      }
    },
    openModalReport(data) {
      this.$v.$touch();
      if (this.$v.$invalid) {
        this.$nextTick(() => {
          document.querySelector('.field_error').scrollIntoView({
            behavior: 'smooth',
            block: 'center'
          });
        });
      } else {
        this.modalKey++;
        this.$rir.modal.open(ModalReport, {
          title: data.title,
          street: this.details.address,
          saveFunc: this.modalReportData,
          type: data.type
        });
      }
    },
    modalReportData(data) {
      const _this = this;
      _this.user = _this.user.filter(item => (_this.$store.state.userId === item.id));
      const username = `${this.$store.state.user.firstName} ${this.$store.state.user.lastName}.`;
      let notification = '';
      if (data.type === 'COMPLETED') {
        notification = `Работы завершены пользователем ${username}`;
      }
      if (data.type === 'RESUME') {
        notification = `Работы возобновлены пользователем ${username}`;
      }
      if (data.type === 'REPORT') {
        notification = `Пользователь ${username} отчитался о результатах работ`;
      }
      if (data.type === 'AUTO_COMPLETED') {
        notification = 'Работы завершены автоматически по наступлению даты окончания работ';
      }

      const payload = {
        orderId: this.$route.query.id,
        notification,
        details: {
          title: notification,
          username,
          comment: data.comment,
          images: data.images,
          type: data.type
        }
      };
      const date = new Date();
      new Api().setOrderReports(payload).then(() => {
        if (data.type === 'RESUME') {
          this.details.completed_info = {
            isComplete: false,
            type: 'RESUME',
            date
          };
        }
        if (data.type === 'COMPLETED') {
          this.details.completed_info = {
            isComplete: true,
            type: 'COMPLETED',
            date
          };
        }
        this.save();
      });
      /* const date = new Date()
       this.details.reports.push({
         id: this.details.reports.length + 1,
         date: date,
         username: this.user?.lastName + ' ' + this.user?.firstName[0] + '.',
         comment: data.comment,
         images: data.images,
         type: data.type,
         isApplicant: false // нужно разобраться. как узнать заявитель или нет?
       })
      */
    },
    OpenSaveModal() {
      this.modalKey++;
      this.$rir.modal.open(SaveModal, {
        saveFunc: this.save,
        title: this.details.address,
        type: 'work'
      });
    },
    isChange(field) {
      /* if (this.initialData[field] === this.details[field]) {
        this.isSave = true;
      } else {
        this.isSave = false;
      } */
    },
    onDeleteWork() {
      this.$refs.Delete.showDelete();
    },
    resetForm() {
      this.details = JSON.parse(JSON.stringify(this.emptyDetails));
      this.isResetForm = false;
    },
    onChangeApplicant() {
      // сброс данных при смене типа заявителя
      this.details.firmId = null;
      this.details.firmName = null;
      this.details.inn = null;
      this.details.firmAccount = null;
      this.details.isAddressesMatch = null;
      this.details.bank = null;
      this.details.firmPhone = null;
      this.details.applicantEmail = null;
      this.details.firmDir = null;
      this.details.postAddress = null;
      this.isChange('applicantStatus');
    },
    onChangeOrganization(val) {
      const orgDetails = { ...this.$store.getters.getOrganizationById(val).details };
      this.details.inn = orgDetails.inn;
      this.details.firmAccount = orgDetails.bankAccount;
      this.details.bank = orgDetails.bankName;
      this.details.firmAddress = orgDetails.address;
      this.details.postAddress = orgDetails.postalAddress;
      this.details.IsAddressesMatch = orgDetails.isAddressesMatch;
      this.details.firmDir = orgDetails?.confidant?.fio;
      this.details.firmName = orgDetails.title;
      this.details.firmPhone = orgDetails?.confidant?.phone;
      this.details.applicantEmail = orgDetails?.confidant?.email;
      this.showOrganization = false;
      this.isChange('firmId');
    },
    async searchAddress(address) {
      if (!address) return;
      const api = new Api();

      const data = {};
      const isFindAddress = Array.isArray(address);

      if (isFindAddress) {
        data.lng = address[0];
        data.lat = address[1];
      } else {
        data.address = this.details.address;
      }

      const res = await api.getAddress(JSON.stringify(data));

      if (isFindAddress && res?.address) {
        if (res?.addressFull?.street && res?.addressFull?.house) {
          this.details.address = `${res.addressFull.street} ${res.addressFull.house}`;
        } else {
          this.details.address = res.address;
        }
      } else if (!isFindAddress && res?.lat && res?.lng) {
        this.details.lat = res.lat;
        this.details.lng = res.lng;
      }
      this.isChange('address');
    },

    async onFileDownload(obj) {
      /* if (obj.id == 2) {
         const api = new Api();
         const res = await api.getTemplateFileTwo();

         if (res?.url) {
           window.open(res.url, '_blank');
         }
       }
       else {
         const id = obj.id === 1 ? this.$route.query.id : null;
         const api = new Api();

         const res = id ? await api.getCardFile(id) : await api.getTemplateFile(`"taskId":-2`);

         if (res?.url) {
           window.open(res.url, '_blank');
         }
       } */
      /* 0 Заявление о выдаче разрешения на производство земляных работ - requestId:Int

1 Согласие на обработку персональных данных - просто ссылка

2 Разрешение на производство земляных работ -  selectedId:Int
4 Акт о неисполнении правил восстановления благоустройства - просто ссылка */

      const api = new Api();
      let res = {};
      if (obj.id === 0) {
        res = await api.printExcavators({
          document: obj.document,
          requestId: this.work.requestId
        });
      }
      if (obj.id === 1) {
        res.url = `/docx_templates/${this.$store.state.cityId}_18_pd.docx`;
      }
      if (obj.id === 2) {
        res.url = `/docx_templates/${this.$store.state.cityId}_18_act.docx`;
      }
      if (obj.id === 3) {
        res = await api.printExcavators({
          document: obj.document,
          orderId: this.$route.query.id
        });
      }
      if (obj.id === 4) {
        res.url = `/docx_templates/${this.$store.state.cityId}_18_act2.docx`;
      }

      if (res?.url) {
        window.open(res.url, '_blank');
      }
    },

    onMarkerDragEnd(e) {
      const coords = e.originalEvent.target.geometry.getCoordinates();
      if (coords?.length === 2) {
        this.details.lng = coords[0]; // lng
        this.details.lat = coords[1]; // lat
        this.searchAddress(coords);
      }
    },

    async onMapClick(coords) {
      if (coords?.length === 2) {
        this.details.lng = coords[0];
        this.details.lat = coords[1];
      }

      this.searchAddress(coords);
    },

    async save() {
      this.details.Description = this.details.description;
      // getStatements
      if (this.$attrs.isStatement) {
        await new Api().operateExcavatorRequest({
          action: 'updateJson',
          item: {
            id: -1,
            projectId: this.$route.query.id, // для первичного создания из проекта
            organizationId: this.details.firmId || -1,
            casse: 1,
            details: this.details
          }
        }).then(res => {
          this.$store.dispatch('setExcavation', {
            casse: 3,
            projectId: this.$route.query.id, // для первичного создания из проекта
            organizationId: this.details.firmId,
            requestId: res.id,
            id: -1,
            item: {
              requestId: res.id,
              dateTo: this.details.dateTo,
              dateFrom: this.details.dateFrom,
              dateContinuedTo: this.details.dateContinuedTo,
              id: -1,
              details: this.details
            }
          });
        });
      } else {
        if (!this.details.completed_info.isComplete) {
          this.details.completed_info = this.checkCompleted({ details: this.details });
        }
        // if (!this.isCanSave) return;
        if (this.work?.requestId) {
          const data = {
            id: +this.isEditPage ? this.$route.query.id : -1,
            organizationId: this.details.firmId,
            dateClosed: this.details.completed_info.type === 'COMPLETED' ? this.details.completed_info.date : null,
            requestId: this.work?.requestId,
            item: {
              requestId: this.work?.requestId,
              dateClosed: this.details.completed_info.type === 'COMPLETED' ? this.details.completed_info.date : null,
              dateTo: this.details.dateTo,
              dateFrom: this.details.dateFrom,
              dateContinuedTo: this.details.dateContinuedTo,
              id: +this.isEditPage ? this.$route.query.id : -1,
              details: {
                ...this.details,
                dateClosed: this.details.completed_info.type === 'COMPLETED' ? this.details.completed_info.date : null
              }
            }
          };
          await this.$store.dispatch('setExcavation', data);
        } else {
          await new Api().operateExcavatorRequest({
            action: 'updateJson',
            item: {
              id: -1,
              projectId: -1, // для первичного создания из проекта
              organizationId: this.details.firmId || -1,
              casse: 1,
              details: this.details
            }
          }).then(res => {
            this.$store.dispatch('setExcavation', {
              id: +this.isEditPage ? this.$route.query.id : -1,
              organizationId: this.details.firmId,
              dateClosed: this.details.completed_info.type === 'COMPLETED' ? this.details.completed_info.date : null,
              requestId: res.id,
              item: {
                requestId: res.id,
                dateClosed: this.details.completed_info.type === 'COMPLETED' ? this.details.completed_info.date : null,
                dateTo: this.details.dateTo,
                dateFrom: this.details.dateFrom,
                dateContinuedTo: this.details.dateContinuedTo,
                id: +this.isEditPage ? this.$route.query.id : -1,
                details: {
                  ...this.details,
                  dateClosed: this.details.completed_info.type === 'COMPLETED' ? this.details.completed_info.date : null
                }
              }
            });
          });
        }
      }
      if (this.isEditPage) {
        window.location.reload();
      } else {
        const r = this.$router.resolve({
          name: 'admin'
        });
        window.location.assign(r.href);
      }
    },

    resetData() {
      this.resetForm();
      this.equalObject = {};
    },
    async setData() {
      const options = {
        day: 'numeric',
        month: 'numeric',
        year: 'numeric',
        hour: 'numeric',
        minute: 'numeric'
      };
      if (this.$attrs.isStatement) {
        await new Api().getExcavations({
          casse: 3,
          projectId: this.$route.query.id,
          id: -1
        }).then(res => {
          if (res.error) {
            this.$refs.message.showMessage(res.error, 'error');
          } else {
            this.details = {
              ...this.details,
              ...res.all[0].details,
              dateTo: res.all[0].dateTo,
              dateFrom: res.all[0].dateFrom,
              dateContinuedTo: res.all[0].dateContinuedTo
            };
            this.statement = res.all[0];
          }
        });
      } else {
        await new Api().getExcavations({
          casse: 0,
          id: this.$route.query.id
        }).then(res => {
          if (res.error) {
            this.$refs.message.showMessage(res.error, 'error');
          } else {
            const camelCaseObj = {};
            if (res.all[0].details?.Address) {
              for (const elem in res.all[0].details) {
                if (elem === 'INN') {
                  camelCaseObj[elem.toLowerCase()] = res.all[0].details[elem];
                } else {
                  camelCaseObj[elem.split('')[0].toLowerCase() + elem.slice(1)] = res.all[0].details[elem];
                }
              }
              res.all[0].details = camelCaseObj;
            }

            this.work = res.all[0];

            this.details = {
              ...this.details,
              ...res.all[0].details,
              dateTo: res.all[0].dateTo,
              dateFrom: res.all[0].dateFrom,
              dateContinuedTo: res.all[0].dateContinuedTo
            };
            this.equalObject = {
              ...this.details,
              ...res.all[0].details,
              dateTo: res.all[0].dateTo,
              dateFrom: res.all[0].dateFrom,
              dateContinuedTo: res.all[0].dateContinuedTo
            };

            this.created = new Date(res.all[0].created).toLocaleString('Ru-ru', options);
          }
        });
      }

      /*  if (!this.details.completed_info.isComplete) {
          this.details.completed_info = this.checkCompleted(work)
        } */
      await new Api().getOrderReports(this.$route.query.id).then(res => {
        if (res.error) {
          this.$refs.message.showMessage(res.error, 'error');
        } else {
          this.details.reports = res.all || [];
        }
      });
      this.ended = new Date(this.details.completed_info.date).toLocaleString('Ru-ru', options);
      this.initialData = JSON.parse(JSON.stringify(this.details));

      setTimeout(() => {
        this.isLoading = false;
      }, 300);

      await this.$store.dispatch('getCityId');
    },
    checkCompleted(work) { // есть баг с этим при первом сохранении
      const dateEnd = work.details?.dateContinuedTo ? work.details.dateContinuedTo : work.details.dateTo;
      if (work.details.endPerDate) {
        if (getNumberOfDays(dateEnd) <= 0) {
          /* this.details.reports.push({
            id: this.details.reports.length + 1,
            date: dateEnd,
            username: null,
            comment: null,
            images: [],
            type: 'AUTO_COMPLETED',
            isApplicant: false // нужно разобраться. как узнать заявитель или нет?
          }) */
          return {
            isComplete: true,
            type: 'AUTO_COMPLETED',
            date: dateEnd
          };
        }
        return {
          isComplete: false,
          type: 'AUTO_COMPLETED',
          date: dateEnd
        };
      }
      return work.details.completed_info;
    }
  }
  // beforeRouteEnter(to, from, next) {
  //   console.log('to from', to, from);
  //   if (to.query.id) {
  //     next(vm => {
  //       vm.setData(to.query.id);
  //     });
  //   } else {
  //     next(vm => vm.resetData());
  //   }
  // }
};
</script>

<style lang="scss" scoped>
.work-form {
  min-height: 100vh;
  padding-bottom: 130px;

  &__dashed-cell {
    display: grid;
    grid-template-columns: 1fr 24px 1fr;
    align-items: center;
  }

  &__double-dashed-cell {
    display: grid;
    grid-template-columns: 1fr 24px 1fr 24px 1fr;
    align-items: center;
  }

  &__map {
    height: 522px;
    overflow: hidden;
  }

  &__btn-cont {
    position: fixed;
    right: 0;
    bottom: 0;
    width: calc(100% - 25.3%);
    background-color: #ffffff;
    z-index: 2;
    padding: 26px 40px 26px 32px;
    box-shadow: 0px 9px 28px rgba(17, 48, 121, 0.18);
  }
}

::v-deep .ya-map-point {
  position: absolute;
  left: -4px;
  top: -4px;
  display: block;
  width: 8px;
  height: 8px;
  background-color: #fff;
  border: 2px solid var(--rir-rocky);
  border-radius: 50%;
}

::v-deep .ya-map-point--edge {
  cursor: pointer !important;
  position: absolute;
  left: -3px;
  top: -3px;
  display: block;
  width: 6px;
  height: 6px;
  background-color: var(--rir-rocky);
  border-radius: 50%;
  opacity: 0.8;
}

.dash {
  width: 8px;
  height: 1px;
  background-color: var(--rir-rocky);
  margin: auto;
}

.m-auto {
  margin: auto;
}

::v-deep textarea:focus {
  background: transparent !important;
}

.improvement {
  display: grid;
  grid-gap: 24px;

  &__item {
    padding: 24px;
    background: #F6F9FE;
    border-radius: 16px;
    display: flex;
    align-items: center;
    width: 100%;
  }
}

::v-deep .r-drag-upload-file__remove {
  fill: var(--rir-fargo) !important;
}

.field_wrapper {
  position: relative;

  .field_error {

    &.r-input {
      background: rgba(#E14761, 0.08);
    }

    &.r-textarea {
      background: rgba(#E14761, 0.08);
    }

    ::v-deep .r-input {
      background: rgba(#E14761, 0.08);
    }
  }

  .error {
    position: absolute;
    font-size: 12px;
    top: 1px;
    right: 16px;
    pointer-events: none;

    &.bottom {
      top: calc(100% + 1px);
      right: unset;
      left: 16px;
    }
  }
}

.disabled_tabs {
  pointer-events: none;

  .opacity-32 {
    opacity: .32 !important;
  }
}
</style>
