<template>
  <div>
    <router-link
      :to="{
        name: activeTabId,
        query: { id: statement.id },
      }"
    >
      <div class="card">
        <div class="card__info">
          <div class="card__header mozzarella">
            <template v-if="!!statement.alignment">
              <span :class="getAlignmentStatus.class">
                {{ getAlignmentStatus.title }}
              </span>
              <span
                class="color-fargo ml-2"
                v-if="statement.alignment[0].status === '0' && isDelay > 0"
              >
                Просрочено на {{
                  isDelay
                }} {{ formatDay(isDelay) }}
              </span>
              <span
                class="color-titanic opacity-48  ml-2"
                v-else-if="statement.alignment[0].status === '1'"
              >
                Осталось {{
                  this.$store.state.excavatorStatuses.excavatorAlignmentDeadline + endDays(new Date(statement.created))
                }} {{
                  formatDay((this.$store.state.excavatorStatuses.excavatorAlignmentDeadline + endDays(new Date(statement.created))))
                }}
              </span>
              <span
                class="opacity-48 ml-2"
                v-else
              >{{ statement.createdF }}</span>
            </template>
            <template v-else>
              <span :class="status.class">
                {{ status.title }}
              </span>
              <span
                class="color-fargo ml-2"
                v-if="statement.status === '1' && isDelay > 0"
              >
                Просрочено на {{ isDelay }} {{ formatDay(isDelay) }}
              </span>
              <span
                class="opacity-48 ml-2"
                v-else
              >{{ statement.createdF }}</span>
            </template>
          </div>

          <p class="sulguni mt-2 truncate">
            {{ statement.details.projectName }}
          </p>

          <p class="mozzarella mt-1 opacity-72 truncate">
            {{ statement.details.address }}
          </p>

          <div class="flex mt-2">
            <p class="briscola truncate">
              {{ statement.details.firmName }}
            </p>
          </div>
        </div>

        <div
          class="flex align-items-center"
          v-if="this.$store.getters.userIsRoot && statement.status === '0'"
        >
          <additional-menu>
            <ul class="card__menu">
              <li
                class="sulguni flex pointer opacity-72"
                @click.stop.prevent="onDelete"
              >
                <r-icon
                  size="16"
                  class="mr-3"
                  icon="delete"
                  fill="fargo"
                />
                Удалить
              </li>
            </ul>
          </additional-menu>
        </div>
      </div>
    </router-link>
  </div>
</template>

<script>
import AdditionalMenu from './AdditionalMenu.vue';
import { getDayAddition, getNumberOfDays } from '../helpers/utils';
import DeleteModal from './DeleteModal';

export default {
  name: 'StatementsCard',
  components: { AdditionalMenu },
  props: {
    activeTabId: String,
    statement: {
      type: Object,
      required: true
    }
  },

  data() {
    return {
      isShowPopover: false
    };
  },
  computed: {
    status() {
      const { status } = this.$props.statement;
      const obj = {
        title: '',
        class: ''
      };
      if (status === '0') {
        obj.title = 'Черновик';
        obj.class = 'text-gentlemen';
      }
      if (status === '1') {
        obj.title = 'На согласовании';
        obj.class = 'color-rocky';
      }
      if (status === '2') {
        obj.title = 'Возврат';
        obj.class = 'color-lebowski';
      }
      if (status === '3') {
        obj.title = 'Согласовано';
        obj.class = 'color-matrix';
      }

      return obj;
    },
    getAlignmentStatus() {
      const { status } = this.$props.statement.alignment[0];
      const statusesObj = {
        0: {
          title: 'На согласовании',
          class: 'color-rocky'
        },
        1: {
          title: 'Возврат',
          class: 'color-lebowski'
        },
        2: {
          title: 'Согласовано мной',
          class: 'color-matrix'
        },
        '-1': {
          title: 'Отозвано',
          class: 'text-fagro'
        }
      };

      return statusesObj[status];
    },
    isDelay() {
      if (this.$store.state.excavatorStatuses.excavatorAlignmentDeadline) {
        const deadline = this.$store.state.excavatorStatuses.excavatorAlignmentDeadline;
        if (this.$props.statement.delayCreated > deadline) {
          return (this.$props.statement.delayCreated - deadline);
        }
      }
      return 0;
    }

  },
  methods: {
    async onDelete() {
      await this.$rir.modal.open(DeleteModal, {
        id: this.$props.statement.id,
        actionPostfix: 'Statement'
      });
    },
    formatDate(date) {
      const options = {
        month: 'numeric',
        day: 'numeric'
      };

      const stringDate = date => {
        const isSame = date.getFullYear() === new Date().getFullYear();
        return date.toLocaleString('Ru-ru', isSame ? options : { ...options, year: 'numeric' });
      };

      return stringDate(new Date(date));
    },
    formatDay(num) {
      return getDayAddition(num);
    },
    endDays(date) {
      return getNumberOfDays(date);
    }

  }
};
</script>

<style lang="scss" scoped>
.card {
  position: relative;
  display: flex;
  justify-content: space-between;
  background-color: #fff;
  border: 1px solid #e4edfb;
  padding: 16px;
  border-radius: 16px;
  height: 100%;
  &:hover {
    box-shadow: 0 4px 16px rgb(4 21 62 / 16%);
  }

  &__header {
    overflow: hidden;
    white-space: nowrap;
    display: flex;
    align-items: center;
  }

  &__icon {
    margin-right: 6px;
  }

  &__info {
    display: flex;
    flex-direction: column;
    overflow: hidden;
  }

  &__restore {
    background-color: transparent;
    cursor: pointer;
  }

  .pointer {
    cursor: pointer;
  }
}

.popover {
  display: inline-block;
  position: relative;
  cursor: pointer;
}

.popover__content {
  overflow: visible !important;
  position: absolute;
  right: 4px;
  top: calc(100% + 4px);
  padding: 4px 8px;
  border-radius: 12px;
  background-color: #fff;
  -webkit-filter: drop-shadow(0px 1px 2px #ccc);
  filter: drop-shadow(0px 1px 2px #ccc);
  z-index: 100;
}
</style>
