<template>
  <span :class="['tag', { tag__active: active }, 'tag_' + color]">{{ title }}</span>
</template>

<script>
export default {
  props: ['title', 'active', 'color']
};
</script>

<style lang="scss" scoped>
.tag {
  display: inline-block;
  position: relative;
  padding: 4px 12px 4px 24px;
  color: #fff;
  white-space: nowrap;
  font-size: 13px;
  line-height: 16px;
  font-weight: 400;
  border-radius: 16px;
  cursor: pointer;
  &:before {
    content: '';
    position: absolute;
    left: 12px;
    top: calc(50% - 4px);
    width: 8px;
    height: 8px;
    border-radius: 50%;
    background-color: #fff;
    opacity: 0.2;
  }
  &.tag_rocky{
    background-color: var(--rir-alien);
  }
  &.tag_fargo{
    background-color: var(--rir-indiana);
  }
  &.tag_lebowski{
    background-color: var(--rir-snatch);
  }
  &.tag_metropolis{
    background-color: var(--rir-metropolis);
    opacity: .72;
  }

  &__active {
    &.tag_rocky{
      background-color: var(--rir-rocky);
    }
    &.tag_fargo{
      background-color: var(--rir-fargo);
    }
    &.tag_lebowski{
      background-color: var(--rir-lebowski);
    }
    &.tag_metropolis{
      background-color: var(--rir-metropolis);
     opacity: 1;
    }
    &:before {
      opacity: 1;
    }
  }
}
</style>
