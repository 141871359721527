<template>
  <r-popover
    class="relative"
    v-click-outside="clickOutside"
    :is-open="isOpen"
    @input="onChangeShow"
  >
    <template #activator="{ on }">
      <r-button-action
        @click.native="on.click"
        icon="load"
        title="Скачать документ"
      />
    </template>

    <template #content>
      <div class="list">
        <template v-for="item in items">
          <div
            class="item"
            @click="!item.disabled && selectItem(item)"
            :key="item.id"
          >
            <r-icon
              size="16"
              fill="rocky"
              v-if="item.icon"
              class="mr-3"
              :icon="item.icon"
            />
            <span class="sulguni">{{ item.value }}</span>
          </div>
        </template>
      </div>
    </template>
  </r-popover>
</template>

<script>
import ClickOutside from 'vue-click-outside';

export default {
  name: 'ButtonDropdown',
  directives: {
    ClickOutside
  },
  props: {
    items: {
      tyep: Array,
      required: true
    }
  },
  data() {
    return {
      isOpen: false
    };
  },

  methods: {
    onChangeShow(val) {
      this.isOpen = val;
    },
    clickOutside() {
      // if (this.isOpen && e.target.closest('.btn-dropdown-list') === null) {
      this.isOpen = false;
      // }
    },
    selectItem(item) {
      this.$emit('select', item);
    }
  }
};
</script>

<style lang="scss" scoped>
.relative {
  position: relative;
}
// .disabled {
//   opacity: 0.72;
// }
.list {
  padding: 12px 0;
}
.item {
  display: flex;
  white-space: nowrap;
  align-items: center;
  opacity: 0.72;
  padding: 14px 16px;
  position: relative;
  &:hover {
    opacity: 1;
    cursor: pointer;
    &::before {
      content: '';
      position: absolute;
      height: 100%;
      left: 0;
      top: 0;
      border-left: 4px solid var(--rir-rocky);
    }
  }
}
::v-deep .r-drop-down__content {
  top: 100%;
  left: 0;
}
</style>
