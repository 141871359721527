<template>
  <div class="card r-map-balloon">
    <div class="card__info">
      <div class="card__header mozzarella">
        <r-icon
          icon="emergency-stop"
          size="16"
          fill="fargo"
          v-if="incident"
          class="mr-2"
        />

        <div>
          <span class="opacity-48">{{ date.from }}-</span>
          <span
            :class="{
              'color-fargo': isOverdue && !!!dateContinuedTo,
              'color-lebowski': isNearEnd && !!!dateContinuedTo && !isOverdue,
              'opacity-48': !isNearEnd && !isOverdue || !!dateContinuedTo,
            }"
          >{{ date.to }}</span>
        </div>

        <svg
          class="opacity-48 mr-2 ml-2"
          v-if="dateContinuedTo"
          width="4"
          height="8"
          viewBox="0 0 4 8"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <g opacity="0.48">
            <path
              d="M1.82999 3.99847L0.172416 1.54823C-0.135128 1.09361 -0.014819 0.476491 0.441133 0.169849C0.897084 -0.136791 1.51602 -0.0168361 1.82356 0.437779L3.82965 3.40321C3.95824 3.59329 4.01203 3.81179 3.99776 4.02412C4.00141 4.22108 3.94646 4.42108 3.82758 4.59679L1.8215 7.56222C1.51395 8.01684 0.895018 8.13679 0.439066 7.83015C-0.0168856 7.52351 -0.137194 6.90639 0.170349 6.45177L1.82999 3.99847Z"
              fill="#04153E"
            />
          </g>
        </svg>

        <div v-if="dateContinuedTo">
          <span class="opacity-48">Продление до </span>
          <span
            :class="{
              'color-fargo': isOverdue,
              'color-lebowski': isNearEnd && !isOverdue,
              'opacity-48': !isNearEnd && !isOverdue,
            }"
          >{{
            formatDate(dateContinuedTo)
          }}</span>
        </div>

        <svg
          class="opacity-48 mr-2 ml-2"
          v-if="completed_info.isComplete"
          width="4"
          height="8"
          viewBox="0 0 4 8"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <g opacity="0.48">
            <path
              d="M1.82999 3.99847L0.172416 1.54823C-0.135128 1.09361 -0.014819 0.476491 0.441133 0.169849C0.897084 -0.136791 1.51602 -0.0168361 1.82356 0.437779L3.82965 3.40321C3.95824 3.59329 4.01203 3.81179 3.99776 4.02412C4.00141 4.22108 3.94646 4.42108 3.82758 4.59679L1.8215 7.56222C1.51395 8.01684 0.895018 8.13679 0.439066 7.83015C-0.0168856 7.52351 -0.137194 6.90639 0.170349 6.45177L1.82999 3.99847Z"
              fill="#04153E"
            />
          </g>
        </svg>
        <div
          class="opacity-48"
          v-if="completed_info.isComplete"
        >
          Завершено
        </div>
      </div>

      <p class="sulguni mt-2 truncate">
        {{ address }}
      </p>

      <p class="mozzarella mt-1 opacity-72 truncate">
        {{ description }}
      </p>

      <div class="flex mt-2">
        <r-icon
          size="16"
          fill="rocky"
          class="card__icon"
          icon="mayor"
        />
        <p class="briscola truncate">
          {{ organization }}
        </p>
      </div>
    </div>

    <!-- <div class="flex align-items-center">
      <additional-menu v-if="active">
        <ul class="card__menu">
          <li class="sulguni flex pointer" @click.stop.prevent="copyItem">
            <r-icon size="16" fill="rocky" class="mr-3" icon="copy" />Дублировать
          </li>
          <li class="sulguni flex mt-6 pointer" @click.stop.prevent="onDeleteItem">
            <r-icon size="16" fill="rocky" class="mr-3" icon="delete" fill="fargo" />Удалить
          </li>
        </ul>
      </additional-menu>
      <button class="card__restore" v-else @click.stop.prevent="onDeleteItem" title="Восстановить">
        <r-icon size="16" fill="rocky" icon="restore" />
      </button>
    </div> -->
  </div>
</template>

<script>
export default {
  name: 'ExcavationBalloon',
  props: {
    completed_info: {
      type: Object,
      required: true
    },
    organization: {
      type: String,
      default: ''
    },

    description: {
      type: String,
      default: ''
    },
    address: {
      type: String,
      default: ''
    },
    dateFrom: {
      type: String,
      default: ''
    },
    dateTo: {
      type: String,
      default: ''
    },
    dateContinuedTo: {
      type: String,
      default: ''
    },

    continueDate: {
      type: String,
      default: ''
    },
    id: {
      type: String,
      required: true
    },
    // active: {
    //   type: Boolean,
    //   required: true
    // },
    incident: {
      type: Boolean,
      default: false
    },
    isOverdue: {
      type: Boolean,
      default: false
    },
    isNearEnd: {
      type: Boolean,
      default: false
    }
  },

  data() {
    return {
      isShowPopover: false
    };
  },

  computed: {
    date() {
      const options = {
        month: 'numeric',
        day: 'numeric'
      };

      const stringDate = date => {
        const isSame = date.getFullYear() === new Date().getFullYear();
        return date.toLocaleString('Ru-ru', isSame ? options : { ...options, year: 'numeric' });
      };

      const from = stringDate(new Date(this.dateFrom));
      const to = !this.dateTo ? 'не указана' : stringDate(new Date(this.dateTo));

      return {
        from,
        to
      };
    },
    formatedContinueDate() {
      const options = {
        month: 'long',
        day: 'numeric'
      };

      const stringDate = date => {
        const isSame = date.getFullYear() === new Date().getFullYear();
        return date.toLocaleString('Ru-ru', isSame ? options : { ...options, year: 'numeric' });
      };

      return stringDate(new Date(this.сontinueDate));
    },
    actionPostfix() {
      let postfix = null;
      if (this.currentTab === 'districts') {
        postfix = 'District';
      } else if (this.currentTab === 'staff') {
        postfix = 'Staff';
      } else if (this.currentTab === 'institutions') {
        postfix = 'Institution';
      }
      return postfix;
    }
  },
  methods: {
    formatDate(date) {
      const options = {
        month: 'numeric',
        day: 'numeric'
      };

      const stringDate = date => {
        const isSame = date.getFullYear() === new Date().getFullYear();
        return date.toLocaleString('Ru-ru', isSame ? options : { ...options, year: 'numeric' });
      };

      return stringDate(new Date(date));
    },
    async onRestore() {
      if (this.actionPostfix) {
        const res = await this.$store.dispatch(`set${this.actionPostfix}`, {
          id: this.uid,
          hidden: 0
        });
        res && this.$store.dispatch(`get${this.actionPostfix}`);
      }

      // this.actionPostfix &&
      // this.$store.dispatch(`set${this.actionPostfix}`, { id: this.uid, hidden: 0 });
      // if (!res.error) {
      //   this.$store.dispatch('loadObjects');
      // }
    },
    copyItem() {
      this.$router.push({ name: 'organizations', query: { id: this.uid, copy: true } });
    },
    async onDeleteItem() {
      const res = await this.$store.dispatch('deleteOrganization', this.uid);
      if (res?.updated) {
        this.$store.dispatch('getOrganizations');
        this.$router.push({ name: 'admin' });
      }
      // let actionName = null;
      // if (this.currentTab === 'districts') {
      //   actionName = 'deleteDistrict';
      // } else if (this.currentTab === 'staff') {
      //   actionName = 'deleteStaff';
      // } else if (this.currentTab === 'institutions') {
      //   actionName = 'deleteInstitution';
      // }

      // actionName && this.$store.dispatch(actionName, this.uid);
      // this.actionPostfix && (await this.$store.dispatch(`delete${this.actionPostfix}`, this.uid));

      // if (this.actionPostfix) {
      //   const res = await this.$store.dispatch(`delete${this.actionPostfix}`, this.uid);
      //   res && this.$store.dispatch(`get${this.actionPostfix}`);
      // }
      // this.actionPostfix && (await this.$store.dispatch(`delete${this.actionPostfix}`, this.uid));
    }
  }
};
</script>

<style lang="scss" scoped>
.card {
  min-width: 364px;
  font-family: 'Golos UI';
  //   position: relative;
  //   display: flex;
  padding: 16px 16px 16px 16px;
  //   &:hover {
  //     box-shadow: 0 4px 16px rgb(4 21 62 / 16%);
  //   }
  &__icon {
    margin-right: 6px;
  }
  &__info {
    display: flex;
    flex-direction: column;
    overflow: hidden;
  }
  &__date {
    white-space: nowrap;
  }
  //   &__title {
  //     overflow: hidden;
  //     text-overflow: ellipsis;
  //     white-space: nowrap;
  //   }
  //   &__address {
  //     opacity: 0.72;
  //     overflow: hidden;
  //     text-overflow: ellipsis;
  //     white-space: nowrap;
  //   }
  //   &__counters {
  //     margin-top: 14px;
  //   }
  //   &__restore {
  //     background-color: transparent;
  //     cursor: pointer;
  //   }
  //   .pointer {
  //     cursor: pointer;
  //   }
}
// .popover {
//   display: inline-block;
//   position: relative;
//   cursor: pointer;
// }
// .popover__content {
//   overflow: visible !important;
//   position: absolute;
//   right: 4px;
//   top: calc(100% + 4px);
//   padding: 4px 8px;
//   border-radius: 12px;
//   background-color: #fff;
//   -webkit-filter: drop-shadow(0px 1px 2px #ccc);
//   filter: drop-shadow(0px 1px 2px #ccc);
//   z-index: 100;
// }
</style>
