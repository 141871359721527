<template>
  <div
    class="report mt-6"
    v-if="!!report"
  >
    <time class="mozzarella mb-2 opacity-48 block">
      {{ report.createdF }}
    </time>
    <p class="parmigiano">
      {{ report.details.title }}
    </p>
    <div
      class="report_info mt-4"
      v-if="report.details.comment"
    >
      <p class="parmigiano ">
        {{ report.details.comment }}
      </p>
      <div
        class="images mt-4"
        v-if="report.details.images.length > 0"
      >
        <r-file
          v-for="image of report.details.images"
          :key="image.url"
          :file="{
            type: image.contentType,
            ...image,
          }"
          :title="image.filename"
          :button-simple-option="{
            color: 'rocky',
            icon: 'load',
          }"
        />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'ReportItem',
  props: {
    report: {
      type: Object,
      required: true
    }
  },
  computed: {
    title() {
      let title;
      const report = this.$props.report.details;

      if (report.type === 'COMPLETED') {
        title = `Работы завершены пользователем ${report.username}`;
      }
      if (report.type === 'RESUME') {
        title = `Работы возобновлены пользователем ${report.username}`;
      }
      if (report.type === 'REPORT') {
        if (report.isApplicant) {
          title = `Заявитель ${report.username} отчитался о результатах работ`;
        } else {
          title = `Пользователь ${report.username} отчитался о результатах работ`;
        }
        return title;
      }

      if (report.type === 'AUTO_COMPLETED') {
        title = 'Работы завершены автоматически по наступлению даты окончания работ';
        return title;
      }
      return title;
    }
  },
  methods: {
    downloadFile(file) {
      if (file?.url) {
        window.open(file.url, '_blank');
      }
    }
  }
};
</script>

<style scoped>
.report {
  max-width: 620px;
}

.report_info {
  background: #F6F9FE;
  border-radius: 8px;
  padding: 16px;
}
</style>
