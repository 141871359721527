<template>
  <div
    class="picker-wrap"
    v-click-outside="clickOutside"
  >
    <div>
      <span
        class="color-rocky mr-2 pointer sulguni"
        @click="isShowContent = !isShowContent"
      >{{
        dateRange
      }}</span>
      <r-icon
        fill="rocky"
        :class="['picker-wrap__icon', { 'picker-wrap__icon-active': isShowContent }]"
        icon="arrow-down"
        size="8"
      />
    </div>

    <div
      class="flex content"
      v-show="isShowContent"
      :key="count"
    >
      <div class="icon-div">
        <r-date-picker
          click-close
          label="С"
          v-model="filterStartDate"
        />
        <div
          style="display: table"
          @click="deleteStart"
        >
          <r-icon
            size="16"
            v-show="filterStartDate"
            icon="close"
            fill="titanic"
            class="anie icon-close"
          />
        </div>
      </div>
      <div class="icon-div">
        <r-date-picker
          class="ml-2"
          click-close
          label="По"
          v-model="filterEndDate"
        />
        <div
          style="display: table"
          @click="deleteEnd"
        >
          <r-icon
            size="16"
            v-show="filterEndDate"
            icon="close"
            fill="titanic"
            class="anie icon-close"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ClickOutside from 'vue-click-outside';

export default {
  name: 'DropdownDatepicker',
  directives: {
    ClickOutside
  },
  props: {
    start: {
      type: [String, null],
      default: null
    },
    end: {
      type: [String, null],
      default: null
    }
  },
  data() {
    return {
      count: 0,
      isShowContent: false
    };
  },

  computed: {
    filterStartDate: {
      get() {
        return this.start;
      },
      set(val) {
        this.$emit('changeStart', val);
      }
    },
    filterEndDate: {
      get() {
        return this.end;
      },
      set(val) {
        this.$emit('changeEnd', val);
      }
    },
    dateRange() {
      const options = { year: '2-digit', month: 'numeric', day: 'numeric' };
      if (this.filterStartDate && this.filterEndDate) {
        const start = new Date(this.filterStartDate).toLocaleString('Ru-ru', options).split(',')[0];
        const end = new Date(this.filterEndDate).toLocaleString('Ru-ru', options).split(',')[0];
        return `${start} - ${end}`;
      } if (this.filterStartDate) {
        return new Date(this.filterStartDate).toLocaleString('Ru-ru', options).split(',')[0];
      }
      return 'Период';
    }
  },

  methods: {
    deleteStart() {
      this.filterStartDate = null;
      this.count++;
    },
    deleteEnd() {
      this.filterEndDate = null;
      this.count++;
    },
    select(item) {
      this.$emit('select', item);
    },
    clickOutside(e) {
      if (this.isShowContent && e.target.closest('.r-date-picker__content') === null) {
        this.isShowContent = false;
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.icon-close{
  cursor: pointer;
  position: absolute;
  margin-left: calc(100% - 60px);
  margin-top: -28px;
}

.icon-div{
  display: table;
  position: relative;
}

.picker-wrap {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  &__icon {
    transition: transform 0.2s ease;
    cursor: pointer;
    &-active {
      transform: rotate(180deg);
    }
  }
}
.content {
  position: absolute;
  display: flex;
  flex-direction: row;
  background-color: #fff;

  box-shadow: 0 9px 24px rgba(17, 48, 121, 0.18);
  z-index: 10000;
  border-radius: 8px;

  min-width: 400px;
  right: 0;
  top: 100%;
  padding: 8px 10px;
}
</style>
