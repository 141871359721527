<template>
  <div
    class="table"
    ref="table"
  >
    <scroll-bar
      :options="{ alwaysShowTracks: true }"
      @scroll="scrollHandler"
    >
      <div
        class="table_head"
        v-if="headings.length > 0"
        ref="tableHead"
      >
        <div
          class="table_row mozzarella "
          :class="{ hidden: !checkboxes }"
          :style="!!columnsGrid
            ? ('grid-template-columns:' + (checkboxes ? '16px ' : '') + (draggable ? '115px ' : '') + columnsGrid.join(' '))
            : 'grid-auto-flow:column;'"
        >
          <div
            class="table_col"
            v-if="checkboxes"
          >
            <r-checkbox
              class="mr-2"
              v-model="allChoice"
              :val="rows"
              return-object
              @input="choiceAll"
            />
          </div>
          <div
            class="table_col opacity-48"
            v-if="draggable"
          >
            Позиция в списке
          </div>
          <div
            class="table_col opacity-48"
            v-for="(head, index) of headings"
            :key="index"
          >
            {{ head }}
          </div>
        </div>
      </div>
      <draggable
        :list="filteredRows"
        class="table_body"
        handle=".drag_handle"
        v-bind="dragOptions"
        :class="{ 'pb-100px': checkboxes }"
        :move="dragMove"
        @start="drag = true"
        @end="dragEnd"
      >
        <transition-group
          type="transition"
          :name="!drag ? 'flip-list' : null"
          tag="div"
        >
          <div
            class="table_row"
            v-for="(row, index) of filteredRows"

            :key="index + '1'"
            :class="{ error: checkboxes ? (row.errored_fields.length > 0) : false, hidden: !checkboxes }"
            :style="!!columnsGrid
              ? ('grid-template-columns:' + (checkboxes ? '16px ' : '') + (draggable ? '115px ' : '') + columnsGrid.join(' '))
              : 'grid-auto-flow:column;'"
          >
            <div
              class="table_col"
              v-if="checkboxes"
            >
              <section class="r-tooltip">
                <r-checkbox
                  class="mr-2"
                  v-model="choicest"
                  :val="row"
                  return-object
                  @input="$emit('changed', choicest)"
                  :disabled="row.errored_fields.length > 0"
                  @click="clickCheckbox"
                />
                <section
                  class="r-tooltip__wrapper color-titanic mozzarella"
                  v-if="row.errored_fields.length > 0"
                >
                  Чтобы выбрать этого участника согласования, <span
                    class="color-fargo mozzarella"
                  >укажите {{ errorFields(row.errored_fields) }}</span> в карточке
                  организации
                  <div class="r-tooltip__wrapper--arrow" />
                </section>
              </section>
            </div>
            <div
              class="table_col feta opacity-48 flex align-items-center"
              v-if="draggable"
            >
              <div class="mr-7 inline-flex draggable align-items-center">
                <r-icon
                  size="16"
                  icon="menu"
                  fill="titanic"
                  class="opacity-32 drag_handle"
                />
              </div>
              {{ index + 1 }}
            </div>
            <div
              class="table_col "
              v-for="(col, index) of row.details"
              :class="{
                feta: index === 'title',
                mozzarella: index !== 'title',
                'color-fargo': !col && checkboxes && index === 'ceoName',
              }"
            >
              {{
                !!col ? col : checkboxes ? index === 'ceoName' ? 'Необходимо заполнить' : '-' : '-'
              }}
            </div>
            <div
              class="table_row__delete"
              @click="DeleteItem($event, row.id)"

              :style="'right:' + position + 'px'"
              v-show="!drag"
              v-if="!checkboxes"
            >
              <r-icon
                size="16"
                icon="delete"
                fill="fargo"
                class="opacity-72"
              />
            </div>
          </div>
        </transition-group>
      </draggable>
    </scroll-bar>
  </div>
</template>

<script>

import draggable from 'vuedraggable';
import ScrollBar from '@blackbp/vue-smooth-scrollbar';

export default {
  name: 'DragDropTable',
  components: { draggable, ScrollBar },
  props: {
    headings: {
      type: Array,
      default: () => ([])
    },
    list: {
      type: Array,
      required: true
    },
    columnsGrid: {
      type: Array
    },
    draggable: {
      type: Boolean,
      default: false
    },
    checkboxes: {
      type: Boolean,
      default: false
    }
  },
  data(vm) {
    return {
      draggingItemIndex: null,
      dragOverItemIndex: null,
      rows: [],
      drag: false,
      position: 0,
      limit: 0,
      allChoice: false,
      dragList: []
    };
  },
  computed: {
    filteredRows() {
      if (this.checkboxes) {
        return this.$props.list;
      }
      return this.$props.list.sort((a, b) => a.dragIndex - b.dragIndex);
    },
    choicest: {
      get() {
        return this.$store.state.participants.choicestOrganizations;
      },
      set(value) {
        this.$store.commit('setAlignmentOrganizations', value);
      }
    },
    dragOptions() {
      return {
        animation: 200,
        group: 'description',
        disabled: false,
        ghostClass: 'ghost'
      };
    }

  },
  created() {

  },
  mounted() {
    this.limit = this.$refs.tableHead.offsetWidth - this.$refs.table.offsetWidth;
    this.position = this.$refs.tableHead.offsetWidth - this.$refs.table.offsetWidth;
  },
  methods: {
    dragMove(e) {
      this.dragList = e.relatedContext.list;
    },
    async DeleteItem(e, id) {
      if (this.checkboxes) return;
      const data = {
        id,
        action: 'updateAlignment',
        alignment: 0
      };
      await this.$store.dispatch('setOrganization', data).then(res => {
        this.$nextTick(() => {
          this.$store.dispatch('fetchOrganizations');
        });
      });
    },
    dragEnd(e) {
      this.drag = false;
      // добавить индексы и сохранить
      this.dragList.forEach(async (item, index) => {
        const { id } = item;
        const org = this.$store.getters.getOrganizationById(id);
        const data = {
          id,
          action: 'updateJson',
          item: {
            alignment: org.alignment === 'checked' ? 1 : 0,
            details: {
              ...org.details,
              dragIndex: index
            }
          }
        };
        await this.$store.dispatch('setOrganization', data);
      });
    },
    errorFields(fields, string = []) {
      const names = {
        title: 'наименование',
        //    inn: 'ИНН',
        phone: 'телефон',
        email: 'имейл',
        ceoName: 'доверенное лицо',
        address: 'почтовый адрес'
      };
      fields.forEach(field => {
        if (names[field]) {
          string.push(names[field]);
        }
      });
      return string.join(', ');
    },
    scrollHandler(e) {
      this.position = this.limit - e.offset.x;
    },
    clickCheckbox() {
      this.allChoice = false;
      // this.$store.commit('setChoicestOrganizations', this.choicest)
    },
    choiceAll(choicest = []) {
      if (this.allChoice) {
        choicest = this.list.filter(item => item.errored_fields.length < 1);
      } else {
        choicest = [];
      }
      this.$store.commit('setAlignmentOrganizations', choicest);
    }
  }
};
</script>

<style scoped lang="scss">
.table {
  width: calc(100% + 32px + 40px);
  position: relative;
  left: -32px;
  overflow: hidden;
}

.r-tooltip {
  position: relative;
}

.r-tooltip__wrapper--arrow {
  position: absolute;
  top: 100%;
  left: 12px;
  width: 0;
  height: 0;
  border-left: 6px solid transparent;
  border-right: 6px solid transparent;
  border-bottom: 6px solid;
  background-color: transparent !important;
}

.r-tooltip__wrapper {
  position: absolute;
  top: calc(100% + 6px);
  border-radius: 12px;
  padding: 4px 8px;
  left: 0;
  right: 0;
  z-index: 100;
  filter: drop-shadow(0 4px 16px rgba(48, 3, 14, .16));
  width: -webkit-max-content;
  width: -moz-max-content;
  width: max-content;
  font-size: 13px;
  line-height: 16px;
}

.table_row {
  display: grid !important;
  grid-gap: 24px;
  align-items: center;
  align-content: center;
  padding: 16px 40px 16px 32px;
  border-top: 1px solid #E4EDFB;
  position: relative;

  &.hidden {
    overflow: hidden;
  }

  &__delete {
    position: absolute;
    right: 0;
    bottom: 0;
    top: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    background: #F6F9FE;
    box-shadow: 0px 2px 8px rgba(4, 21, 62, 0.12);
    height: 100%;
    width: 70px;
    transform: translateX(100%);
    transition: .2s ease-in-out;
    transition-delay: .3s;
    cursor: pointer;
    opacity: 0;

    .r-icon {
      transition: .2s ease-in-out;
    }

    &:hover {
      .r-icon {
        opacity: 1;
      }
    }
  }

  &:hover {
    .table_row__delete {
      opacity: 1;
      transform: translateX(0px);
    }
  }

}

.table_body {
  width: max-content;
  min-width: 100%;

  .table_row {
    &:hover {
      background: #F6F9FE;

    }

    &.error {
      &:hover {
        background: #FEF8F9;

        .r-tooltip__wrapper {
          opacity: 1;
        }
      }

      .r-checkbox {
        pointer-events: none;
      }

      .r-checkbox__input:before {
        border: 2px solid #E14761;
        opacity: 0.32;
      }
    }
  }
}

.table_head {
  width: max-content;
  min-width: 100%;

  .table_row {
    border: none;
    padding-top: 0;
    padding-bottom: 32px;
  }
}

.draggable {
  cursor: grab;
}

.draggable:active {
  cursor: grabbing;
}

.flip-list-move {
  transition: transform 0.5s;
}

.no-move {
  transition: transform 0s;
}

.ghost {
  opacity: 0.3;
  //background: red;
}

::v-deep .scrollbar-track-x {
  background: #F6F9FE;
  padding: 4px 0;
  height: 20px;
  bottom: unset;
  top: 28px;

  .scrollbar-thumb {
    background: #E4EDFB;
    border-radius: 4px;
    height: 12px;
    top: 4px;
    cursor: grab;

    &:active {
      cursor: grabbing;
    }

    &:before,
    &:after {
      content: '';
      background-image: url("data:image/svg+xml;charset=UTF-8,%3csvg width='4' height='6' viewBox='0 0 4 6' fill='none' xmlns='http://www.w3.org/2000/svg'%3e%3cpath d='M0.5 0C0.223858 0 0 0.223858 0 0.5V5.5C0 5.77614 0.223858 6 0.5 6C0.776142 6 1 5.77614 1 5.5V0.5C1 0.223858 0.776142 0 0.5 0Z' fill='%2381ABEE'/%3e%3cpath d='M3.5 0C3.22386 0 3 0.223858 3 0.5V5.5C3 5.77614 3.22386 6 3.5 6C3.77614 6 4 5.77614 4 5.5V0.5C4 0.223858 3.77614 0 3.5 0Z' fill='%2381ABEE'/%3e%3c/svg%3e ");
      width: 4px;
      height: 6px;
      background-repeat: no-repeat;
      background-position: center center;
      position: absolute;
      top: 50%;
      margin-top: -3px;
    }

    &:before {
      left: 4px;
    }

    &:after {
      right: 4px;
    }
  }
}

.r-tooltip {
  &__wrapper {
    background-color: var(--rir-amelie);
    filter: drop-shadow(0px 4px 16px rgba(4, 21, 62, 0.16));
    left: -10px;
    top: calc(100% + 10px);
    max-width: 345px;
    pointer-events: none;
    opacity: 0;
    transition: .2s ease-in-out;

    &--arrow {
      border-bottom-color: var(--rir-amelie);
      border-top-color: var(--rir-amelie);
      z-index: 100;
      top: -6px;
    }
  }
}

.pb-100px {
  padding-bottom: 100px;
}
</style>
