import Vue from 'vue';
import VueRouter from 'vue-router';
import Admin from '../views/Admin.vue';
import ObjectList from '../views/ObjectList.vue';
import Work from '../views/Work.vue';
import Organization from '../views/Organization.vue';
import store from "../store";
import Statement from "../views/Statement";
import Approval from "../views/Approval";
import NotFoundPage from "../views/NotFoundPage";
Vue.use(VueRouter);

const routes = [
  {
    path: '/',
    name: 'admin',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    // component: () => import(/* webpackChunkName: "about" */ '../views/Admin.vue')
    component: Admin,

    children: [
      {
        path: '',
        component: ObjectList
      },
      {
        name: 'work',
        path: 'work',
        component: Work,
        props: { isStatement: false }
      },
      {
        name: 'organizations',
        path: 'organizations',
        component: Organization
      },
      {
        name: 'statements',
        path: 'statements',
        component: Statement,
      },
      {
        name: 'statementsWork',
        path: 'statements/work',
        component: Work,
        props: { isStatement: true }
      },
      {
        name: 'approvals',
        path: 'approvals',
        component: Approval
      },
      { path: '*', name:'NotFoundPage', component: NotFoundPage }
    ]
  }
];

const router = new VueRouter({
  mode: 'history',
  base: process.env.VUE_APP_BASE_URL,
  routes
});

router.beforeEach((to, from, next) => {
  const user = store.getters.userAccesses
  // if (!!user.access) {
  //   CheckUser(user, next)
  // } else {
    store.dispatch('getUserAccesses').then(() => {
      CheckUser(store.getters.userAccesses, next)
    });
 // }

});

const CheckUser = (user, next) => {
  if (user.status <= 0) {
    //не залогинен
    window.location.href = `/login2?redirect=${process.env.VUE_APP_BASE_URL}`;
  } else {
    //залогинен
    if (!user.access.role === 'ANONYMOUS') {
      //отправить на главную/ обычный юзер
      window.location.href = `/`;
    }else{
      next();
    }
  }

}

export default router;
