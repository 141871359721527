<template>
  <div>
    <div
      class="r-select__content"
      v-clickOutside="hide"
    >
      <r-input
        readonly
        label="Учётная запись пользователя"
        :value="!!value ? value.fio : null"
        class="r-select"
        @click.native="showSelect = !showSelect"
      >
        <a
          class="r-select__icon"
          ref="icon"
          slot="after"
          tabindex="0"
        >
          <r-icon
            fill="rocky"
            size="20"
            :class="{
              active: showSelect,
            }"
            icon="arrow-down"
          />
        </a>
      </r-input>

      <div
        class="r-select__list"
        v-show="showSelect"
      >
        <div class="r-select__list_item">
          <r-input
            class="flex-1"
            label="Поиск"
            v-model.trim="searchText"
            beforeIcon="search"
          />
        </div>
        <div
          class="scroll_container"
          v-if="filteredUsers.length > 0"
        >
          <div
            class="r-select__list_item"
            v-for="user of filteredUsers"
            :key="user.id"
            :class="{ active: !!value ? user.id === value.id : false }"
            @click="selectItem(user)"
          >
            <p
              class="sulguni opacity-72"
              :inner-html.prop="user.fio | highlights(searchText)"
            />
            <p class="briscola opacity-48">
              {{ user.firm }}
            </p>
          </div>
        </div>
        <p
          class="taleggio mt-3 mb-3"
          style="text-align: center"
          v-else
        >
          Ничего не найдено
        </p>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'SelectSearchInput',
  filters: {
    highlights(stringToSearch, searchTerm) {
      if (searchTerm === '') return stringToSearch;
      const iQuery = new RegExp(searchTerm, 'ig');
      return stringToSearch.toString().replace(iQuery, (matchedText, a, b) => `<span style='color: #3D75E4' class='highlight'>${matchedText}</span>`);
    }
  },
  directives: {
    clickOutside: {
      bind(el, binding, vnode) {
        window.event = function (event) {
          if (!(el == event.target || el.contains(event.target))) {
            vnode.context[binding.expression](event);
          }
        };
        document.body.addEventListener('click', window.event);
      },
      unbind(el) {
        document.body.removeEventListener('click', window.event);
      }
    }
  },
  props: {
    value: {
      type: Object,
      default: null
    }
  },
  data(vm) {
    return {
      searchText: '',
      results: [],
      selected: vm.value,
      showSelect: false
    };
  },
  computed: {
    filteredUsers() {
      if (this.searchText && this.searchText.length > 0) {
        return this.results.filter(result => (
          result.fio.toLowerCase().includes(this.searchText.toLowerCase())
        ));
      }
      return this.results;
    }
  },
  async created() {
    this.results = await this.$store.dispatch('getUsers');
  },
  activated() {
    this.selected = this.$props.value;
  },
  mounted() {
    /* this.$nextTick(()=>{

    }) */
  },
  methods: {
    selectItem(user) {
      this.$emit('change', user);
      this.selected = user;
      this.showSelect = false;
      this.searchText = '';
    },
    hide() {
      this.showSelect = false;
      this.searchText = '';
    }
  }
};
</script>

<style scoped lang="scss">
.r-select__content {
  position: relative;
  ::v-deep .r-input__body{
    pointer-events: none;
  }
}

.r-select__list {
  position: absolute;
  background: #FFFFFF;
  box-shadow: 0px 8px 32px rgba(4, 21, 62, 0.16);
  border-radius: 8px;
  width: 100%;
  left: 0;
  top: calc(100% + 8px);
  padding: 8px 0;
  z-index: 12;
  max-height: 250px;

  .scroll_container {
    overflow-y: auto;
    max-height: 178px;
  }

  &_item {
    padding: 8px 20px;
    transition: .2s ease-in-out;
    cursor: pointer;

    p {
      transition: .2s ease-in-out;

    }

    &:hover,
    &.active{
      background: rgba(#3D75E4, 0.06);

      p.sulguni {
        opacity: 1 !important;
      }

      p.briscola {
        opacity: .56 !important;
      }
    }
  }
}

</style>
