<template>
  <div v-if="list.length > 0">
    <r-button-action
      :title="title"
      is-arrow
      @click="isOpen = !isOpen"
      :value="isOpen"
      :icon="icon"
    />
    <div
      class="list"
      v-if="isOpen"
    >
      <div
        v-for="item of list"
        :key="item.id"
      >
        <div
          class="list_item mt-4"
          v-if="item.status === '-1'"
        >
          <p class="mozzarella opacity-48 mb-3">
            Версия {{ item.version }}, {{ item.created }}
          </p>
          <template v-if="item[typeArray]">
            <r-file
              v-for="file of item[typeArray]"
              :key="file.url"
              :file="{
                type: file.contentType,
                ...file,
              }"
              :title="file.filename"
              type-view="MINI"
            />
          </template>
          <template v-else>
            -
          </template>
        </div>
        <template v-else>
          <p class="sulguni mt-4">
            Ничего не найдено
          </p>
        </template>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'ButtonOpenList',
  props: {
    title: {
      type: String,
      default: 'Открыть список'
    },
    list: {
      type: Array,
      required: true,
      default: () => []
    },
    typeArray: {
      type: String,
      required: true
    },
    icon: {
      type: String,
      default: ''
    },
  },
  data() {
    return {
      isOpen: false
    };
  }
};
</script>

<style scoped>
svg.active{
  transform: rotate(180deg);
}
</style>
