
export default {
  state: () => ({
    choicestOrganizations: [],
    recordChanges:[]
  }),
  mutations: {
    setAlignmentOrganizations(state, organizations){
      state.choicestOrganizations = organizations
    },
    setRecordChanges(state, organizations){
      organizations.forEach(org=>{
        let recordItem = state.recordChanges.find(item => item.id === org.id)
        if(!!recordItem){
          recordItem.isAlignment = !recordItem.isAlignment
        }else{
          state.recordChanges.push({
            id: org.id,
            isAlignment: true
          })
        }
      })
    },
  },

  getters: {

  }
}
