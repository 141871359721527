<template>
  <div>
    <div
      class="flex mb-6"
      v-if="this.$store.getters.userIsRoot"
    >
      <router-link
        class="flex align-items-center sulguni color-rocky mr-6"
        :to="{ name: `${activeTabId}` }"
      >
        <r-icon
          size="16"
          fill="rocky"
          class="mr-2"
          icon="add"
        />
        Новая организация
      </router-link>
    </div>
    <div class="objects__filter">
      <r-input
        class="flex-1 mr-6"
        label="Поиск"
        v-model.trim="search"
        beforeIcon="search"
      />

      <r-button-simple
        size="larishae"
        icon="menu"
        icon-size="20"
        @click="selectedView = 'table'"
        :type="selectedView === 'map' ? 'secondary' : 'primary'"
      />
      <r-button-simple
        size="larishae"
        class="ml-2"
        icon="geopoint"
        :type="selectedView === 'map' ? 'primary' : 'secondary'"
        icon-size="20"
        @click="selectedView = 'map'"
      />
    </div>
    <loader v-if="isLoading" />
    <template v-else>
      <div class="mt-6 flex">
        <r-checkbox
          v-model="showHiddenOrganizations"
          title="Показывать скрытые"
        />
        <div class="ml-auto parmigiano color-titanic opacity-48">
          {{ !!searchText ? 'Найдено' : 'Всего' }} {{ countRow }}
        </div>
      </div>

      <div
        v-if="selectedView === 'map'"
        class="objects__map mt-6"
        ref="map"
      >
        <!-- v-if="filteredWorks.length && isShowMap" -->
        <rir-map
          v-if="isShowMap"
          show-type
          @click="onMapClick"
          :controls="['trafficControl']"
          :show-traffic="isShowTraffic"
        >
          <div v-if="organizationList.length">
            <div
              v-for="marker in organizationList"
              :key="`org_${marker.id}`"
            >
              <ymap-marker
                v-if="Array.isArray(marker.coords) && marker.coords.length"
                :coords="marker.coords"
                :marker-id="`org_${marker.id}`"
                :icon="marker.active ? $markerIcon('mapPin') : $markerIcon('mapPinGray')"
                :options="{ hideIconOnBalloonOpen: false }"
                cluster-name="orgs"
                @click="onMarkerClick(marker.id)"
                @balloonopen="bindListener"
                @balloonclose="unbindListener"
              >
                <balloon-card
                  slot="balloon"
                  :title="marker.title"
                  :address="marker.address"
                  :manager="marker.ceoName"
                  :img="marker.img"
                  :data-id="marker.id"
                >
                  <!-- <div class="flex mt-3 justify-content-between">
                  <r-button class="opacity-72" data-action="copy" transparent contentWidth>
                    <r-icon size="16" fill="rocky" class="mr-2" icon="copy" />
                    <span class="briscola color-rocky">Дублировать</span>
                  </r-button>
                  <r-button transparent contentWidth data-action="delete">
                    <r-icon size="16" fill="rocky" class="opacity-72" icon="delete" fill="fargo" />
                  </r-button>
                </div> -->
                </balloon-card>
              </ymap-marker>
            </div>
          </div>
        </rir-map>
      </div>
      <div
        v-else
        class="objects__list mt-6"
      >
        <router-link
          v-for="item in organizationList"
          :key="item.id"
          :to="{
            name: activeTabId,
            query: { id: item.id },
          }"
        >
          <card
            :uid="item.id"
            :img="item.img || null"
            icon="mayor"
            :title="item.title || ''"
            :address="item.address"
            :active="item.active"
          >
            <!-- <div class="flex align-items-center" v-if="!item.active">
                <r-icon size="16" fill="rocky" class="mr-2" icon="block" fill="fargo" />
                <span class="briscola color-fargo" :style="{ color: 'var(--rir-fargo)' }">
                  Будет удалено
                  {{ formatDate(item.deleted) }}
                </span>
              </div> -->
            <div class="flex align-cenetr">
              <div class="flex">
                <r-icon
                  size="16"
                  fill="rocky"
                  class="mr-2"
                  icon="avatar"
                />
                <span class="briscola mr-5">{{ item.ceoName }}</span>
              </div>
            </div>
          </card>
        </router-link>
      </div>
      <not-found v-if="countRow < 1" />
    </template>
  </div>
</template>

<script>

import notFound from '../components/notFound';
import RirMap from '@/components/RirMap.vue';
import BalloonCard from '@/components/BalloonCard.vue';
import Card from '@/components/AdminCard.vue';
import Loader from "./Loader";

export default {
  name: 'OrganizationsTab',
  components: {
    Loader,
    Card,
    RirMap,
    BalloonCard,
    notFound
  },
  props: ['activeTabId'],
  data() {
    return {
      count: 0,
      showHiddenOrganizations: false,

      selectedView: 'table',
      selectedSortType: 0,
      selectedOrgType: 0,
      // countOnPublic: null,
      // countOnModerate: null,
      searchText: null,
      timeoutId: null,
      selectedMarkerId: null,
      isShowMap: false
    };
  },
  computed: {
    countRow() {
      return this.organizationList.length;
    },
    // Список организаций
    organizationList() {
      let orgList = [...this.$store.state.organizations];

      orgList = orgList.map(el => ({
        id: el.id,
        title: el.details.title,
        address: el.details.address || '',
        active: !!el.details.isActive,
        img: el.details.imgUrl,
        ceoName: el.details.ceoName,
        coords: el.details.coordinates,
        orgType: el.details.orgType,
        hidden: el.details.isActive
      }));

      if (this.searchText) {
        orgList = orgList.filter(
          el => el?.address?.toLowerCase().indexOf(this.searchText) > -1
            || el?.ceoName?.toLowerCase().indexOf(this.searchText) > -1
            || el?.title?.toLowerCase().indexOf(this.searchText) > -1
        );
      }

      if (this.selectedOrgType !== 0) {
        orgList = orgList.filter(el => (this.selectedOrgType === 2 ? el.orgType === 'applicantOrg' : el.orgType !== 'applicantOrg'));
      }

      return orgList.filter(org => this.showHiddenOrganizations || org.hidden);
    },
    search: {
      get() {
        return this.searchText;
      },
      set(val) {
        if (this.timeoutId) clearTimeout(this.timeoutId);
        this.timeoutId = setTimeout(() => {
          this.searchText = typeof val === 'string' ? val.toLowerCase() : null;
        }, 420);
      }
    },
    isLoading() {
      return false;
      return this.$store.getters.getLoading;
    }
  },
  watch: {
    selectedView(val) {
      if (val === 'table' && this.isShowTraffic) {
        this.isShowTraffic = false;
      }
      if (val === 'map') {
        this.isShowMap = true;
      }
    }
  },
  async mounted() {
    await this.$store.dispatch('fetchOrganizations', {
      onlyMine: this.$store.getters.userIsRoot ? 0 : 1
    });
  },
  activated() {
    this.isShowMap = true;
  },

  deactivated() {
    this.isShowMap = false;
  },
  methods: {
    onMarkerClick(id) {
      this.selectedMarkerId = id;
    },

    onMapClick() {
      this.selectedMarkerId = null;
    },

    onBalloonClick() {
      if (!this.selectedMarkerId) return;

      this.$router.push({
        name: this.activeTabId,
        query: { id: this.selectedMarkerId }
      });
    },

    // Обрабочики для Balloon'a карты
    bindListener() {
      if (!this.selectedMarkerId) return;

      const balloon = this.$refs.map.querySelector(
        `.r-map-balloon[data-id="${this.selectedMarkerId}"]`
      );

      balloon.addEventListener('click', this.onBalloonClick);
    },
    unbindListener() {
      if (!this.selectedMarkerId) return;
      const balloon = this.$refs.map.querySelector(
        `.r-map-balloon[data-id="${this.selectedMarkerId}"]`
      );

      balloon.addEventListener('click', this.onBalloonClick);
    }
  }
};
</script>

<style scoped lang="scss">
.icon-close {
  cursor: pointer;
  position: absolute;
  margin-left: calc(100% - 60px);
  margin-top: -28px;
}

.icon-div {
  display: table;
  position: relative;
}

.objects {
  &__filter {
    display: flex;
  }

  &__list {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 4px;

    > * {
      min-width: 0;
    }
  }

  &__map {
    height: 700px;
    border-radius: 24px;
    overflow: hidden;
  }
}

.r-popover {
  display: flex;
  align-items: center;

  .r-icon {
    &.add-icon {
      margin-right: 8px;
    }

    &.data-download__arrow {
      margin-left: 8px;
    }
  }
}

.data-download {
  &__wrapper {
    padding: 12px;

    .r-button {
      display: flex;
      align-items: center;

      &:not(:last-child) {
        margin-bottom: 4px;
      }

      .r-icon {
        margin-right: 8px;
      }
    }
  }
}

::v-deep .r-popover__content {
  padding: 8px !important;
}

::v-deep .ya-map-point {
  position: absolute;
  left: -4px;
  top: -4px;
  display: block;
  width: 8px;
  height: 8px;
  background-color: #fff;
  border: 2px solid var(--rir-rocky);
  border-radius: 50%;
}

::v-deep .ya-map-point--edge {
  cursor: pointer !important;
  position: absolute;
  left: -3px;
  top: -3px;
  display: block;
  width: 6px;
  height: 6px;
  background-color: var(--rir-rocky);
  border-radius: 50%;
  opacity: 0.8;
}
</style>
