<template>
  <div class="work-form flex flex-direction-column">
    <loader v-if="isLoading" />
    <div v-else>
      <admin-header
        :to="{ name: 'admin', query: { tab: 'approvals' } }"
        :title-changed="checkChangedField('projectName')"
        :title="!isEditPage ? 'Новое заявление' : details.projectName || ''"
      >
        <sup
          class="text-c13 color-fargo"
          v-if="!isSave && isEditPage"
        >Не сохранено</sup>
      </admin-header>
      <p
        class="opacity-48 mt-2 feta"
        v-if="!!statement && isEditPage"
      >
        № {{ statement.number }} от
        {{ statement.createdF }}
      </p>

      <template v-if="!!statement.alignment">
        <div
          class="flex align-items-center mt-4"
          v-if="statement.alignment[0].status === '0'"
        >
          <r-badge
            :text="'На согласовании' + (details.versions.length > 0 ? ', вер.' + (details.versions.length + 1) : '')"
            color="alien"
          />
          <p class="feta opacity-72 ml-3">
            Осталось {{ statementInfo.excavatorAlignmentDeadline - statement.delayCreated }}
            {{
              formatDay(Number(statementInfo.excavatorAlignmentDeadline - statement.delayCreated))
            }}
          </p>
        </div>
        <div
          class="flex align-items-center mt-4"
          v-if="statement.alignment[0].status === '1'"
        >
          <r-badge
            text="Возврат"
            color="snatch"
          />
          <p class="feta opacity-72 ml-3">
            Осталось {{ statementInfo.excavatorAlignmentDeadline }}
            {{ formatDay(Number(statement.excavatorAlignmentDeadline)) }}
          </p>
        </div>
        <div
          class="flex align-items-center mt-4"
          v-if="statement.alignment[0].status === '2'"
        >
          <r-badge
            text="Согласовано мной"
            color="drive"
          />
          <p class="feta opacity-72 ml-3">
            Ожидает согласования у других участников
          </p>
        </div>
      </template>

      <!-- <DropDownButton class="mt-6" :items="fileDownloadMenu" @select="onFileDownload">
        <template>
          <button class="pointer color-rocky sulguni">
            <r-icon size="16" fill="rocky" class="mr-2" icon="load"/>
            Скачать документ
          </button>
        </template>
      </DropDownButton>
-->
      <r-tabs
        class="mt-7"
        :items="editTabs"
        v-model="activeTabId"
        v-if="!isEmpty(statement) && (statement.status === '1' || statement.status === '3' || statement.status === '2')"
      />
      <div
        v-show="activeTabId.id === 'information'"
        class="edit_tab mt-8 flex flex-1"
      >
        <div class="flex flex-1 flex-direction-column">
          <statement-only-readable
            :statement="statement"
            :check-changed-field="checkChangedField"
            v-if="!isEmpty(statement) && (statement.status === '1' || statement.status === '3' || statement.status === '2')"
          />
        </div>

        <div class="ml-6 flex-1 overflow-hidden">
          <div class="work-form__map">
            <rir-map
              v-if="isMapReady"
              collapse-btn
            >
              <ymap-marker
                v-if="markerCoords"
                :coords="markerCoords"
                :marker-id="'marker'"
                :icon="$markerIcon('mapPin')"
              />
            </rir-map>
          </div>

          <div class="readable mt-6">
            <div class="mb-4">
              <p class="mozzarella opacity-48">
                Адрес проведения работ
              </p>
              <p
                class="feta opacity-72 mt-1"
                :class="{ 'color-lebowski': checkChangedField('address') }"
              >
                {{ details.address || '-' }}
              </p>
            </div>
            <div class="flex mb-4">
              <div class="mr-8">
                <p class="mozzarella opacity-48">
                  Широта
                </p>
                <p
                  class="feta opacity-72 mt-1"
                  :class="{ 'color-lebowski': checkChangedField('lat') }"
                >
                  {{ details.lat }}
                </p>
              </div>
              <div>
                <p class="mozzarella opacity-48">
                  Долгота
                </p>
                <p
                  class="feta opacity-72 mt-1"
                  :class="{ 'color-lebowski': checkChangedField('lng') }"
                >
                  {{ details.lng }}
                </p>
              </div>
            </div>
          </div>
        </div>

        <div
          class="work-form__btn-cont"
          v-if="!!statement.alignment && statement.alignment[0].status === '0'"
        >
          <r-button
            class="mr-6"
            @click="openModalReport({
              casse: 1,
              coordinationETSP: statement.details.coordinationETSP,
              title: 'Отказать в согласовании заявления?',
              projectName: details.projectName,
              type: 'APPROVAL_NO',
            })"
            type="secondary"
            title=" Отказать в согласовании"
            width="wide"
          />

          <r-button
            @click="openModalReport({
              casse: 2,
              coordinationETSP: statement.details.coordinationETSP,
              title: 'Согласовать заявление?',
              projectName: details.projectName,
              type: 'APPROVAL_YES',
            })"
            title="Согласовать"
            width="wide"
          />
        </div>
      </div>
      <div
        class="edit_tab mt-8"
        v-show="activeTabId.id === 'history'"
      >
        <statement-history-tab
          :statement="statement"
          :statement-info="statementInfo"
        />
      </div>
    </div>
  </div>
</template>

<script>
import AdminHeader from '../components/AdminHeader';
import Api from '../api/Api';
import RirMap from '@/components/RirMap.vue';
import StatementOnlyReadable from '../components/StatementOnlyReadable';
import DropDownButton from '../components/DropDownButton';
import StatementHistoryTab from '../components/StatementHistoryTab';
import ModalReport from '../components/ModalReport';
import { getDayAddition } from '../helpers/utils';
import Loader from '../components/Loader';

export default {
  name: 'Approval',
  components: {
    Loader,
    AdminHeader,
    RirMap,
    StatementOnlyReadable,
    DropDownButton,
    StatementHistoryTab
  },
  data() {
    return {
      activeTabId: {
        id: 'information',
        title: 'Данные'
      },
      editTabs: [
        {
          id: 'information',
          title: 'Данные'
        },
        {
          id: 'history',
          title: 'История'
        }
      ],
      statement: {},
      isSave: true,
      user: {},
      showOrganization: false,
      isMapReady: false,
      emptyDetails: {},
      details: {
        versions: [],
        projectName: null,
        coordinationETSP: false,
        documents: [],
        description: null,
        images: [],
        // Благоустройства
        improvements: [
          {
            id: 0,
            name: null,
            square: null
          }
        ],
        applicantStatus: null,
        isPetitionerIndividual: false,
        // ContactIndividual: null, // ФИО заявителя (Физ. лицо)
        applicantSurname: null, // фамилия заявителя
        applicantName: null, // имя заявителя
        applicantPatronymic: null, // отчество заявителя
        applicantAddress: null, // aдрес заявителя (Физ. лицо)
        applicantEmail: null,
        inn: null, // ИНН
        firmAccount: null, // Расчетный счет
        bank: null, // Банк
        firmId: null, // id организации
        firmName: null, // Наименование организации
        isAddressesMatch: false, // Совпадение юр. и почтового адреса
        firmAddress: null, // Адрес организации (Юридический) ???
        postAddress: null, // Почтовый адрес организации
        firmDir: null, // ФИО руководителя организации
        firmPhone: null, // Тел. ответственного   ???
        address: null, // Адрес разрытия
        lat: null, // Широта
        lng: null, // Долгота
        reports: [],
        dateFrom: null,
        dateTo: null,

        isContractor: false,
        contractorTitle: null,
        contractorNumber: null,
        contractorDate: null,
        contractorSurname: null,
        contractorName: null,
        contractorPatronymic: null,
        contractorPosition: null,
        contractorPhone: null,
        contractorEmail: null,

        restorationWorkDescription: null,
        excavationMethod: null,
        guaranteePeriod: null,
        isFence: false,
        dateFromFence: null,
        dateToFence: null,
        requestedDatesFrom: null,
        requestedDatesTo: null,

        additionalDocuments: []
      },
      statementInfo: {},
      oldVersion: {}
    };
  },
  computed: {
    isLoading() {
      return this.$store.state.statements.isLoading;
    },
    markerCoords() {
      const { lat } = this.details;
      const { lng } = this.details;
      if (lat && lng) {
        return [lng, lat];
      }

      return null;
    },
    orgList() {
      const orgList = [...this.$store.state.organizations]
        .filter(org => org.details.isActive)
        .map(el => ({
          id: el.id,
          value: el.details.title
        }));

      return orgList;
    },
    isCopy() {
      return !!this.$route.query.copy;
    },
    isEditPage() {
      return !!this.$route.query.id && !this.isCopy;
    }
  },
  async created() {
    this.emptyDetails = this.details;
    if (this.$store.state.organizations.length === 0) {
      await this.$store.dispatch('fetchOrganizations');
    }
    if (this.$route.query.id) {
      new Api().getStatements({
        projectId: this.$route.query.id,
        casse: 6
      }).then(res => {
        this.statementInfo = res;
      });
    }
    this.isMapReady = true;
  },
  activated() {
    this.statement = {};
    this.resetForm();

    (this.isEditPage || this.isCopy) && this.setData();
    this.isMapReady = true;
  },
  deactivated() {
    // this.intervalID && clearInterval(this.intervalID);
    this.isMapReady = false;
    this.activeTabId = {
      id: 'information',
      title: 'Данные'
    };
  },
  methods: {
    checkChangedField(fieldName) {
      if (this.oldVersion.changedFields) {
        return !!this.oldVersion?.changedFields.find(item => item.field === fieldName);
      }
      return false;
    },
    async getVersion(version) {
      await new Api().getStatements({
        casse: 2,
        id: version.id || this.statement.details.versions[0].id
      }).then(res => {
        this.oldVersion = {
          res,
          created: version.created,
          changedFields: res.comparison.filter(item => item.field !== 'versions'
            && item.match !== 100 && item.actual !== item.version)
        };
      });
    },
    openModalReport(data) {
       this.$rir.modal.open(ModalReport, {
        ...data,
        saveFunc: this.submit
      });
    },
    async submit(actionObj) {
      // casse:
      // 1 - отказать
      // 2 - согласовать
      // 3 - уточнить
      // -3 - исключить себя
      // 0 - ожидать (сброс)
      const data = {
        item: {
          projectId: this.statement.id,
          casse: actionObj.casse,
          alignmentComments: actionObj.alignmentComments,
          alignmentAttachment: actionObj.alignmentAttachment,
          alignmentPersonalSign: actionObj.alignmentPersonalSign
        }
      };
      await this.$store.dispatch('operateExcavatorProjectAlignment', data).then(() => {
        const r = this.$router.resolve({
          name: 'index', // надо возвращать на тот таб, из которого удалили
          query: {
            tab: 'approvals'
          }
        });
        window.location.assign(r.href);
      });
    },
    resetForm() {
      this.details = JSON.parse(JSON.stringify(this.emptyDetails));
      this.isResetForm = false;
    },
    async onFileDownload(obj) {
      if (obj.id == 2) {
        const api = new Api();
        const res = await api.getTemplateFileTwo();

        if (res?.url) {
          const anchor = document.createElement('a');
          anchor.href = res.url;
          anchor.download = 'excavation_edit.doc';

          // Append to the DOM
          document.body.appendChild(anchor);

          // Trigger `click` event
          anchor.click();

          // Remove element from DOM
          document.body.removeChild(anchor);
        }
      } else {
        const id = obj.id === 1 ? this.$route.query.id : null;
        const api = new Api();

        const res = id ? await api.getCardFile(id) : await api.getTemplateFile();

        if (res?.url) {
          const anchor = document.createElement('a');
          anchor.href = res.url;
          anchor.download = id ? `excavation_${id}.doc` : 'excavation_tmp.doc';

          // Append to the DOM
          document.body.appendChild(anchor);

          // Trigger `click` event
          anchor.click();

          // Remove element from DOM
          document.body.removeChild(anchor);
        }
      }
    },
    fileDownloadMenu() {
      const menu = [{ id: 0, active: false, value: 'Пустой шаблон карточки' },
        { id: 2, active: false, value: 'Редактирование шаблона' }];

      this.isEditPage && menu.unshift({ id: 1, active: true, value: 'Карточку этой работы' });

      return menu;
    },
    isEmpty(obj) {
      return _.isEmpty(obj);
    },
    formatDay(num) {
      return getDayAddition(num);
    },
    async setData() {
      const statement = await this.$store.dispatch('getApprovalById', this.$route.query.id);
      this.details = { ...this.details, ...statement.all[0].details };
      this.statement = statement.all[0];
      if (statement.all[0]?.details?.versions?.length > 0) {
        this.getVersion(statement.all[0].details.versions[0]);
      }

      this.initialData = JSON.parse(JSON.stringify(this.details));
    }
  }

};
</script>

<style scoped lang="scss">
.work-form {
  min-height: 100vh;
  padding-bottom: 130px;

  &__dashed-cell {
    display: grid;
    grid-template-columns: 1fr 24px 1fr;
    align-items: center;
  }

  &__double-dashed-cell {
    display: grid;
    grid-template-columns: 1fr 24px 1fr 24px 1fr;
    align-items: center;
  }

  &__map {
    height: 522px;
    overflow: hidden;
  }

  &__btn-cont {
    position: fixed;
    right: 0;
    bottom: 0;
    width: calc(100% - 25.3%);
    background-color: #ffffff;
    z-index: 2;
    padding: 26px 40px 26px 32px;
    box-shadow: 0px 9px 28px rgba(17, 48, 121, 0.18);
  }
}

::v-deep .ya-map-point {
  position: absolute;
  left: -4px;
  top: -4px;
  display: block;
  width: 8px;
  height: 8px;
  background-color: #fff;
  border: 2px solid var(--rir-rocky);
  border-radius: 50%;
}

::v-deep .ya-map-point--edge {
  cursor: pointer !important;
  position: absolute;
  left: -3px;
  top: -3px;
  display: block;
  width: 6px;
  height: 6px;
  background-color: var(--rir-rocky);
  border-radius: 50%;
  opacity: 0.8;
}

.dash {
  width: 8px;
  height: 1px;
  background-color: var(--rir-rocky);
  margin: auto;
}

.m-auto {
  margin: auto;
}

::v-deep textarea:focus {
  background: transparent !important;
}

.improvement {
  display: grid;
  grid-gap: 24px;

  &__item {
    padding: 24px;
    background: #F6F9FE;
    border-radius: 16px;
    display: flex;
    align-items: center;
    width: 100%;
  }
}

::v-deep .r-drag-upload-file__remove {
  fill: var(--rir-fargo) !important;
}

.field_wrapper {
  position: relative;

  .field_error {

    &.r-input {
      background: rgba(#E14761, 0.08);
    }

    &.r-textarea {
      background: rgba(#E14761, 0.08);
    }

    ::v-deep .r-input {
      background: rgba(#E14761, 0.08);
    }
  }

  .error {
    position: absolute;
    font-size: 12px;
    top: 1px;
    right: 16px;
    pointer-events: none;

    &.bottom {
      top: calc(100% + 1px);
      right: unset;
      left: 16px;
    }
  }
}

.disabled_tabs {
  pointer-events: none;

  .opacity-32 {
    opacity: .32 !important;
  }
}

.work-form__btn-cont {
  display: flex;
  align-items: center;
}

.opacity-72.color-lebowski {
  opacity: 1 !important;
}

</style>
