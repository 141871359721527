<template>
  <div class="readable">
    <!--<div class="mb-4">
      <p class="mozzarella opacity-48">
        Согласование с Факсимиле
      </p>
      <p class="feta opacity-72 mt-1"
         :class="{'color-lebowski': checkChangedField('coordinationETSP')}">
        {{ statement.details.coordinationETSP === true ? 'Да' : 'Нет' }}
      </p>
    </div>-->
    <div class="mb-4">
      <p class="mozzarella opacity-48">
        Проектная документация
      </p>
      <p class="feta mt-2">
        <template v-if="!!statement.details.documents.length">
          <r-file
            v-for="file of statement.details.documents"
            :key="file.url"
            :file="{
              type: file.contentType,
              ...file,
            }"
            :title="file.name"
            type-view="MINI"
            @clickPreview="(e)=>{ window.open(e.url, '_blank') }"
          />
        </template>
        <template v-else>
          -
        </template>
      </p>
    </div>

    <button-open-list
      class="mb-6"
      type-array="documents"
      :list="projectDocs"
    />

    <div class="mb-4">
      <p class="mozzarella opacity-48">
        Краткое описание проводимых работ
      </p>
      <p
        class="feta opacity-72 mt-1"
        :class="{ 'color-lebowski': checkChangedField('description') }"
        style="word-break: break-word;"
      >
        {{ statement.details.description || '-' }}
      </p>
    </div>

    <div class="mb-4">
      <p class="mozzarella opacity-48">
        Фото до начала работ
      </p>
      <p
        class="feta mt-2"
        :class="{ 'color-lebowski': checkChangedField('images') }"
      >
        <template v-if="!!statement.details.images.length">
          <r-file
            v-for="file of statement.details.images"
            :key="file.url"
            :file="{
              type: file.contentType,
              ...file,
            }"
            :title="file.name"
            type-view="MINI"
            @clickPreview="(e)=>{ window.open(e.url, '_blank') }"
          />
        </template>
        <template v-else>
          -
        </template>
      </p>
    </div>
    <div class="mb-4">
      <p class="mozzarella opacity-48">
        Заявитель
      </p>
      <p
        class="feta opacity-72 mt-1"
        :class="{ 'color-lebowski': checkChangedField('applicantStatus') }"
      >
        {{ statement.details.applicantStatus === 0 ? 'Физическое лицо' : 'Юридическое лицо' }}
      </p>
    </div>
    <div
      class="mb-8"
      v-if="statement.details.applicantStatus === 0"
    >
      <div class="mb-4">
        <p class="mozzarella opacity-48">
          Фамилия
        </p>
        <p
          class="feta opacity-72 mt-1"
          :class="{ 'color-lebowski': checkChangedField('applicantSurname') }"
        >
          {{ statement.details.applicantSurname || '-' }}
        </p>
      </div>

      <div class="flex mb-4">
        <div class="mr-8">
          <p class="mozzarella opacity-48">
            Имя
          </p>
          <p
            class="feta opacity-72 mt-1"
            :class="{ 'color-lebowski': checkChangedField('applicantName') }"
          >
            {{ statement.details.applicantName || '-' }}
          </p>
        </div>
        <div>
          <p class="mozzarella opacity-48">
            Отчество
          </p>
          <p
            class="feta opacity-72 mt-1"
            :class="{ 'color-lebowski': checkChangedField('applicantPatronymic') }"
          >
            {{ statement.details.applicantPatronymic || '-' }}
          </p>
        </div>
      </div>
      <div class="mb-4">
        <p class="mozzarella opacity-48">
          Адрес регистрации
        </p>
        <p
          class="feta opacity-72 mt-1"
          :class="{ 'color-lebowski': checkChangedField('applicantAddress') }"
        >
          {{ statement.details.applicantAddress || '-' }}
        </p>
      </div>
      <div class="flex mb-4">
        <div class="mr-8">
          <p class="mozzarella opacity-48">
            Телефон
          </p>
          <p
            class="feta opacity-72 mt-1"
            :class="{ 'color-lebowski': checkChangedField('firmPhone') }"
          >
            {{ statement.details.firmPhone || '-' }}
          </p>
        </div>
        <div>
          <p class="mozzarella opacity-48">
            Имейл
          </p>
          <p
            class="feta opacity-72 mt-1"
            :class="{ 'color-lebowski': checkChangedField('applicantEmail') }"
          >
            {{ statement.details.applicantEmail || '-' }}
          </p>
        </div>
      </div>
    </div>
    <div
      class="mb-8"
      v-if="statement.details.applicantStatus === 1"
    >
      <div class="mb-4">
        <p class="mozzarella opacity-48">
          Наименование
        </p>
        <p
          class="feta opacity-72 mt-1"
          :class="{ 'color-lebowski': checkChangedField('firmId') }"
        >
          {{ statement.details.firmName || '-' }}
        </p>
      </div>
      <div class="mb-4">
        <p class="mozzarella opacity-48">
          ИНН
        </p>
        <p
          class="feta opacity-72 mt-1"
          :class="{ 'color-lebowski': checkChangedField('inn') }"
        >
          {{ statement.details.inn || '-' }}
        </p>
      </div>
      <div class="flex mb-4">
        <div class="mr-8">
          <p class="mozzarella opacity-48">
            Телефон
          </p>
          <p
            class="feta opacity-72 mt-1"
            :class="{ 'color-lebowski': checkChangedField('firmPhone') }"
          >
            {{ statement.details.firmPhone || '-' }}
          </p>
        </div>
        <div>
          <p class="mozzarella opacity-48">
            Имейл
          </p>
          <p
            class="feta opacity-72 mt-1"
            :class="{ 'color-lebowski': checkChangedField('applicantEmail') }"
          >
            {{ statement.details.applicantEmail || '-' }}
          </p>
        </div>
      </div>
      <div class="mb-4">
        <p class="mozzarella opacity-48">
          Доверенное лицо
        </p>
        <p
          class="feta opacity-72 mt-1"
          :class="{ 'color-lebowski': checkChangedField('firmDir') }"
        >
          {{ statement.details.firmDir || '-' }}
        </p>
      </div>
      <div class="mb-4">
        <p class="mozzarella opacity-48">
          Почтовый адрес
        </p>
        <p
          class="feta opacity-72 mt-1"
          :class="{ 'color-lebowski': checkChangedField('postAddress') }"
        >
          {{ statement.details.postAddress || '-' }}
        </p>
      </div>
    </div>

    <div
      class="mb-8"
      v-if="statement.details.isContractor"
    >
      <div class="mb-4">
        <p class="mozzarella opacity-48">
          Уполномоченный подрядчик
        </p>
        <p
          class="feta opacity-72 mt-1"
          :class="{ 'color-lebowski': checkChangedField('contractorFirmName') }"
        >
          {{ statement.details.contractorFirmName || '-' }}
        </p>
      </div>
      <div class="mb-4">
        <p class="mozzarella opacity-48">
          ИНН
        </p>
        <p
          class="feta opacity-72 mt-1"
          :class="{ 'color-lebowski': checkChangedField('contractorInn') }"
        >
          {{ statement.details.contractorInn || '-' }}
        </p>
      </div>
      <div class="flex mb-4">
        <div class="mr-8">
          <p class="mozzarella opacity-48">
            Телефон
          </p>
          <p
            class="feta opacity-72 mt-1"
            :class="{ 'color-lebowski': checkChangedField('contractorFirmPhone') }"
          >
            {{ statement.details.contractorFirmPhone || '-' }}
          </p>
        </div>
        <div>
          <p class="mozzarella opacity-48">
            Имейл
          </p>
          <p
            class="feta opacity-72 mt-1"
            :class="{ 'color-lebowski': checkChangedField('contractorApplicantEmail') }"
          >
            {{ statement.details.contractorApplicantEmail || '-' }}
          </p>
        </div>
      </div>
      <div class="mb-4">
        <p class="mozzarella opacity-48">
          Доверенное лицо
        </p>
        <p
          class="feta opacity-72 mt-1"
          :class="{ 'color-lebowski': checkChangedField('contractorFirmDir') }"
        >
          {{ statement.details.contractorFirmDir || '-' }}
        </p>
      </div>
      <div class="mb-4">
        <p class="mozzarella opacity-48">
          Почтовый адрес
        </p>
        <p
          class="feta opacity-72 mt-1"
          :class="{ 'color-lebowski': checkChangedField('contractorPostAddress') }"
        >
          {{ statement.details.contractorPostAddress || '-' }}
        </p>
      </div>
      <div class="flex mb-4">
        <div class="mr-8">
          <p class="mozzarella opacity-48">
            № договора подряда
          </p>
          <p
            class="feta opacity-72 mt-1"
            :class="{ 'color-lebowski': checkChangedField('contractorNumber') }"
          >
            {{ statement.details.contractorNumber || '-' }}
          </p>
        </div>
        <div>
          <p class="mozzarella opacity-48">
            Дата договора
          </p>
          <p
            class="feta opacity-72 mt-1"
            :class="{ 'color-lebowski': checkChangedField('contractorDate') }"
          >
            {{ !!statement.details.contractorDate ? new Date(statement.details.contractorDate).toLocaleDateString() : 'Дата не указана' }}
          </p>
        </div>
      </div>
    </div>

    <div class="mb-8">
      <p class="mozzarella opacity-48">
        Намечаемые сроки производства работ
      </p>
      <p class="feta opacity-72 mt-1">
        <span :class="{ 'color-lebowski': checkChangedField('dateFrom') }">
          {{ new Date(statement.details.dateFrom).toLocaleDateString() }}
        </span>–<span :class="{ 'color-lebowski': checkChangedField('dateTo') }">
          {{ new Date(statement.details.dateTo).toLocaleDateString() }}
        </span>
      </p>
    </div>
    <h3 class="bryndza mb-4">
      Нарушаемое в процессе работ благоустройство
    </h3>
    <div class="mb-4">
      <p class="mozzarella opacity-48">
        Работы по восстановлению
      </p>
      <p
        class="feta opacity-72 mt-1"
        :class="{ 'color-lebowski': checkChangedField('restorationWorkDescription') }"
      >
        {{ statement.details.restorationWorkDescription || '-' }}
      </p>
    </div>
    <div class="mb-4">
      <p class="mozzarella opacity-48">
        Способ ведения земляных работ
      </p>
      <p
        class="feta opacity-72 mt-1"
        :class="{ 'color-lebowski': checkChangedField('excavationMethod') }"
      >
        {{ statement.details.excavationMethod || '-' }}
      </p>
    </div>
    <div class="mb-4">
      <p class="mozzarella opacity-48">
        Гарантийный срок устранения дефектов
      </p>
      <p
        class="feta opacity-72 mt-1"
        :class="{ 'color-lebowski': checkChangedField('guaranteePeriod') }"
      >
        {{ statement.details.guaranteePeriod || '-' }}
      </p>
    </div>
    <div class="mb-8 grid">
      <div
        v-for="item of statement.details.improvements"
        :key="item.id"
        v-show="!!item.name"
      >
        <p class="mozzarella opacity-48">
          {{ item.name }}, м2
        </p>
        <p class="feta opacity-72 mt-1">
          {{ item.square }}
        </p>
      </div>
    </div>
    <div class="mb-8">
      <p class="mozzarella opacity-48">
        Ограничение движения
      </p>
      <p class="feta opacity-72 mt-1">
        <span :class="{ 'color-lebowski': checkChangedField('dateFromFence') }">
          {{ !!statement.details.dateFromFence ? new Date(statement.details.dateFromFence).toLocaleDateString() : 'Дата не указана' }}
        </span>–<span :class="{ 'color-lebowski': checkChangedField('dateToFence') }">
          {{ !!statement.details.dateToFence ? new Date(statement.details.dateToFence).toLocaleDateString() : 'Дата не указана' }}
        </span>
      </p>
    </div>
    <div class="mb-6">
      <p class="mozzarella opacity-48">
        Дополнительные документы
      </p>
      <p class="feta mt-2">
        <template v-if="!!statement.details.additionalDocuments.length">
          <r-file
            v-for="file of statement.details.additionalDocuments"
            :key="file.url"
            :file="{
              type: file.contentType,
              ...file,
            }"
            :title="file.name"
            type-view="MINI"
            @clickPreview="(e)=>{ window.open(e.url, '_blank') }"
          />
        </template>
        <template v-else>
          -
        </template>
      </p>
    </div>
    <button-open-list
      class="mb-11"
      type-array="additionalDocuments"
      :list="projectDocs"
    />
  </div>
</template>

<script>
import ButtonOpenList from './ButtonOpenList';

export default {
  name: 'StatementOnlyReadable',
  components: {
    ButtonOpenList
  },
  props: {
    statement: {
      type: Object,
      required: true
    },
    checkChangedField: {
      type: Function,
      default: () => {
      }
    }
  },
  data() {
    return {
      projectDocs: []
    };
  },
  async created() {
    await this.$store.dispatch('getProjectDocs', this.$route.query.id).then(res => {
      this.projectDocs = res.all;
    });
  }
};
</script>

<style scoped lang="scss">
.grid {
  grid-template-columns: 1fr 1fr;
  grid-column-gap: 32px;
  grid-row-gap: 16px;
  justify-content: space-between;
}

.dash {
  width: 8px;
  height: 1px;
  background-color: var(--rir-rocky);
  margin: auto;
}

.m-auto {
  margin: auto;
}

.disabled_tabs {
  pointer-events: none;

  .opacity-32 {
    opacity: .32 !important;
  }
}

.opacity-72.color-lebowski {
  opacity: 1 !important;
}
::v-deep .r-file-list__info{
  margin-bottom: 0;
  opacity: .72;
}
</style>
