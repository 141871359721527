<template>
  <div class="organization-form">
    <admin-header
      :title="!isEditPage ? 'Новая организация' : formData.title || ''"
      :to="{ name: 'admin', query: { tab: 'organizations' } }"
    >
      <sup
        class="text-c13 color-fargo"
        v-if="!isChangeComp && isEditPage"
      >Не сохранено</sup>
    </admin-header>
    <div class="flex mt-8 ">
      <organization-only-readable
        :organization="organization"
        class="flex-1"
        v-if="!this.$store.getters.userIsRoot"
      />
      <div
        v-else
        class="organization-form__form flex flex-1 flex-direction-column"
      >
        <div class="flex">
          <img-uploader
            class="organization-form__img-cont mr-6"
            @change="onUpload"
            @onViewImage="onViewImage"
            @deleteFile="deleteFile"
            :src="formData.imgUrl ? '/cityProperty/rest/' + formData.imgUrl : null"
          />

          <!--          <div class="flex-1">-->
          <!--            <r-radio-group isColumn v-model="formData.orgType">-->
          <!--              <r-radio label="Согласующая организация" value="matchingOrg" />-->
          <!--              <r-radio label="Организация — заявитель" value="applicantOrg" />-->
          <!--            </r-radio-group>-->
          <!--          </div>-->
        </div>

        <r-input
          class="mt-6"
          label="Наименование"
          v-model="formData.title"
          @blur="isChange('title')"
          :class="{ field_error: $v.formData.title.$error }"
          :error="$v.formData.title.$error && !$v.formData.title.required"
          error-message="Поле обязательно для заполнения"
        />

        <!-- <h2 class="taleggio mt-10">Обложка</h2>
        <ImgUploader
          class="organization-form__img-cont mt-6"
          @change="onImgUpload"
          :src="formData.imgUrl || null"
        /> -->

        <h2 class="taleggio mt-10">
          Руководитель
        </h2>
        <r-input
          class="mt-6"
          label="Должность"
          v-model="formData.ceoPosition"
          @blur="isChange('ceoPosition')"
        />
        <r-input
          class="mt-6"
          label="ФИО"
          v-model="formData.ceoName"
          @blur="isChange('ceoName')"
        />

        <h2 class="taleggio mt-10">
          Доверенное лицо
        </h2>

        <r-select
          class="mt-6"
          :is-search="true"
          v-model="formData.confidant"
          return-object
          :items="users"
          @input="changeConfidant"
          label="Учётная запись пользователя"
          title-value="fio"
          sub-title-value="firm"
        />
        <div
          class="confidant_info mt-6"
          v-if="!!formData.confidant"
        >
          <div>
            <p class="mozzarella opacity-48">
              Должность
            </p>
            <p class="feta opacity-72 mt-1">
              {{ formData.confidant.job || '-' }}
            </p>
          </div>
          <div class="mt-4">
            <p class="mozzarella opacity-48">
              Организация
            </p>
            <p class="feta opacity-72 mt-1">
              {{ formData.confidant.firm || '-' }}
            </p>
          </div>
          <div class="flex mt-4">
            <div class="flex-1">
              <p class="mozzarella opacity-48">
                Телефон
              </p>
              <p class="feta opacity-72 mt-1">
                {{ formData.confidant.phone || '-' }}
              </p>
            </div>
            <div class="flex-1">
              <p class="mozzarella opacity-48">
                Имейл
              </p>
              <p class="feta opacity-72 mt-1">
                {{ formData.confidant.email || '-' }}
              </p>
            </div>
          </div>
        </div>
        <!--
                <div class="flex mt-6">
                  <div class="flex-1 mr-2">
                    <r-input @onPressEnter="addManager" label="ФИО" v-model="manager"/>
                    <ContactItems
                      v-show="formData.managers.length"
                      class="mt-4"
                      v-model="formData.managers"
                      icon="avatar"
                    />
                  </div>
                  <r-button @click="addManager" icon="add" :disabled="!manager"/>
                </div>
        -->
        <h2 class="taleggio mt-10">
          Контакты организации
        </h2>
        <div class="flex mt-6">
          <div class="flex-1 mr-2">
            <r-input
              @onPressEnter="addPhone"
              label="Телефон"
              v-model="phone"
            />
            <contact-items
              v-show="formData.contacts.length"
              class="mt-4"
              v-model="formData.contacts"
            />
          </div>
          <r-button-simple
            size="larishae"
            @click="addPhone"
            icon="add"
            :disabled="!phone"
          />
        </div>

        <div class="mt-6 flex align-items-center">
          <div class="flex-1 mr-2">
            <r-input
              label="E-mail"
              @onPressEnter="addMail"
              v-model="email"
            />
            <contact-items
              v-show="formData.emails.length"
              class="mt-4"
              icon="mail"
              v-model="formData.emails"
            />
          </div>
          <r-button-simple
            size="larishae"
            @click="addMail"
            icon="add"
            :disabled="!email"
          />
        </div>

        <h2 class="taleggio mt-10">
          Реквизиты
        </h2>
        <div class="mt-6 flex align-start">
          <r-input
            class="flex-1"
            label="ИНН"
            v-model="formData.inn"
            @blur="isChange('inn')"
          />
          <r-input
            class="ml-6 flex-1"
            label="Расчетный счет организации"
            v-model="formData.bankAccount"
            @blur="isChange('bankAccount')"
          />
        </div>
        <r-input
          class="mt-6"
          label="ОГРН"
          v-model="formData.ogrn"
          @blur="isChange('ogrn')"
        />
        <r-input
          class="mt-6"
          label="Банк"
          v-model="formData.bankName"
          @blur="isChange('bankName')"
        />

        <div class="flex mt-10">
          <!--          <r-button-->
          <!--            class="color-rocky mr-6"-->
          <!--            :class="{ 'opacity-72': !formData.isActive }"-->
          <!--            color="transparent"-->
          <!--            @click="formData.isActive = !formData.isActive"-->
          <!--          >-->
          <!--            <r-icon size="16" fill="rocky" class="mr-2" icon="block" />-->
          <!--            {{ formData.isActive ? 'Скрыть организацию' : 'Показать организацию' }}</r-button-->
          <!--          >-->

          <r-checkbox
            class="mr-6"
            :key="count"
            title="Скрыть организацию"
            @input="opganizationFnc()"
            v-model="isOrganizationHidden"
          />

          <r-button-action
            class="color-fargo mr-6"
            icon="delete"
            color="fargo"
            title="Удалить"
            @click="onDeleteOrg"
          />
        </div>
      </div>

      <div class="ml-6 flex flex-direction-column flex-1 overflow-hidden">
        <rir-map
          v-if="true"
          @click="onMapClick"
          collapse-btn
          style="height: 352px"
          class="mb-6"
          :center="formData.coordinates.length ? formData.coordinates : $cityCenter"
        >
          <ymap-marker
            :coords="formData.coordinates.length ? formData.coordinates : $cityCenter"
            marker-id="marker"
            :options="{
              draggable: this.$store.getters.userIsRoot,
            }"
            :icon="$markerIcon('mapPin')"
            @dragend="onDragMarker"
          />
        </rir-map>
        <div
          class="readable"
          v-if="!this.$store.getters.userIsRoot"
        >
          <div class="flex">
            <div class="mr-8 flex-1">
              <p class="mozzarella opacity-48">
                Юридический адрес
              </p>
              <p class="feta opacity-72 mt-1">
                {{ organization.details.address || '-' }}
              </p>
            </div>
            <div class="flex-1">
              <p class="mozzarella opacity-48">
                Почтовый адрес
              </p>
              <p class="feta opacity-72 mt-1">
                {{ organization.details.postalAddress || '-' }}
              </p>
            </div>
          </div>
        </div>
        <div
          class="inputable"
          v-else
        >
          <h2 class="taleggio">
            Адрес
          </h2>
          <r-input
            class="mt-6"
            label="Юридический"
            v-model="formData.address"
            @blur="searchAddress(formData.address)"
            @onPressEnter="searchAddress(formData.address)"
            :readonly="addressLoading"
          />
          <r-checkbox
            class="mt-6"
            title="Совпадает с почтовым адресом"
            v-model="formData.isAddressesMatch"
          />
          <r-input
            class="mt-6"
            label="Почтовый"
            @blur="isChange('postalAddress')"
            :readonly="formData.isAddressesMatch"
            v-model="postalAddress"
          />
        </div>
      </div>
    </div>

    <div class="organization-form__btn-cont mt-6">
      <r-button
        @click="submit"
        :disabled="isChangeComp"
        :title="isEditPage ? 'Сохранить' : 'Добавить'"
        :is-loading="isLoading"
        width="wide"
      />
    </div>
    <delete ref="Delete" />
    <delete
      target="organization"
      ref="Delete"
    />
    <message ref="message" />
  </div>
</template>

<script>
import ContactItems from '@/components/ContactItems.vue';
import ImgUploader from '../components/ImgUploader.vue';
import RirMap from '@/components/RirMap.vue';
import AdminHeader from '@/components/AdminHeader.vue';
import Api from '../api/Api';
import Delete from '../components/Delete';
import ModalCrop from '@/components/ModalCrop';
import ModalViewImage from '@/components/ModalViewImage';
import SaveModal from '../components/SaveModal';
import OrganizationOnlyReadable from '../components/OrganizationOnlyReadable';

import SelectSearchInput from '../components/SelectSearchInput';
import DeleteModal from '../components/DeleteModal';
import Message from '../components/Message';
import { required } from 'vuelidate/lib/validators';
import _ from 'lodash'
export default {
  components: {
    ContactItems,
    ImgUploader,
    RirMap,
    AdminHeader,
    Delete,
    SelectSearchInput,
    OrganizationOnlyReadable,
    Message
  },
  data() {
    return {
      count: 0,
      manager: null,
      phone: null,
      email: null,
      postAddress: null,
      equalObject: {},
      loadedImg: {},
      isOrganizationHidden: false,
      formData: {
        isActive: true,
        orgType: 'matchingOrg',
        title: null,
        imgUrl: null,
        ceoName: null,
        ceoPosition: null,
        managers: [],
        contacts: [],
        emails: [],
        inn: null,
        ogrn: null,
        bankName: null,
        bankAccount: null,
        address: null,
        isAddressesMatch: false,
        coordinates: [],
        confidant: {
          id: -1
        }
      },
      isLoading: false,
      initialData: {},
      addressLoading: false,
      isSave: true,
      organization: {},
      users: []
    };
  },
  validations: {
    formData: {
      title: {
        required
      }
    }
  },
  computed: {
    isChangeComp(){
      return _.isEqual(this.formData, this.initialData)
    },

    isCanSave() {
      if (this.isEditPage && this.isEqual) return false;
      if (!this.formData.title) return false;
      return true;
    },
    isEqual() {
      if (!this.isEditPage) return false;
      return JSON.stringify(this.equalObject) === JSON.stringify(this.formData);
    },
    postalAddress: {
      get() {
        if (this.formData.isAddressesMatch) {
          return this.formData.address;
        }
        return this.postAddress;
      },

      set(val) {
        this.postAddress = val;
      }
    },

    isEditPage() {
      return !!this.$route.query.id && !this.isCopy;
    },
    isCopy() {
      return !!this.$route.query.copy;
    }
  },
  watch: {
    isOrganizationHidden(value) {
      this.formData.isActive = !value;
    }
  },

  created() {
    if (this.formData.isActive === null) {
      this.isOrganizationHidden = false;
      return;
    }

    this.isOrganizationHidden = !this.formData.isActive;
  },

  async mounted() {
    this.users = await this.$store.dispatch('getUsers');
    if (this.$store.state.organizations.length === 0) {
      await this.$store.dispatch('fetchOrganizations');
    }
  },

  activated() {
    this.resetData();
    (this.isEditPage || this.isCopy) && this.setData(this.$route.query.id);
    this.isShowMap = true;
  },

  deactivated() {
    this.isShowMap = false;
  },
  methods: {
    opganizationFnc() {
      this.isSave = false;
    },
    changeConfidant(user) {
      this.formData.confidant = user;
      this.isChange('confidant');
    },
    submit() {
      this.$v.$touch();
      if (this.$v.$invalid) {
        this.$nextTick(() => {
          document.querySelector('.field_error').scrollIntoView({
            behavior: 'smooth',
            block: 'center'
          });
        });
      } else if (this.isEditPage && !this.$attrs.isStatement) {
        this.OpenSaveModal();
      } else {
        this.save();
      }
    },
    async OpenSaveModal() {
      await this.$rir.modal.open(SaveModal, {
        saveFunc: this.save,
        title: this.formData.title,
        type: 'organization'
      });
    },
    isChange(field) {
      this.isSave = _.isEqual(this.formData, this.initialData)
    },
    async onDeleteOrg() {
      await this.$rir.modal.open(DeleteModal, {
        id: this.$route.query.id,
        actionPostfix: 'Organization'
      });
    },

    async searchAddress(address) {
      if (!address) return;
      const api = new Api();

      const data = {};
      const isFindAddress = Array.isArray(address);

      if (isFindAddress) {
        data.lng = address[0];
        data.lat = address[1];
      } else {
        data.address = this.formData.address;
      }

      const res = await api.getAddress(JSON.stringify(data));

      if (isFindAddress && res?.address) {
        if (res?.addressFull?.street && res?.addressFull?.house) {
          this.formData.address = `${res.addressFull.street} ${res.addressFull.house}`;
        } else {
          this.formData.address = res.address;
        }
      } else if (!isFindAddress && res?.lat && res?.lng) {
        this.formData.coordinates = [res.lng, res.lat];
      }
      this.isChange('address');
    },

    onViewImage() {
      this.$rir.modal.open(ModalViewImage, {
        file: this.formData.imgUrl
      });
    },
    deleteFile() {
      this.formData.imgUrl = null;
    },
    onUpload(file, target = null) {
      // 1. Revoke the object URL, to allow the garbage collector to destroy the uploaded before file
      if (this.loadedImg?.src) {
        URL.revokeObjectURL(this.loadedImg.src);
      }
      // 2. Create the blob link to the file to optimize performance:
      const blob = URL.createObjectURL(file);

      // 3. Update the image. The type will be derived from the extension and it can lead to an incorrect result:
      this.loadedImg = {
        src: blob,
        type: file.type
      };

      this.$rir.modal.open(ModalCrop, {
        image: this.loadedImg,
        valName: target === 'staff' ? 'contactImg' : 'imgUrl',
        portrait: target === 'staff'
      });
    },
    // onImgUpload(file) {
    //   const formData = new FormData();
    //   // Заголовки formDat'ы
    //   formData.append('files[]', file); // For PHP back
    //   formData.append('upload_url', 'import'); // for PHP back
    //   // formData.append('userId', undefined);
    //
    //   // formData.append('file', file); // for java back
    //
    //   // Загрузка файла
    //   // Don't set contentType manually → https://github.com/github/fetch/issues/505#issuecomment-293064470
    //   const API_URL = '/uploadfile/index.php';
    //   fetch(API_URL, { method: 'POST', body: formData })
    //     .then(res => res.json())
    //     .then(res => {
    //       // PHP back
    //       if (res?.files?.length && res?.files[0]?.url) {
    //         this.formData.imgUrl = res.files[0].url;
    //       }
    //     });
    // },

    addPhone() {
      this.formData.contacts.push(this.phone);
      this.phone = null;
      this.isChange('contacts');
    },
    addMail() {
      this.formData.emails.push(this.email);
      this.email = null;
      this.isChange('emails');
    },
    addManager() {
      this.formData.managers.push(this.manager);
      this.manager = null;
      this.isChange('managers');
    },
    async save() {
     // if (!this.isCanSave) return;

      const id = this.isEditPage ? this.$route.query.id : -1;
      const org = this.$store.getters.getOrganizationById(id);
      const data = {
        id,
        action: 'updateJson',
        item: {
          userId: this.formData?.confidant?.id,
          title: this.formData.title,
          address: this.formData.address,
          details: {
            ...this.formData,
            postalAddress: this.postalAddress,
            alignment: org ? (org.alignment === 'checked' ? 1 : 0) : 0
          }
        }
      };

      await this.$store.dispatch('setOrganization', data).then(res => {
        if (res?.error && res?.error !== '') {
          this.$refs.message.showMessage(res.error, 'error');
        } else {
          const r = this.$router.resolve({
            name: 'admin',
            query: {
              tab: 'organizations'
            }
          });
          window.location.assign(r.href);
        }
      });
    },

    onDragMarker(e) {
      this.coordinates = e.get('target').geometry._bounds[0];
      this.searchAddress(this.coordinates);
    },

    onMapClick(coords) {
      if (this.$store.getters.userIsRoot) {
        this.formData.coordinates = coords;
        this.searchAddress(coords);
      }
    },

    resetData() {
      this.manager = null;
      this.phone = null;
      this.email = null;
      this.postAddress = null;
      this.equalObject = {};

      const arrayFields = ['managers', 'contacts', 'emails', 'coordinates'];

      for (const prop in this.formData) {
        this.formData[prop] = arrayFields.includes(prop) ? [] : null;
      }
      this.formData.orgType = 'matchingOrg';
    },

    async setData() {
      if (this.$store.state.organizations.length === 0) {
        await this.$store.dispatch('fetchOrganizations');
        if (this.$store.state.organizations.length === 0) return;
      }

      const { id } = this.$route.query;
      const org = this.$store.getters.getOrganizationById(id);
      this.organization = org;
      this.postAddress = org.details?.postalAddress || null;

      this.formData = {
        ...this.formData,
        ...org.details
      };
      this.isOrganizationHidden = !this.formData.isActive;
      this.count++;

      this.equalObject = { ...this.formData };
      this.initialData = JSON.parse(JSON.stringify(this.formData));
    }
  }
};
</script>

<style lang="scss" scoped>
.organization-form {
  min-height: 100vh;
  padding-bottom: 130px;

  &__img-cont {
    border-radius: 8px;
  }

  &__btn-cont {
    position: fixed;
    right: 0;
    bottom: 0;
    width: calc(100% - 25.3%);
    background-color: #f6f9fe;
    z-index: 20;
    padding: 32px 40px 40px 32px;
    box-shadow: 0px 9px 28px rgba(17, 48, 121, 0.18);
  }
}

.delete-btn {
  opacity: 0.72;

  &:hover {
    cursor: pointer;
    opacity: 1;
  }
}

::v-deep .ya-map-point {
  position: absolute;
  left: -4px;
  top: -4px;
  display: block;
  width: 8px;
  height: 8px;
  background-color: #fff;
  border: 2px solid var(--rir-rocky);
  border-radius: 50%;
}

::v-deep .ya-map-point--edge {
  cursor: pointer !important;
  position: absolute;
  left: -3px;
  top: -3px;
  display: block;
  width: 6px;
  height: 6px;
  background-color: var(--rir-rocky);
  border-radius: 50%;
  opacity: 0.8;
}

::v-deep textarea:focus {
  background: transparent !important;
}
</style>
