import ApiService from './ApiService';

// 1. Получить список
// POST: /ajax.php?action=getExcavators
// JSON: {casse:Int, currentMainMenuNumber:18}
// где casse см excavators.js/loadExcavators()
// возвращает список all[], все поля из БД

export default class Api extends ApiService {
  async getExcavations(payload) {
    // &wHidden=1  - показть скрытые (удаленные)
    const { data } = await this._axios.post('/ajax.php?action=getExcavators&v=2', {
      currentMainMenuNumber: 18,
      ...payload
    });
    return data;
  }

  // 2. Записать строку в БД
  // POST ajax.php?action=operateExcavator
  // JSON: {id:Int,action:action,casse:Int,currentMainMenuNumber:18}
  // где
  // id: айди либо -1 для нового
  // action: update|delete|consume|save, для save json.coordinates[lat,lng]

  async setExcavation(payload) {
    const { data } = await this._axios.post('/ajax.php?action=sendToExcavators', {
      action: 'update',
      currentMainMenuNumber: 18,
      ...payload
    });
    return data;
  }

  async deleteExcavation(id) {
    const { data } = await this._axios.post('/ajax.php?action=operateExcavator', {
      action: 'delete',
      currentMainMenuNumber: 18,
      id
    });
    return data;
  }

  async restoreExcavation(id) {
    const { data } = await this._axios.post('/ajax.php?action=sendToExcavators', {
      action: 'update',
      hidden: 0,
      id
    });
    return data;
  }

  async setOrganization(payLoad) {
    const { data } = await this._axios.post('/ajax.php?action=operateOrganization', {
      ...payLoad
      //  currentMainMenuNumber: 18
    });
    return data;
  }

  async deleteOrganization(id) {
    const { data } = await this._axios.post('/ajax.php?action=operateOrganization', {
      action: 'delete',
      id
    });
    return data;
  }

  async fetchOrganizations(payload) {
    const { data } = await this._axios.post('/ajax.php?action=getOrganizations', {
      currentMainMenuNumber: 18,
      withDetails: 1,
      ...payload
    });
    return data;
  }

  async getCardFile(id) {
    const { data } = await this._axios.post(
      `ajax.php?action=downloadAndPrint&casse=docx&json={"currentMainMenuNumber":18,"selectedId":${id}}`
    );
    return data;
  }

  async getTemplateFile(payload) {
    const { data } = await this._axios.post(
      `ajax.php?action=downloadAndPrint&casse=docx&json={"currentMainMenuNumber":18,${payload}}`
    );
    return data;
  }

  async getTemplateFileTwo() {
    const { data } = await this._axios.post(
      'ajax.php?action=downloadAndPrint&casse=docx&json={"currentMainMenuNumber":18,"taskId":-1}'
    );
    return data;
  }

  async getFile(ids, fileExtension) {
    const { data } = await this._axios.post(
      `ajax.php?action=downloadAndPrint&casse=${fileExtension}&json={"currentMainMenuNumber":18, "extraOptions": {"all":1, "ids":[${ids}]}}`
    );
    return data;
  }

  async getAddress(payload) {
    const { data } = await this._axios.post('/mob_ajax.php?action=geosearch', payload);
    return data;
  }

  async getUserInfo() {
    const { data } = await this._axios.get('nginxApi.php?get=user');
    // const {data} = await this._axios.get(`/auth/rest/user`);
    return data;
  }

  async getUserAccesses() {
    const { data } = await this._axios.get('/auth/rest/access/EXCAVATION');
    return data;
  }

  async logOut() {
    const { data } = await this._axios.get('auth/rest/logout');
    return data;
  }

  async getParticipantAgreement() {
    const { data } = await this._axios.post('ajax.php?action=getExcavators&v=2');
    return data;
  }

  async getStatements(payload) {
    const { data } = await this._axios.post('/ajax.php?action=getExcavators&v=2', payload);
    return data;
  }

  async saveStatement(payload) {
    const { data } = await this._axios.post('ajax.php?action=operateExcavatorProject', payload);
    return data;
  }

  async deleteStatement(id) {
    const { data } = await this._axios.post('ajax.php?action=operateExcavatorProject', {
      action: 'updateJson',
      id,
      item: {
        casse: -2
      }
    });
    return data;
  }

  //
  async getUsers() {
    const { data } = await this._axios.get('ajax.php?action=getExcavatorMasters');
    // const {data} = await this._axios.get(`/auth/rest/user/list`);
    return data;
  }

  async checkExcavatorIsAlignment() {
    const { data } = await this._axios.post('/ajax.php?action=checkExcavatorIsAlignment');
    return data;
  }

  async getExcavatorStatuses() {
    const res = await this._axios.post('ajax.php?action=getExcavatorStatuses');
    return res.data;
  }

  async operateExcavatorProjectAlignment(payload) {
    const res = await this._axios.post('/ajax.php?action=operateExcavatorProjectAlignment', payload);
    return res;
  }

  async operateExcavatorRequest(payload) {
    const { data } = await this._axios.post('/ajax.php?action=operateExcavatorRequest', payload);
    return data;
  }

  async getProjectDocs(projectId) {
    const { data } = await this._axios.post('/ajax.php?action=getExcavators&v=2', {
      casse: 8,
      projectId
    });
    return data;
  }

  async getOrderReports(orderId) {
    const { data } = await this._axios.post('ajax.php?action=getExcavators&v=2', {
      casse: 13,
      currentMainMenuNumber: 18,
      id: orderId
    });
    return data;
  }

  async setOrderReports(payload) {
    // payload = {
    // orderId:Int, //айди родителя-ордера
    // details: {} //любые поля
    // }
    const { data } = await this._axios.post('ajax.php?action=operateExcavator', {
      action: 'update',
      currentMainMenuNumber: 18,
      casse: 13,
      id: -1,
      ...payload
    });
    return data;
  }

  async printExcavators(payload) {
    /* Типы документов на печать:
excavatorOrderDOCX Скачать выделенный ордер в DOCX
excavatorOrderAllDOCX Скачать все ордера (DOCX)
excavatorOrderAllDOCX Скачать все ордера за период (DOCX)
excavatorOrderAllCSV Скачать все ордера (Excel)
excavatorOrderAllCSV Скачать все ордера за период (Excel)
excavatorOrderAllCSV,{version:2} Скачать все ордера 2 (Excel)
excavatorOrderAllCSV,{version:2} Скачать все ордера за период 2 (Excel)
excavatorOrderAllPDF Скачать все ордера (PDF)
excavatorOrderAllPDF Скачать все ордера за период (PDF)
excavatorProjectAlignmentsDOCX лист согласований
excavatorRequestDOCX заявка на выдачу ордера */
    const { data } = await this._axios.post('/ajax.php?action=printExcavators', payload);
    return data;
  }

  async getCityId() {
    const { data } = await this._axios.get('/nginxApi.php?get=config');
    return data;
  }
}
