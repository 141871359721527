<template>
  <div class="flex">
    <div
      v-show="upload"
      class="upload"
    >
      <div>
        <div @click="upload = !upload">
          <r-icon
            fill="rocky"
            class="mr-2 margin-right"
            icon="close"
            size="32"
          />
        </div>
        <div class="parent align-items-center">
          <div class="container-upload">
            <h1 class="ricotta mb-2">
              Уверены, что хотите удалить?
            </h1>
            <div class="mb-6 font">
              {{ target === 'work' ? 'Работа' : 'Организация' }} будет удалена. Данное действие необратимо.
            </div>
            <div
              class="flex mt-2 delete__button"
              @click="upload = !upload"
            >
              <r-button
                class="flex-1"
                :disabled="true"
                title="Нет, оставить"
                width="wide"
              />
            </div>
            <div
              class="flex mt-2 delete__button delete__float"
              @click="onDelete"
            >
              <r-button
                class="flex-1"
                title=" Да, удалить"
                width="wide"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Api from '../api/Api';

export default {
  name: 'Delete',
  props: {
    target: {
      type: String
    }
  },
  data() {
    return {
      upload: false
    };
  },
  methods: {
    showDelete() {
      this.upload = true;
    },
    async onDelete() {
      const api = new Api();

      const method = this.target === 'work' ? 'deleteExcavation' : 'deleteOrganization';
      const fetch = this.target === 'work' ? 'fetchExcavations' : 'fetchOrganizations';

      const res = await api[method](+this.$route.query.id);

      if (res?.error === '') {
        const r = this.$router.resolve({
          name: 'admin'
        });
        await this.$store.dispatch(fetch);
        window.location.assign(r.href);
        // this.$router.push({ name: 'admin' });
        this.upload = false;
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.delete {
  &__button {
    width: 48%;
    display: inline-block;
    cursor: pointer
  }

  &__float {
    float: right;
  }

}

.align-items-center {
  align-items: center;
  float: left;
  margin-top: 5px;
}

.upload {
  background-color: white;
  z-index: 999;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
}

.parent {
  display: block;
  position: absolute;
  width: 40%;
  height: 400px;
  top: 55%;
  margin-left: 33%;
  margin-top: -150px;
}

.container-upload {
  width: 87%;
  height: 300px;
}

.margin-right {
  float: right;
  margin-top: 17px;
  margin-right: 17px;
  z-index: 1000;
  cursor: pointer;
}

.font {
  font-family: 'Golos UI';
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 20px;
  color: #04153E;
  opacity: 0.72;
}
</style>
