var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('router-link',{attrs:{"to":{
      name: _vm.activeTabId,
      query: { id: _vm.id },
    }}},[_c('div',{staticClass:"card"},[_c('div',{staticClass:"card__info"},[_c('div',{staticClass:"card__header mozzarella"},[(_vm.incident)?_c('r-icon',{staticClass:"mr-2",attrs:{"icon":"emergency-stop","size":"16","fill":"fargo"}}):_vm._e(),_c('div',[_c('span',{staticClass:"opacity-48"},[_vm._v(_vm._s(_vm.date.from || 'Не указана')+"-")]),_c('span',{class:{
                'color-fargo': _vm.isOverdue && !!!_vm.dateContinuedTo,
                'color-lebowski': _vm.isNearEnd && !!!_vm.dateContinuedTo && !_vm.isOverdue,
                'opacity-48': !_vm.isNearEnd && !_vm.isOverdue || !!_vm.dateContinuedTo,
              }},[_vm._v(_vm._s(_vm.date.to || 'Не указана'))])]),(_vm.dateContinuedTo)?_c('svg',{staticClass:"opacity-48 mr-2 ml-2",attrs:{"width":"4","height":"8","viewBox":"0 0 4 8","fill":"none","xmlns":"http://www.w3.org/2000/svg"}},[_c('g',{attrs:{"opacity":"0.48"}},[_c('path',{attrs:{"d":"M1.82999 3.99847L0.172416 1.54823C-0.135128 1.09361 -0.014819 0.476491 0.441133 0.169849C0.897084 -0.136791 1.51602 -0.0168361 1.82356 0.437779L3.82965 3.40321C3.95824 3.59329 4.01203 3.81179 3.99776 4.02412C4.00141 4.22108 3.94646 4.42108 3.82758 4.59679L1.8215 7.56222C1.51395 8.01684 0.895018 8.13679 0.439066 7.83015C-0.0168856 7.52351 -0.137194 6.90639 0.170349 6.45177L1.82999 3.99847Z","fill":"#04153E"}})])]):_vm._e(),(_vm.dateContinuedTo)?_c('div',[_c('span',{staticClass:"opacity-48"},[_vm._v("Продление до ")]),_c('span',{class:{
                'color-fargo': _vm.isOverdue,
                'color-lebowski': _vm.isNearEnd && !_vm.isOverdue,
                'opacity-48': !_vm.isNearEnd && !_vm.isOverdue,
              }},[_vm._v(_vm._s(_vm.formatDate(_vm.dateContinuedTo) || 'Не указана'))])]):_vm._e(),(_vm.completed_info.isComplete)?_c('svg',{staticClass:"opacity-48 mr-2 ml-2",attrs:{"width":"4","height":"8","viewBox":"0 0 4 8","fill":"none","xmlns":"http://www.w3.org/2000/svg"}},[_c('g',{attrs:{"opacity":"0.48"}},[_c('path',{attrs:{"d":"M1.82999 3.99847L0.172416 1.54823C-0.135128 1.09361 -0.014819 0.476491 0.441133 0.169849C0.897084 -0.136791 1.51602 -0.0168361 1.82356 0.437779L3.82965 3.40321C3.95824 3.59329 4.01203 3.81179 3.99776 4.02412C4.00141 4.22108 3.94646 4.42108 3.82758 4.59679L1.8215 7.56222C1.51395 8.01684 0.895018 8.13679 0.439066 7.83015C-0.0168856 7.52351 -0.137194 6.90639 0.170349 6.45177L1.82999 3.99847Z","fill":"#04153E"}})])]):_vm._e(),(_vm.completed_info.isComplete)?_c('div',{staticClass:"opacity-48"},[_vm._v(" Завершено ")]):_vm._e()],1),_c('p',{staticClass:"sulguni mt-2 truncate"},[_vm._v(" "+_vm._s(_vm.address || 'Не указан')+" ")]),_c('p',{staticClass:"mozzarella mt-1 opacity-72 truncate"},[_vm._v(" "+_vm._s(_vm.description || '-')+" ")]),_c('div',{staticClass:"flex mt-2"},[_c('r-icon',{staticClass:"card__icon",attrs:{"size":"16","fill":"rocky","icon":"mayor"}}),_c('p',{staticClass:"briscola truncate"},[_vm._v(" "+_vm._s(_vm.organization || '-')+" ")])],1)]),(this.$store.getters.userIsRoot)?_c('div',{staticClass:"flex align-items-center"},[_c('additional-menu',[_c('ul',{staticClass:"card__menu"},[_c('li',{staticClass:"sulguni flex pointer opacity-72",on:{"click":function($event){$event.stopPropagation();$event.preventDefault();return _vm.onDelete.apply(null, arguments)}}},[_c('r-icon',{staticClass:"mr-3",attrs:{"size":"16","icon":"delete","fill":"fargo"}}),_vm._v(" Удалить ")],1)])])],1):_vm._e()])])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }