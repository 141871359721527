import Api from '@/api/Api';
const api = new Api();
export default {
  state: () => ({
  }),
  mutations: {
  },
  actions:{
    async operateExcavatorProjectAlignment(ctx, payload){
      const res = await api.operateExcavatorProjectAlignment(payload);
      return res
    }
  },
  getters: {

  }
}
