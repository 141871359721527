<template>
  <div class="modal_container">
    <r-icon
      icon="warning"
      fill="fargo"
      size="56"
    />
    <h1 class="ricotta mb-4 mt-7">
      Удалить
      <template v-if="actionPostfix === 'Work' || actionPostfix === 'Excavation'">
        работу
      </template>
      <template v-if="actionPostfix === 'Organization'">
        организацию
      </template>
      <template v-if="actionPostfix === 'Statement'">
        заявление
      </template>?
    </h1>
    <div class="mb-8 font">
      Все данные
      <template v-if="actionPostfix === 'Work' || actionPostfix === 'Excavation'">
        о работе
      </template>
      <template v-if="actionPostfix === 'Organization'">
        об организации
      </template>
      <template v-if="actionPostfix === 'Statement'">
        о заявлении
      </template>
      будут удалены.
    </div>
    <div class="mt-2 buttons">
      <r-button
        class="flex-1"
        color="secondary"
        @click.native="$rir.modal.close()"
        title="Не удалять"
        width="wide"
      />
      <r-button
        class="flex-1"
        @click.native.prevent.stop="Delete"
        title="Да, удалить"
        width="wide"
      />
    </div>
  </div>
</template>

<script>
import Api from '../api/Api';

export default {
  name: 'DeleteModal',
  props: {
    id: [Number, String],
    actionPostfix: String
  },
  data() {
    return {
      upload: false
    };
  },
  methods: {
    async Delete() {
      const api = new Api();
      await api[`delete${this.$props.actionPostfix}`](this.$props.id).then(() => {
        const r = this.$router.resolve({
          name: 'index', // надо возвращать на тот таб, из которого удалили
          query: {
            tab: this.whereBack()
          }
        });
        window.location.assign(r.href);
      });
      /* await this.$store.dispatch(`delete${this.$props.actionPostfix}`, this.$props.id).then(() => {
         let r = this.$router.resolve({
           name: 'index',//надо возвращать на тот таб, из которого удалили
           query: {
             tab: this.whereBack()
           }
         });
         window.location.assign(r.href)
       }); */
    },
    whereBack() {
      let string = '';
      if (this.$props.actionPostfix === 'Work' || this.$props.actionPostfix === 'Excavation') {
        string = 'work';
      }
      if (this.$props.actionPostfix === 'Organization') {
        string = 'organizations';
      }
      if (this.$props.actionPostfix === 'Statement') {
        string = 'statements';
      }
      return string;
    }
  }
};
</script>

<style lang="scss" scoped>
.modal_container{
  max-width: 800px;
  width: 100%;
  margin: 0 auto;
  padding: 10px 0;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  position: relative;
}
.buttons{
  display: grid;
  grid-template-columns: 380px 380px;
  grid-gap: 32px;
  @media(max-width: 792px ){
    grid-template-columns: 1fr;
  }

}
</style>
