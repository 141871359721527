import Api from '@/api/Api';
const api = new Api();
export default {
  state: () => ({
    statements:[],
    isLoading: true
  }),
  mutations: {
    setStatements(state, data) {
      state.statements = data;
    },
    setLoading(state, data) {
      state.isLoading = data;
    },
  },
  actions: {
    async getStatements(ctx, payload = {casse:2}) {
      ctx.commit('setLoading', true)
      const res = await api.getStatements(payload);
      ctx.commit('setStatements', res.all);
      // console.log('userInfo', res, ctx.state.user);
      setTimeout(()=>{
        ctx.commit('setLoading', false)
      },500)
    },
    async getStatementById(ctx, id) {
      ctx.commit('setLoading', true)
      const res = await api.getStatements({
        casse:2,
        id: id
      });
      setTimeout(()=>{
        ctx.commit('setLoading', false)
      },500)
      return res
    },
    async getApprovalById(ctx, id) {
      ctx.commit('setLoading', true)
      const res = await api.getStatements({
        casse:4,
        id: id
      });
      setTimeout(()=>{
        ctx.commit('setLoading', false)
      },500)
      return res
    },
    async saveStatement(ctx, data) {
      const res = await api.saveStatement(data);
      ctx.commit('setStatements', res);
      // console.log('userInfo', res, ctx.state.user);
    },
    async deleteStatement(ctx, id) {
      const res = await api.deleteStatement(id);
      // console.log('deleteOrg', res);
      return res;
    },
  },
  getters: {
  }
}
