<template>
  <div class="work-form flex flex-direction-column">
    <loader v-if="isLoading && isEditPage" />
    <div v-else>
      <admin-header
        :to="{ name: 'admin', query: { tab: 'statements' } }"
        :title="!isEditPage ? 'Новое заявление' : details.projectName || ''"
      >
        <sup
          class="text-c13 color-fargo"
          v-if="!isCanSave && isEditPage"
        >Не сохранено</sup>
      </admin-header>
      <p
        class="opacity-48 mt-2 feta"
        v-if="!!statement && isEditPage"
      >
        № {{ statement.number }} от
        {{ statement.createdF }}
      </p>

      <div
        class="flex align-items-center mt-4"
        v-if="!isEmpty(statement)"
      >
        <r-badge
          :style="{ backgroundColor: $store.getters.getProjectStatusColor[statement.status] }"
          :title="$store.getters.getProjectStatus[statement.status] + ((statement.versions && statement.versions.length > 1) ? ', вер.' + statement.versions.length : '')"
        />
        <p
          class="feta opacity-72 ml-3"
          v-if="statement.status === '0'"
        >
          {{ statementInfo.excavatorAlignmentDeadline }}
          {{ formatDay(statementInfo.excavatorAlignmentDeadline) }} на рассмотрение
          заявления
        </p>
        <p
          class="feta opacity-72 ml-3"
          v-if="statement.status === '1'"
        >
          Осталось {{ statementInfo.excavatorAlignmentDeadline - statement.delayCreated }}
          {{ formatDay(Number(statementInfo.excavatorAlignmentDeadline - statement.delayCreated)) }}
        </p>
      </div>

      <div class="mt-5 flex align-items-center">
        <drop-down-button
          :items="fileDownloadMenu"
          @select="onFileDownload"
          v-if="statement.status === '3' || statement.status === '2'"
        />

        <router-link
          v-if="!!statement && statement.status === '3' && $store.getters.userIsRoot && !!!statement.orderId"
          :to="{
            name: 'statementsWork',
            query: { id: statement.id },
          }"
        >
          <r-button-action
            class="color-rocky sulguni ml-6"
            title="Добавить работу из заявления"
            icon="add"
          />
        </router-link>
        <router-link
          v-if="!!statement && statement.status === '3' && $store.getters.userIsRoot && !!statement.orderId"
          :to="{
            name: 'work',
            query: { id: statement.orderId },
          }"
        >
          <r-button-action
            class="color-rocky sulguni ml-6"
            title="Посмотреть работу из заявления"
          />
        </router-link>
      </div>

      <r-tabs
        class="mt-7"
        :items="editTabs"
        v-model="activeTabId"
        v-if="!isEmpty(statement) && statement.log.length > 0"
      />
      <div
        v-show="activeTabId.id === 'information'"
        class="edit_tab mt-8 flex flex-1"
      >
        <div class="flex flex-1 flex-direction-column">
          <statement-only-readable
            :statement="statement"
            v-if="!isEmpty(statement) && (statement.status === '1' || statement.status === '3')"
          />
          <div
            v-else
            class="inputable"
          >
            <r-input
              @blur="isChange('projectName')"
              v-model.trim="details.projectName"
              label="Название проекта"
              :class="{ field_error: $v.details.projectName.$error }"
              :error="$v.details.projectName.$error && !$v.details.projectName.required"
              error-message="Поле обязательно для заполнения"
            />

            <!--<r-checkbox
              v-model="details.coordinationETSP"
              class="mt-6"
              title="Согласование с Факсимиле"
              @input="isChange('coordinationETSP')"
            />-->
            <h2 class="bryndza mt-6 mb-4">
              Проектная документация
            </h2>
            <upload-file
              :count-file="5"
              upload-url="/uploadfile/index.php"
              :value="details.documents"
              @input="loadDocuments"
              :count-line="2"
              :params-body="{ upload_url: 'excavators', privateUserId: $store.state.userId || -1 }"
              sub-title="или перетяните сюда файл объёмом не более 50 МБайт"
              :accept-file="[
                'image/jpeg',
                'image/png',
                'image/gif',
                'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
                'application/pdf',
                'application/msword',
                'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
                'text/csv',
              ]"
            />

            <div class="field_wrapper mt-6">
              <r-textarea
                @input="isChange('description')"
                :rows="6"
                label="Краткое описание проводимых работ"
                v-model.trim="details.description"
                :class="{ field_error: $v.details.description.$error }"
              />
              <div
                class="error mozzarella color-fargo bottom"
                v-if="$v.details.description.$error && !$v.details.description.required"
              >
                Поле обязательно для заполнения
              </div>
              <div
                class="error mozzarella color-fargo bottom"
                v-if="$v.details.description.$error && !$v.details.description.minLength"
              >
                Описание должно содержать минимум {{ $v.details.description.$params.minLength.min }}
                символов
              </div>
            </div>

            <h2 class="bryndza mt-6 mb-4">
              Фото до начала работ
            </h2>
            <upload-file
              :count-file="5"
              upload-url="/uploadfile/index.php"
              :value="details.images"
              @input="loadImages"
              :params-body="{ upload_url: 'excavators' }"
              :count-line="2"
              sub-title="или перетяните сюда до 5 фотографий, общим объёмом не более 50 МБайт"
              :accept-file="[
                'image/jpeg',
                'image/png',
                'image/gif',
              ]"
            />
            <h2 class="bryndza mt-8">
              Заявитель
            </h2>
            <p
              v-if="isContractor"
              class="feta opacity-72 mt-1"
            >
              {{ details.applicantStatus === 0 ? 'Физическое лицо' : 'Юридическое лицо' }}
            </p>
            <r-select
              v-else
              class="mt-3"
              :items="[
                {
                  id: 0,
                  title: 'Физическое лицо',
                },
                {
                  id: 1,
                  title: 'Юридическое лицо',
                },
              ]"
              @change="onChangeApplicant"
              label="Заявитель"
              :class="{ field_error: $v.details.applicantStatus.$error }"
              v-model="$v.details.applicantStatus.$model"
              :error="$v.details.applicantStatus.$error && !$v.details.applicantStatus.required"
              error-message="Поле обязательно для заполнения"
            />

            <div v-if="isContractor">
              <div
                class="mt-6"
                v-if="details.applicantStatus === 0"
              >
                <div class="mb-4">
                  <p class="mozzarella opacity-48">
                    Фамилия
                  </p>
                  <p class="feta opacity-72 mt-1">
                    {{ details.applicantSurname || '-' }}
                  </p>
                </div>

                <div class="flex mb-4">
                  <div class="mr-8">
                    <p class="mozzarella opacity-48">
                      Имя
                    </p>
                    <p class="feta opacity-72 mt-1">
                      {{ details.applicantName || '-' }}
                    </p>
                  </div>
                  <div>
                    <p class="mozzarella opacity-48">
                      Отчество
                    </p>
                    <p class="feta opacity-72 mt-1">
                      {{ details.applicantPatronymic || '-' }}
                    </p>
                  </div>
                </div>
                <div class="mb-4">
                  <p class="mozzarella opacity-48">
                    Адрес регистрации
                  </p>
                  <p class="feta opacity-72 mt-1">
                    {{ details.applicantAddress || '-' }}
                  </p>
                </div>
                <div class="flex mb-4">
                  <div class="mr-8">
                    <p class="mozzarella opacity-48">
                      Телефон
                    </p>
                    <p class="feta opacity-72 mt-1">
                      {{ details.firmPhone || '-' }}
                    </p>
                  </div>
                  <div>
                    <p class="mozzarella opacity-48">
                      Имейл
                    </p>
                    <p class="feta opacity-72 mt-1">
                      {{ details.applicantEmail || '-' }}
                    </p>
                  </div>
                </div>
              </div>
              <div
                class="mt-6"
                v-if="details.applicantStatus === 1"
              >
                <div class="mb-4">
                  <p class="mozzarella opacity-48">
                    Наименование
                  </p>
                  <p class="feta opacity-72 mt-1">
                    {{ details.firmName || '-' }}
                  </p>
                </div>
                <div class="mb-4">
                  <p class="mozzarella opacity-48">
                    ИНН
                  </p>
                  <p class="feta opacity-72 mt-1">
                    {{ details.inn || '-' }}
                  </p>
                </div>
                <div class="flex mb-4">
                  <div class="mr-8">
                    <p class="mozzarella opacity-48">
                      Телефон
                    </p>
                    <p class="feta opacity-72 mt-1">
                      {{ details.firmPhone || '-' }}
                    </p>
                  </div>
                  <div>
                    <p class="mozzarella opacity-48">
                      Имейл
                    </p>
                    <p class="feta opacity-72 mt-1">
                      {{ details.applicantEmail || '-' }}
                    </p>
                  </div>
                </div>
                <div class="mb-4">
                  <p class="mozzarella opacity-48">
                    Доверенное лицо
                  </p>
                  <p class="feta opacity-72 mt-1">
                    {{ details.firmDir || '-' }}
                  </p>
                </div>
                <div class="mb-4">
                  <p class="mozzarella opacity-48">
                    Почтовый адрес
                  </p>
                  <p class="feta opacity-72 mt-1">
                    {{ details.postAddress || '-' }}
                  </p>
                </div>
              </div>
            </div>
            <div v-else>
              <div
                class="mt-6"
                v-if="details.applicantStatus === 0"
              >
                <r-input
                  class="flex-1"
                  @blur="isChange('applicantSurname')"
                  label="Фамилия"
                  :class="{ field_error: $v.details.applicantSurname.$error }"
                  v-model="$v.details.applicantSurname.$model"
                  :error="$v.details.applicantSurname.$error && !$v.details.applicantSurname.required"
                  error-message="Поле обязательно для заполнения"
                />
                <div class="flex align-items-center mt-6">
                  <r-input
                    class="flex-1 mr-6"
                    @blur="isChange('applicantName')"
                    label="Имя"
                    :class="{ field_error: $v.details.applicantName.$error }"
                    v-model="$v.details.applicantName.$model"
                    :error="$v.details.applicantSurname.$error && !$v.details.applicantSurname.required"
                    error-message="Поле обязательно для заполнения"
                  />

                  <r-input
                    class="flex-1"
                    @blur="isChange('applicantPatronymic')"
                    label="Отчество"
                    :class="{ field_error: $v.details.applicantPatronymic.$error }"
                    v-model="$v.details.applicantPatronymic.$model"
                    :error="$v.details.applicantPatronymic.$error && !$v.details.applicantPatronymic.required"
                    error-message="Поле обязательно для заполнения"
                  />
                </div>
                <r-input
                  class="mt-6"
                  @blur="isChange('applicantAddress')"
                  :class="{ field_error: $v.details.applicantAddress.$error }"
                  label="Адрес регистрации"
                  v-model="$v.details.applicantAddress.$model"
                  :error="$v.details.applicantAddress.$error && !$v.details.applicantAddress.required"
                  error-message="Поле обязательно для заполнения"
                />

                <div class="flex align-items-center mt-6">
                  <r-input
                    class="mr-6 flex-1"
                    @blur="isChange('firmPhone')"
                    label="Телефон"
                    :class="{ field_error: $v.details.firmPhone.$error }"
                    v-model="$v.details.firmPhone.$model"
                    :error="$v.details.firmPhone.$error && !$v.details.firmPhone.required"
                    error-message="Поле обязательно для заполнения"
                  />
                  <r-input
                    class="flex-1"
                    @blur="isChange('applicantEmail')"
                    label="Имейл"
                    :class="{ field_error: $v.details.applicantEmail.$error }"
                    v-model="$v.details.applicantEmail.$model"
                    :error="$v.details.applicantEmail.$error && !$v.details.applicantEmail.required"
                    error-message="Поле обязательно для заполнения"
                  />
                </div>
              </div>
              <div
                class="mt-6"
                v-if="details.applicantStatus === 1"
              >
                <p
                  class="sulguni color-rocky pointer"
                  @click="showOrganization = true"
                >
                  {{
                    !!details.firmId ? 'Выбрать другую организацию' : 'Выбрать организацию из списка'
                  }}
                </p>
                <div
                  class="error mozzarella color-fargo bottom"
                  v-if="$v.details.firmId.$error && !$v.details.firmId.required && !showOrganization"
                >
                  Поле обязательно для заполнения
                </div>
                <r-select
                  class="mt-6"
                  v-if="showOrganization"
                  :items="orgListOnlyMine"
                  label="Наименование организации"
                  v-model="$v.details.firmId.$model"
                  :class="{ field_error: $v.details.firmId.$error }"
                  return-object
                  @input="onChangeOrganization"
                  :error="$v.details.firmId.$error && !$v.details.firmId.required"
                  error-message="Поле обязательно для заполнения"
                />
                <div
                  class="mt-7"
                  v-if="!!details.firmId"
                >
                  <div>
                    <p class="mozzarella opacity-48">
                      Наименование
                    </p>
                    <p class="feta opacity-72 mt-1">
                      {{ details.firmName || '-' }}
                    </p>
                  </div>
                  <div class="mt-4">
                    <p class="mozzarella opacity-48">
                      ИНН
                    </p>
                    <p class="feta opacity-72 mt-1">
                      {{ details.inn || '-' }}
                    </p>
                  </div>
                  <div class="flex mt-4">
                    <div class="mr-8">
                      <p class="mozzarella opacity-48">
                        Телефон
                      </p>
                      <p class="feta opacity-72 mt-1">
                        {{ details.firmPhone || '-' }}
                      </p>
                    </div>
                    <div>
                      <p class="mozzarella opacity-48">
                        Имейл
                      </p>
                      <p class="feta opacity-72 mt-1">
                        {{ details.applicantEmail || '-' }}
                      </p>
                    </div>
                  </div>
                  <div class="mt-4">
                    <p class="mozzarella opacity-48">
                      Доверенное лицо
                    </p>
                    <p class="feta opacity-72 mt-1">
                      {{ details.firmDir || '-' }}
                    </p>
                  </div>
                  <div class="mt-4">
                    <p class="mozzarella opacity-48">
                      Почтовый адрес
                    </p>
                    <p class="feta opacity-72 mt-1">
                      {{ details.postAddress || '-' }}
                    </p>
                  </div>
                </div>
              </div>
            </div>

            <div
              class="mt-6"
              v-if="details.applicantStatus === 0 || details.applicantStatus === 1"
            >
              <r-checkbox
                v-model="details.isContractor"
                class="mt-6 "
                title="Работы ведутся уполномоченным подрядчиком"
                :disabled="isContractor"
                @input="isChange('isContractor')"
              />
              <div v-if="details.applicantStatus === 0">
                <r-input
                  @blur="isChange('contractorTitle')"
                  v-model="details.contractorTitle"
                  label="Наименование подрядчика"
                  class="mt-6"
                  :readonly="!details.isContractor"
                />
                <div class="flex mt-6">
                  <r-input
                    @blur="isChange('contractorNumber')"
                    v-model="details.contractorNumber"
                    class="mr-6 flex-1"
                    label="№ договора подряда"
                    :readonly="!details.isContractor"
                  />
                  <r-date-picker
                    isClearModel
                    click-close
                    class="flex-1"
                    label="Дата договора"
                    v-model="details.contractorDate"
                    :readonly="!details.isContractor"
                    @input="isChange('contractorDate')"
                  />
                </div>
                <r-input
                  @blur="isChange('contractorSurname')"
                  v-model="details.contractorSurname"
                  class="mt-6"
                  label="Фамилия доверенного лица"
                  :readonly="!details.isContractor"
                />
                <div class="flex mt-6">
                  <r-input
                    @blur="isChange('contractorName')"
                    v-model="details.contractorName"
                    class="mr-6 flex-1"
                    label="Имя"
                    :readonly="!details.isContractor"
                  />
                  <r-input
                    @blur="isChange('contractorPatronymic')"
                    v-model="details.contractorPatronymic"
                    label="Отчество"
                    class="flex-1"
                    :readonly="!details.isContractor"
                  />
                </div>
                <r-input
                  @blur="isChange('contractorPosition')"
                  v-model="details.contractorPosition"
                  class="mt-6"
                  label="Должность"
                  :readonly="!details.isContractor"
                />
                <div class="flex mt-6">
                  <r-input
                    @blur="isChange('contractorPhone')"
                    v-model="details.contractorPhone"
                    class="mr-6 flex-1"
                    label="Телефон"
                    :readonly="!details.isContractor"
                  />
                  <r-input
                    @blur="isChange('contractorEmail')"
                    v-model="details.contractorEmail"
                    label="Имейл"
                    class="flex-1"
                    :readonly="!details.isContractor"
                  />
                </div>
              </div>
              <div
                class="mt-6"
                v-if="details.applicantStatus === 1"
              >
                <p
                  class="sulguni color-rocky pointer"
                  v-if="!isContractor"
                  :class="{ disabled: !details.isContractor }"
                  @click="()=>{ details.isContractor ? contractorShowOrganization = true : contractorShowOrganization = false }"
                >
                  {{
                    !!details.contractorFirmId ? 'Выбрать другую организацию-подрядчика ' : 'Выбрать организацию-подрядчика из списка'
                  }}
                </p>
                <div
                  class="error mozzarella color-fargo bottom"
                  v-if="$v.details.contractorFirmId.$error && !$v.details.contractorFirmId.required && !contractorShowOrganization"
                >
                  Поле обязательно для заполнения
                </div>
                <r-select
                  class="mt-6"
                  v-if="contractorShowOrganization"
                  :items="orgList"
                  placeholder="Наименование организации"
                  v-model="$v.details.contractorFirmId.$model"
                  :class="{ field_error: $v.details.contractorFirmId.$error }"
                  @change="onChangeContractorOrganization"
                  :error="$v.details.contractorFirmId.$error && !$v.details.contractorFirmId.required"
                  error-message="Поле обязательно для заполнения"
                />
                <div
                  class="mt-7"
                  v-if="!!details.contractorFirmId"
                >
                  <div>
                    <p class="mozzarella opacity-48">
                      Наименование
                    </p>
                    <p class="feta opacity-72 mt-1">
                      {{ details.contractorFirmName || '-' }}
                    </p>
                  </div>
                  <div class="mt-4">
                    <p class="mozzarella opacity-48">
                      ИНН
                    </p>
                    <p class="feta opacity-72 mt-1">
                      {{ details.contractorInn || '-' }}
                    </p>
                  </div>
                  <div class="flex mt-4">
                    <div class="mr-8">
                      <p class="mozzarella opacity-48">
                        Телефон
                      </p>
                      <p class="feta opacity-72 mt-1">
                        {{ details.contractorFirmPhone || '-' }}
                      </p>
                    </div>
                    <div>
                      <p class="mozzarella opacity-48">
                        Имейл
                      </p>
                      <p class="feta opacity-72 mt-1">
                        {{
                          details.contractorApplicantEmail || '-'
                        }}
                      </p>
                    </div>
                  </div>
                  <div class="mt-4">
                    <p class="mozzarella opacity-48">
                      Доверенное лицо
                    </p>
                    <p class="feta opacity-72 mt-1">
                      {{ details.contractorFirmDir || '-' }}
                    </p>
                  </div>
                  <div class="mt-4">
                    <p class="mozzarella opacity-48">
                      Почтовый адрес
                    </p>
                    <p class="feta opacity-72 mt-1">
                      {{ details.contractorPostAddress || '-' }}
                    </p>
                  </div>
                </div>
                <div class="flex mt-6">
                  <r-input
                    @blur="isChange('contractorNumber')"
                    v-model="details.contractorNumber"
                    class="mr-6 flex-1"
                    label="№ договора подряда"
                    :readonly="!details.isContractor"
                  />
                  <r-date-picker
                    isClearModel
                    click-close
                    class="flex-1"
                    label="Дата договора"
                    v-model="details.contractorDate"
                    :readonly="!details.isContractor"
                    @input="isChange('contractorDate')"
                  />
                </div>
              </div>

              <h2 class="bryndza mt-8">
                Намечаемые сроки производства работ
              </h2>
              <div class="mt-6 work-form__dashed-cell">
                <r-date-picker
                  isClearModel
                  click-close
                  :class="{ field_error: $v.details.dateFrom.$error }"
                  :start-date="dateNow"
                  label="Начало"
                  v-model="$v.details.dateFrom.$model"
                  @input="isChange('dateFrom')"
                  :error="$v.details.dateFrom.$error && !$v.details.dateFrom.required"
                  error-message="Поле обязательно для заполнения"
                />
                <i class="dash" />
                <r-date-picker
                  isClearModel
                  click-close
                  :class="{ field_error: $v.details.dateTo.$error }"
                  label="Окончание"
                  :readonly="!details.dateFrom"
                  :start-date="String(details.dateFrom)"
                  @input="isChange('dateTo')"
                  v-model="$v.details.dateTo.$model"
                  :error="$v.details.dateTo.$error && !$v.details.dateTo.required"
                  error-message="Поле обязательно для заполнения"
                />
              </div>
              <h2 class="bryndza mt-8">
                Нарушаемое в процессе работ благоустройство
              </h2>
              <r-textarea
                @input="isChange('restorationWorkDescription')"
                :rows="3"
                class="mt-6"
                label="Краткое описание проводимых работ"
                v-model="details.restorationWorkDescription"
              />
              <r-input
                @blur="isChange('excavationMethod')"
                v-model="details.excavationMethod"
                class="mt-6"
                label="Способ ведения земляных работ"
              />
              <r-input
                @blur="isChange('guaranteePeriod')"
                v-model="details.guaranteePeriod"
                class="mt-6"
                label="Гарантийный срок"
              />
              <r-button-action
                class="mt-7"
                title="Добавить элемент благоустройства"
                icon="add"
                @click.native="onAddImprovement"
              />
              <div
                class="improvement mt-4"
                v-if="details.improvements.length > 0"
              >
                <div
                  class="improvement__item"
                  v-for="item of details.improvements"
                  :key="item.id"
                >
                  <div class="mr-7 flex-1">
                    <r-input
                      label="Наименование благоустройства"
                      v-model="item.name"
                    />
                    <r-input
                      class="mt-6"
                      label="Площадь, м2"
                      :params-input="{ type: 'number' }"
                      v-model="item.square"
                    />
                  </div>
                  <div @click="onDeleteImprovement(item.id)">
                    <r-icon
                      size="16"
                      icon="delete"
                      fill="fargo"
                      class="pointer opacity-72"
                    />
                  </div>
                </div>
              </div>

              <r-checkbox
                v-model="details.isFence"
                class="mt-6 "
                title="Работы предполагают ограничение движения"
                @input="isChange('isFence')"
              />
              <div class="work-form__dashed-cell mt-6 align-items-center">
                <r-date-picker
                  isClearModel
                  click-close
                  label="Начало"
                  v-model="details.dateFromFence"
                  :readonly="!details.isFence"
                />
                <i class="dash" />
                <r-date-picker
                  isClearModel
                  click-close
                  label="Окончание"
                  v-model="details.dateToFence"
                  :readonly="!details.isFence"
                />
              </div>
              <h3 class="bryndza mt-8">
                Запрашиваемые сроки работ с учётом восстановления
                благоустройства
              </h3>
              <div class="work-form__dashed-cell mt-6 align-items-center">
                <r-date-picker
                  isClearModel
                  click-close
                  label="Начало"
                  v-model="details.requestedDatesFrom"
                />
                <i class="dash" />
                <r-date-picker
                  isClearModel
                  click-close
                  label="Окончание"
                  v-model="details.requestedDatesTo"
                />
              </div>
              <h3 class="bryndza mt-8 mb-4">
                Дополнительные документы
              </h3>
              <upload-file
                :count-file="5"
                upload-url="/uploadfile/index.php"
                :value="details.additionalDocuments"
                @input="loadAdditionalDocuments"
                :params-body="{ upload_url: 'excavators', privateUserId: $store.state.userId || -1 }"
                :count-line="1"
                sub-title="или перетяните сюда файлы общим объёмом не более 50 МБайт"
                :accept-file="[
                  'image/jpeg',
                  'image/png',
                  'image/gif',
                  'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
                  'application/pdf',
                  'application/msword',
                  'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
                  'text/csv',
                ]"
              />
            </div>
          </div>
          <r-button-action
            v-if="isEditPage && statement.status === '0'"
            class="mt-9 color-fargo sulguni"
            color="fargo"
            icon="delete"
            title="Удалить заявление"
            @click="onDelete"
          />
          <r-button-action
            v-if="isEditPage && statement.status === '1'"
            class="mt-9 color-lebowski sulguni"
            title="Отозвать с согласования"
            icon="close"
            @click="onRevoke"
          />
        </div>

        <div class="ml-6 flex-1 overflow-hidden">
          <div
            class="work-form__map"
            :class="{ disabled: !isEmpty(statement) && (statement.status === '1' || statement.status === '3') }"
          >
            <rir-map
              v-if="isMapReady"
              collapse-btn
              @click="onMapClick"
            >
              <ymap-marker
                v-if="markerCoords"
                :coords="markerCoords"
                :marker-id="'marker'"
                :icon="$markerIcon('mapPin')"
                :options="{ draggable: !(!isEmpty(statement) && (statement.status === '1' || statement.status === '3')) }"
                @dragend="onMarkerDragEnd"
              />
            </rir-map>
          </div>

          <div
            class="readable mt-6"
            v-if="!isEmpty(statement) && (statement.status === '1' || statement.status === '3')"
          >
            <div class="mb-4">
              <p class="mozzarella opacity-48">
                Адрес проведения работ
              </p>
              <p class="feta opacity-72 mt-1">
                {{ statement.details.address }}
              </p>
            </div>
            <div class="flex mb-4">
              <div class="mr-8">
                <p class="mozzarella opacity-48">
                  Широта
                </p>
                <p class="feta opacity-72 mt-1">
                  {{ statement.details.lat }}
                </p>
              </div>
              <div>
                <p class="mozzarella opacity-48">
                  Долгота
                </p>
                <p class="feta opacity-72 mt-1">
                  {{ statement.details.lng }}
                </p>
              </div>
            </div>
          </div>
          <div
            class="inputable"
            v-else
          >
            <div class="work-form__dashed-cell mt-4 align-items-center">
              <r-input
                label="Широта"
                :params-input="{ type: 'number' }"
                @blur="isChange('lat')"
                :class="{ field_error: $v.details.lat.$error }"
                v-model=" $v.details.lat.$model"
                :error="$v.details.lat.$error && !$v.details.lat.required"
                error-message="Поле обязательно для заполнения"
              />

              <i class="dash" />
              <r-input
                label="Долгота"
                :params-input="{ type: 'number' }"
                @blur="isChange('lng')"
                :class="{ field_error: $v.details.lng.$error }"
                v-model="$v.details.lng.$model"
                :error="$v.details.lng.$error && !$v.details.lng.required"
                error-message="Поле обязательно для заполнения"
              />
            </div>

            <r-input
              class="mt-6"
              label="Адрес"
              :class="{ field_error: $v.details.address.$error }"
              v-model="$v.details.address.$model"
              @blur="searchAddress(details.address)"
              @onPressEnter="searchAddress(details.address)"
              after-icon="search"
              :error="$v.details.address.$error && !$v.details.address.required"
              error-message="Поле обязательно для заполнения"
            />
          </div>
        </div>

        <div
          class="work-form__btn-cont"
          v-if="!isEditPage"
        >
          <r-button
            class="mr-6"
            @click="submit(1)"
            type="secondary"
            title="Добавить и отправить на согласование"
            width="wide"
          />
          <r-button
            @click="submit(0)"
            title="Добавить"
            width="wide"
          />
        </div>
        <div
          class="work-form__btn-cont"
          v-if="isEditPage && (statement.status === '0' || statement.status === '2')"
        >
          <r-button
            class="mr-6"
            :disabled="isCanSave && statement.status !== '0'"
            @click="submit(0, 'save')"
            type="secondary"
            title="Сохранить изменения"
            width="wide"
          />
          <r-button
            @click="submit(1, 'save')"
            :disabled="isCanSave && statement.status !== '0'"
            title="Отправить на согласование"
            width="wide"
          />
        </div>
      </div>
      <div
        class="edit_tab mt-8"
        v-show="activeTabId.id === 'history'"
      >
        <statement-history-tab
          v-if="!!statementInfo.all"
          :is-agreed="statement.status === '3' || statement.status === '2'"
          :is-refuse="statement.status === '2' || statement.status === '3'"
          :statement="statement"
          :statement-info="statementInfo"
        />
      </div>
    </div>
  </div>
</template>

<script>
import { minLength, required, requiredIf } from 'vuelidate/lib/validators';
import _ from 'lodash';
import AdminHeader from '../components/AdminHeader';
import Api from '../api/Api';
import RirMap from '@/components/RirMap.vue';
import DeleteModal from '../components/DeleteModal';
import { getDayAddition, formattedFiles } from '../helpers/utils';
import StatementOnlyReadable from '../components/StatementOnlyReadable';
import DropDownButton from '../components/DropDownButton';
import StatementHistoryTab from '../components/StatementHistoryTab';
import ModalReport from '../components/ModalReport';
import Loader from '../components/Loader';
import UploadFile from '../components/UploadFile';

export default {
  name: 'Statement',
  components: {
    Loader,
    AdminHeader,
    RirMap,
    StatementOnlyReadable,
    DropDownButton,
    StatementHistoryTab,
    UploadFile
  },
  data() {
    return {
      dateNow: '',
      activeTabId: {
        id: 'information',
        title: 'Данные'
      },
      isCanAddWork: false,
      editTabs: [
        {
          id: 'information',
          title: 'Данные'
        },
        {
          id: 'history',
          title: 'История'
        }
      ],
      statement: {},
      isCanSave: true,
      user: {},
      showOrganization: false,
      contractorShowOrganization: false,
      isMapReady: false,
      initialData: {},
      emptyDetails: {},

      details: {
        versions: [],
        projectName: null,
        coordinationETSP: false,
        documents: [],
        description: null,
        images: [],
        // Благоустройства
        improvements: [
          {
            id: 0,
            name: null,
            square: null
          }
        ],
        applicantStatus: null,
        isPetitionerIndividual: false,
        // ContactIndividual: null, // ФИО заявителя (Физ. лицо)
        applicantSurname: null, // фамилия заявителя
        applicantName: null, // имя заявителя
        applicantPatronymic: null, // отчество заявителя
        applicantAddress: null, // aдрес заявителя (Физ. лицо)
        applicantEmail: null,
        inn: null, // ИНН
        firmAccount: null, // Расчетный счет
        bank: null, // Банк
        firmId: null, // id организации
        firmName: null, // Наименование организации
        isAddressesMatch: false, // Совпадение юр. и почтового адреса
        firmAddress: null, // Адрес организации (Юридический) ???
        postAddress: null, // Почтовый адрес организации
        firmDir: null, // ФИО руководителя организации
        firmPhone: null, // Тел. ответственного   ???
        contractorFirmId: null,
        contractorInn: null, // ИНН
        contractorFirmAccount: null, // Расчетный счет
        contractorBank: null, // Банк
        contractorFirmName: null, // Наименование организации
        contractorFirmAddress: null, // Адрес организации (Юридический) ???
        contractorPostAddress: null, // Почтовый адрес организации
        contractorFirmDir: null, // ФИО руководителя организации
        contractorFirmPhone: null, // Тел. ответственного   ???
        contractorApplicantEmail: null,
        address: null, // Адрес разрытия
        lat: null, // Широта
        lng: null, // Долгота
        reports: [],
        dateFrom: null,
        dateTo: null,

        isContractor: false,
        contractorTitle: null,
        contractorNumber: null,
        contractorDate: null,
        contractorSurname: null,
        contractorName: null,
        contractorPatronymic: null,
        contractorPosition: null,
        contractorPhone: null,
        contractorEmail: null,

        restorationWorkDescription: null,
        excavationMethod: null,
        guaranteePeriod: null,
        isFence: false,
        dateFromFence: null,
        dateToFence: null,
        requestedDatesFrom: null,
        requestedDatesTo: null,

        additionalDocuments: []
      },
      statementInfo: {},
      onlyMineOrganization: []
    };
  },
  validations: {
    details: {
      projectName: {
        required
      },
      description: {
        required,
        minLength: minLength(20)
      },
      address: {
        required
      },
      lng: {
        required
      },
      lat: {
        required
      },
      applicantStatus: {
        required
      },
      applicantSurname: {
        required: requiredIf(details => details.applicantStatus === 0)
      },
      applicantName: {
        required: requiredIf(details => details.applicantStatus === 0)
      },
      applicantPatronymic: {
        required: requiredIf(details => details.applicantStatus === 0)
      },
      applicantAddress: {
        required: requiredIf(details => details.applicantStatus === 0)
      },
      firmPhone: {
        required: requiredIf(details => details.applicantStatus === 0)
      },
      applicantEmail: {
        required: requiredIf(details => details.applicantStatus === 0)
      },
      firmId: {
        required: requiredIf(details => details.applicantStatus === 1)
      },
      dateTo: {
        required
      },
      dateFrom: {
        required
      },
      contractorFirmId: {
        required: requiredIf(details => details.applicantStatus === 1 && details.isContractor)
      }
    }
  },
  computed: {
    isContractor() {
      if (this.statement?.userId && this.statement?.confidant?.userId) {
        return true;
      }
      return false;
    },
    isLoading() {
      return this.$store.state.statements.isLoading;
    },
    markerCoords() {
      const { lat } = this.details;
      const { lng } = this.details;
      if (lat && lng) {
        return [lng, lat];
      }

      return null;
    },
    orgList() {
      const orgList = [...this.$store.state.organizations]
        .filter(org => org.details.isActive)
        .map(el => ({
          id: el.id,
          title: el.details.title
        }));

      return orgList;
    },
    orgListOnlyMine() {
      const orgList = [...this.onlyMineOrganization]
        .filter(org => org.details.isActive)
        .map(el => ({
          id: el.id,
          title: el.details.title
        }));

      return orgList;
    },
    isCopy() {
      return !!this.$route.query.copy;
    },
    isEditPage() {
      return !!this.$route.query.id && !this.isCopy;
    },
    fileDownloadMenu() {
      const menu = [
        {
          id: 1,
          active: false,
          value: 'Лист согласования ресурсными организациями',
          document: 'excavatorProjectAlignmentsDOCX'
        }
      ];

      return menu;
    }
  },
  async created() {
    this.emptyDetails = this.details;
    if (this.$store.state.organizations.length === 0) {
      await this.$store.dispatch('fetchOrganizations');
    }
    if (this.onlyMineOrganization.length === 0) {
      await new Api().fetchOrganizations({
        onlyMine: this.$store.getters.userIsRoot ? 0 : 1
      }).then(res => {
        this.onlyMineOrganization = res.all;
      });
    }

    this.isMapReady = true;
  },
  activated() {
    this.statement = {};
    this.resetForm();
    (this.isEditPage || this.isCopy) && this.setData();
    this.isMapReady = true;
    this.dateNow = new Date().toLocaleString('En-en', {
      day: 'numeric',
      month: 'numeric',
      year: 'numeric'
    });
  },
  deactivated() {
    // this.intervalID && clearInterval(this.intervalID);
    this.isMapReady = false;
    this.activeTabId = {
      id: 'information',
      title: 'Данные'
    };
  },
  methods: {
    onDeleteImprovement(id) {
      const index = this.details.improvements.findIndex(item => item.id === id);
      this.details.improvements.splice(index, 1);
      this.isChange('improvements');
    },
    async onFileDownload(obj) {
      const api = new Api();
      let res = {};

      if (obj.id === 1) {
        res = await api.printExcavators({
          document: obj.document,
          projectId: this.$route.query.id
        });
      }

      if (res?.url) {
        window.open(res.url, '_blank');
      }
    },

    isEmpty(obj) {
      return _.isEmpty(obj);
    },
    formatDay(num) {
      return getDayAddition(num);
    },
    async onRevoke() {
      // отозвать -1
      const data = {
        action: 'updateJson',
        alignment: this.$store.getters.getChoicestOrganizations,
        item: {
          id: this.statement.id,
          casse: -1,
          organizationId: -1,
          details: this.details
        }
      };
      await this.$store.dispatch('saveStatement', data).then(() => {
        const r = this.$router.resolve({
          name: 'index', // надо возвращать на тот таб, из которого удалили
          query: {
            tab: 'statements'
          }
        });
        window.location.assign(r.href);
      });
    },
     onDelete() {
       this.$rir.modal.open(DeleteModal, {
        id: this.$route.query.id,
        actionPostfix: 'Statement'
      });
    },
    resetForm() {
      this.details = JSON.parse(JSON.stringify(this.emptyDetails));
      this.isResetForm = false;
    },
    async submit(casse, type) {
      this.$v.$touch();
      if (this.$v.$invalid) {
        this.$nextTick(() => {
          document.querySelector('.field_error').scrollIntoView({
            behavior: 'smooth',
            block: 'center'
          });
        });
      } else {
        let data = {
          action: 'updateJson',
          alignment: this.statement.alignment?.length > 0 ? this.statement.alignment : this.$store.state.participants.choicestOrganizations, // this.$store.state.participants.choicestOrganizations,
          item: {
            needPersonalSign: this.details.coordinationETSP,
            title: this.details.projectName,
            firm: this.details.firmName || `${this.details.applicantName} ${this.details.applicantPatronymic} ${this.details.applicantSurname}`,
            contractorId: this.details.contractorFirmId || '',
            id: -1,
            casse,
            organizationId: -1,
            details: this.details
          }
        };
        if (type === 'save') {
          data = {
            action: 'updateJson',
            alignment: this.statement.alignment,
            item: {
              needPersonalSign: this.details.coordinationETSP,
              title: this.details.projectName,
              firm: this.details.firmName || `${this.details.applicantName} ${this.details.applicantPatronymic} ${this.details.applicantSurname}`,
              contractorId: this.details.contractorFirmId || '',
              id: this.statement.id,
              casse: this.statement.status === '2' && casse === 0 ? -1 : casse,
              organizationId: this.statement.organizationId,
              details: this.details
            }
          };
        }
        data.item.details.versions = this.statement.versions;

        await this.$store.dispatch('saveStatement', data).then(() => {
          const r = this.$router.resolve({
            name: 'index',
            query: {
              tab: 'statements'
            }
          });
          window.location.assign(r.href);
        });
      }
    },

    onAddImprovement() {
      this.details.improvements.push(
        {
          id: this.details.improvements?.length + 1,
          name: null,
          square: null
        }
      );
      this.isChange('improvements');
    },
    loadAdditionalDocuments(files) {
      if (!files?.length) {
        this.details.additionalDocuments = [];
        return;
      }
      this.details.additionalDocuments = formattedFiles(files);
      this.isChange('additionalDocuments');
    },
    loadDocuments(files) {
      if (!files?.length) {
        this.details.documents = [];
        return;
      }
      this.details.documents = formattedFiles(files);
      this.isChange('documents');
    },
    loadImages(files) {
      if (!files?.length) {
        this.details.images = [];
        return;
      }
      this.details.images = formattedFiles(files);
      this.isChange('images');
    },
    isChange(field) {
      if (this.initialData[field] === this.details[field]) {
        this.isCanSave = true;
      } else {
        this.isCanSave = false;
      }
    },
    onMarkerDragEnd(e) {
      if (!this.isEmpty(this.statement) && (this.statement.status === '1' || this.statement.status === '3')) {

      } else {
        const coords = e.originalEvent.target.geometry.getCoordinates();
        if (coords?.length === 2) {
          this.details.lng = coords[0]; // lng
          this.details.lat = coords[1]; // lat
          this.searchAddress(coords);
        }
      }
    },

    async onMapClick(coords) {
      if (!this.isEmpty(this.statement) && (this.statement.status === '1' || this.statement.status === '3')) {

      } else {
        if (coords?.length === 2) {
          this.details.lng = coords[0];
          this.details.lat = coords[1];
        }
        this.searchAddress(coords);
      }
    },
    async searchAddress(address) {
      if (!this.isEmpty(this.statement) && (this.statement.status === '1' || this.statement.status === '3')) {

      } else {
        if (!address) return;
        const api = new Api();

        const data = {};
        const isFindAddress = Array.isArray(address);

        if (isFindAddress) {
          data.lng = address[0];
          data.lat = address[1];
        } else {
          data.address = this.details.address;
        }

        const res = await api.getAddress(JSON.stringify(data));

        if (isFindAddress && res?.address) {
          if (res?.addressFull?.street && res?.addressFull?.house) {
            this.details.address = `${res.addressFull.street} ${res.addressFull.house}`;
          } else {
            this.details.address = res.address;
          }
        } else if (!isFindAddress && res?.lat && res?.lng) {
          this.details.Lat = res.lat;
          this.details.Lng = res.lng;
        }
        this.isChange('address');
      }
    },
    onChangeContractorOrganization(val) {
      const orgDetails = { ...this.$store.getters.getOrganizationById(val.id).details };
      this.details.contractorInn = orgDetails.inn;
      this.details.contractorFirmAccount = orgDetails.bankAccount;
      this.details.contractorBank = orgDetails.bankName;
      this.details.contractorFirmAddress = orgDetails.address;
      this.details.contractorPostAddress = orgDetails.postalAddress;
      this.details.contractorFirmDir = orgDetails.confidant.fio;
      this.details.contractorFirmName = orgDetails.title;
      this.details.contractorFirmPhone = orgDetails.confidant.phone;
      this.details.contractorApplicantEmail = orgDetails.confidant.email;
      this.contractorShowOrganization = false;
      this.isChange('contractorFirmId');
    },
    onChangeApplicant() {
      // сброс данных при смене типа заявителя
      this.details.firmId = null;
      this.details.firmName = null;
      this.details.inn = null;
      this.details.firmAccount = null;
      this.details.isAddressesMatch = null;
      this.details.bank = null;
      this.details.firmPhone = null;
      this.details.applicantEmail = null;
      this.details.firmDir = null;
      this.details.postAddress = null;
      this.isChange('firmId');
      this.details.contractorInn = null;
      this.details.contractorFirmAccount = null;
      this.details.contractorBank = null;
      this.details.contractorFirmAddress = null;
      this.details.contractorPostAddress = null;
      this.details.contractorFirmDir = null;
      this.details.contractorFirmName = null;
      this.details.contractorFirmPhone = null;
      this.details.contractorApplicantEmail = null;
      this.details.contractorNumber = null;
      this.details.contractorDate = null;
      this.contractorShowOrganization = false;
      this.isChange('contractorFirmId');
    },
    onChangeOrganization(val) {
      console.log(val);
      const orgDetails = { ...this.$store.getters.getOrganizationById(val.id).details };
      this.details.inn = orgDetails.inn;
      this.details.firmAccount = orgDetails.bankAccount;
      this.details.bank = orgDetails.bankName;
      this.details.firmAddress = orgDetails.address;
      this.details.postAddress = orgDetails.postalAddress;
      this.details.isAddressesMatch = orgDetails.isAddressesMatch;
      this.details.firmDir = orgDetails.confidant.fio;
      this.details.firmName = orgDetails.title;
      this.details.firmPhone = orgDetails.confidant.phone;
      this.details.applicantEmail = orgDetails.confidant.email;
      this.showOrganization = false;
      this.isChange('firmId');
    },
    async setData() {
      if (this.$route.query.id) {
        new Api().getStatements({
          projectId: this.$route.query.id,
          casse: 6
        }).then(res => {
          this.statementInfo = res;
          const trueLength = this.statementInfo.all.filter(item => item.status === '2').length;
          this.isCanAddWork = trueLength === this.statementInfo.all.length;
          setTimeout(() => {
            this.$store.commit('setLoading', false);
          }, 500);
        });
      }
      const statement = await this.$store.dispatch('getStatementById', this.$route.query.id);
      this.details = { ...this.details, ...statement.all[0].details };
      this.statement = statement.all[0];
      /* const options = {
         day: 'numeric',
         month: 'numeric',
         year: 'numeric',
         hour: 'numeric',
         minute: 'numeric'
       };

       this.created = new Date(work.created).toLocaleString('Ru-ru', options);

       this.ended = new Date(this.details.completed_info.date).toLocaleString('Ru-ru', options); */
      this.initialData = JSON.parse(JSON.stringify(this.details));
    }
  }

};
</script>

<style scoped lang="scss">
.work-form {
  min-height: 100vh;
  padding-bottom: 130px;

  &__dashed-cell {
    display: grid;
    grid-template-columns: 1fr 24px 1fr;
    align-items: center;
  }

  &__double-dashed-cell {
    display: grid;
    grid-template-columns: 1fr 24px 1fr 24px 1fr;
    align-items: center;
  }

  &__map {
    height: 522px;
    overflow: hidden;
  }

  &__btn-cont {
    position: fixed;
    right: 0;
    bottom: 0;
    width: calc(100% - 25.3%);
    background-color: #ffffff;
    z-index: 2;
    padding: 26px 40px 26px 32px;
    box-shadow: 0px 9px 28px rgba(17, 48, 121, 0.18);
  }
}

::v-deep .ya-map-point {
  position: absolute;
  left: -4px;
  top: -4px;
  display: block;
  width: 8px;
  height: 8px;
  background-color: #fff;
  border: 2px solid var(--rir-rocky);
  border-radius: 50%;
}

::v-deep .ya-map-point--edge {
  cursor: pointer !important;
  position: absolute;
  left: -3px;
  top: -3px;
  display: block;
  width: 6px;
  height: 6px;
  background-color: var(--rir-rocky);
  border-radius: 50%;
  opacity: 0.8;
}

.dash {
  width: 8px;
  height: 1px;
  background-color: var(--rir-rocky);
  margin: auto;
}

.m-auto {
  margin: auto;
}

::v-deep textarea:focus {
  background: transparent !important;
}

.improvement {
  display: grid;
  grid-gap: 24px;

  &__item {
    padding: 24px;
    background: #F6F9FE;
    border-radius: 16px;
    display: flex;
    align-items: center;
    width: 100%;
  }
}

::v-deep .r-drag-upload-file__remove svg {
  fill: var(--rir-fargo) !important;
}

.field_wrapper {
  position: relative;

  .field_error {

    &.r-input {
      background: rgba(#E14761, 0.08);
    }

    &.r-textarea {
      background: rgba(#E14761, 0.08);
    }

    ::v-deep .r-input {
      background: rgba(#E14761, 0.08);
    }
  }

  .error {
    position: absolute;
    font-size: 12px;
    top: 1px;
    right: 16px;
    pointer-events: none;

    &.bottom {
      top: calc(100% + 1px);
      right: unset;
      left: 16px;
    }
  }
}

.disabled_tabs {
  pointer-events: none;

  .opacity-32 {
    opacity: .32 !important;
  }
}

.work-form__btn-cont {
  display: flex;
  align-items: center;
}

::v-deep .r-file-list__img {
  flex-shrink: 0;
}

.disabled {
  pointer-events: none;
  opacity: 0.72;
}

</style>
