<template>
  <div>
    <div class="flex mb-6">
      <router-link
        class="flex align-items-center sulguni color-rocky mr-6"
        :to="{ name: `${activeTabId}` }"
      >
        <r-icon
          size="16"
          fill="rocky"
          class="mr-2"
          icon="add"
        />
        Добавить заявление
      </router-link>
    </div>
    <div class="objects__filter">
      <!-- <div class="icon-div">
         <r-date-picker
           clickClose
           label="Дата"
           v-model="filterDate"
         />
         <div style="display: table" @click="deleteFilterDate">
           <r-icon size="16" fill="rocky" v-show="filterDate" icon="close"
                     fill="titanic" class="anie icon-close"/>
         </div>
       </div>-->
      <div class="flex">
        <div class="icon-div">
          <r-date-picker
            click-close
            label="Заявления с"
            v-model="filterStartDate"
          />
          <div
            style="display: table"
            @click="deleteStart"
          >
            <r-icon
              size="16"
              v-show="filterStartDate"
              icon="close"
              fill="titanic"
              class="anie icon-close"
            />
          </div>
        </div>
        <div class="icon-div">
          <r-date-picker
            class="ml-4"
            click-close
            label="Заявления по"
            v-model="filterEndDate"
          />
          <div
            style="display: table"
            @click="deleteEnd"
          >
            <r-icon
              size="16"
              v-show="filterEndDate"
              icon="close"
              fill="titanic"
              class="anie icon-close"
            />
          </div>
        </div>
      </div>
      <r-select
        class="ml-4 mr-4"
        :items="sortStatus"
        label="Статус"
        v-model="selectedSortStatus"
      />

      <r-input
        class="flex-1 mr-6"
        label="Поиск"
        v-model.trim="search"
        beforeIcon="search"
      />

      <!--  <r-button
          class="ml-4"
          icon="icon-view"
          @click="selectedView = 'table'"
          :color="selectedView === 'map' ? 'secondary' : 'primary'"
        />
        <r-button
          class="ml-2"
          icon="geopoint"
          :color="selectedView === 'map' ? 'primary' : 'secondary'"
          iconSize="20"
          @click="selectedView = 'map'"
        />-->
    </div>
    <div class="mt-6 flex flex-end">
      <div class="ml-auto parmigiano color-titanic opacity-48">
        {{ !!searchText ? 'Найдено' : 'Всего' }} {{ countRow }}
      </div>
    </div>
    <loader v-if="isLoading" />
    <template v-else>
      <div
        class="objects__list mt-6"
      >
        <statements-card
          v-for="statement in filteredStatements"
          :active-tab-id="activeTabId"
          :key="statement.id"
          :statement="statement"
        />
      </div>
      <not-found v-if="countRow < 1" />
    </template>
  </div>
</template>

<script>
import StatementsCard from './StatementsCard';
import notFound from './notFound';
import Loader from "./Loader";

export default {
  name: 'StatementsTab',
  components: {
    Loader,
    StatementsCard,
    notFound
  },
  props: ['activeTabId'],
  data() {
    return {
      filterStartDate: null,
      filterEndDate: null,
      filterDoneStartDate: null,
      filterDoneEndDate: null,

      selectedView: 'table',
      filterDate: null,
      sortStatus: [
        {
          id: -1,
          title: 'Все'
        },
        {
          id: 0,
          title: 'Черновик'
        },
        {
          id: 1,
          title: 'На согласовании'
        },
        {
          id: 2,
          title: 'Возврат'
        },
        {
          id: 3,
          title: 'Согласовано'
        }
      ],
      searchText: null,
      selectedSortStatus: -1
    };
  },
  computed: {
    countRow() {
      return this.filteredStatements.length;
    },
    filteredStatements() {
      let list = this.$store.state.statements.statements;
      /* if (!!this.filterDate) {
         let date =  new Date(this.filterDate)
         date.setHours(0)
         list = list.filter(el => new Date(el.createdF).getTime() === date.getTime())
       } */
      // Если заполнены даты
      if (!!this.filterStartDate || !!this.filterEndDate) {
        const start = new Date(this.filterStartDate || new Date(0));
        start.setHours(23, 59, 59, 59);
        // start.setDate(start.getDate() - 1);
        const end = new Date(this.filterEndDate || '01.01.3000');
        end.setHours(23, 59, 59, 59);
        const dateFilter = date => {
          const stamp = new Date(date);
          return stamp >= start && end >= stamp;
        };

        list = list.filter(el => dateFilter(el.created));
      }

      // Если заполнена строка поиска
      if (this.searchText) {
        list = list.filter(
          el => el?.details.address?.toLowerCase().indexOf(this.searchText) > -1
            || el?.details.projectName?.toLowerCase().indexOf(this.searchText) > -1
        );
      }

      // фильтрация по тегам
      if (this.selectedSortStatus !== -1) {
        list = list.filter(el => el.status === String(this.selectedSortStatus));
      }

      return list;
    },
    search: {
      get() {
        return this.searchText;
      },
      set(val) {
        if (this.timeoutId) clearTimeout(this.timeoutId);
        this.timeoutId = setTimeout(() => {
          this.searchText = typeof val === 'string' ? val.toLowerCase() : null;
        }, 420);
      }
    },
    isLoading() {
      return this.$store.state.statements.isLoading;
    }
  },
  async mounted() {
    await this.$store.dispatch('getStatements', {
      currentMainMenuNumber: 18,
      casse: 2,
      onlyMine: this.$store.getters.userIsRoot ? 0 : 1
    });
  },
  methods: {
    deleteStart() {
      this.filterStartDate = null;
    },
    deleteEnd() {
      this.filterEndDate = null;
    },
    deleteFilterDate() {
      this.filterDate = null;
    },
    onChangeStartDate(date) {
      if (typeof date === 'string') {
        this.filterDoneStartDate = date;
      } else {
        this.filterDoneStartDate = null;
      }
    },

    onChangeEndDate(date) {
      if (typeof date === 'string') {
        this.filterDoneEndDate = date;
      } else {
        this.filterDoneEndDate = null;
      }
    }
  }

};
</script>

<style scoped lang="scss">
.icon-close {
  cursor: pointer;
  position: absolute;
  margin-left: calc(100% - 60px);
  margin-top: -28px;
  z-index: 1;
}

.icon-div {
  display: table;
  position: relative;
}

.objects {
  &__filter {
    display: flex;
  }

  &__list {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 4px;

    > * {
      min-width: 0;
    }
  }

  &__map {
    height: 700px;
    border-radius: 24px;
    overflow: hidden;
  }
}
</style>
