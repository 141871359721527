<template>
  <div class="objects">
    <admin-header
      title="Земляные работы"
      :back="false"
    />
    <!--  <span v-if="this.$store.state.alignmentInfo">
      {{
        this.$store.state.alignmentInfo.alignment === '1' ? "Вы - согласующая организация" : "Вы - организация-заявитель"
      }}</span>
      -->
    <r-tabs
      class="mt-7 mb-6"
      :items="tabs"
      v-model="activeTabId"
    />

    <work-tab
      v-if="activeTabId.id === 'work'"
      :active-tab-id="activeTabId.id"
    />
    <organizations-tab
      v-if="activeTabId.id === 'organizations'"
      :active-tab-id="activeTabId.id"
    />
    <participants-agreement-tab
      v-if="activeTabId.id === 'participantsAgreement'"
      :active-tab-id="activeTabId.id"
    />
    <statements-tab
      v-if="activeTabId.id === 'statements'"
      :active-tab-id="activeTabId.id"
    />
    <approvals-tab
      v-if="activeTabId.id === 'approvals'"
      :active-tab-id="activeTabId.id"
    />
  </div>
</template>

<script>

import AdminHeader from '@/components/AdminHeader.vue';
import WorkTab from '../components/WorkTab';
import OrganizationsTab from '../components/OrganizationsTab';
import ParticipantsAgreementTab from '../components/ParticipantsAgreementTab';
import StatementsTab from '../components/StatementsTab';
import ApprovalsTab from '../components/ApprovalsTab';

export default {
  name: 'ObjectList',
  components: {
    AdminHeader,
    WorkTab,
    OrganizationsTab,
    ParticipantsAgreementTab,
    StatementsTab,
    ApprovalsTab
  },
  data() {
    return {
      activeTabId: {
        id: 'work',
        title: 'Работы'
      },
      tabs: []
    };
  },
  async activated() {
    this.tabs = [
      {
        id: 'work',
        title: 'Работы'
      },
      {
        id: 'organizations',
        title: 'Организации'
      },
      {
        id: 'statements',
        title: 'Заявления'
      }
    ];
    if (this.$store.getters.userIsRoot) {
      this.tabs = [
        {
          id: 'work',
          title: 'Работы'
        },
        {
          id: 'organizations',
          title: 'Организации'
        },
        {
          id: 'participantsAgreement',
          title: 'Участники согласования'
        },
        {
          id: 'statements',
          title: 'Заявления'
        }
      ];
    }
    await this.$store.dispatch('checkExcavatorIsAlignment').then(() => {
      if (this.$store.state?.alignmentInfo?.alignment === '1') {
        this.tabs.push({
          id: 'approvals',
          title: 'Согласования'
        });
      }
    });

    this.activeTabId = this.tabs.find(item => item.id === this.$route.query.tab) || {
      id: 'work',
      title: 'Работы'
    };
  }
};
</script>

<style lang="scss" scoped>
.icon-close {
  cursor: pointer;
  position: absolute;
  margin-left: calc(100% - 60px);
  margin-top: -28px;
}

.icon-div {
  display: table;
  position: relative;
}

.objects {
  &__filter {
    display: flex;
  }

  &__list {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 4px;

    > * {
      min-width: 0;
    }
  }

  &__map {
    height: 700px;
    border-radius: 24px;
    overflow: hidden;
  }
}

.r-popover {
  display: flex;
  align-items: center;

  .r-icon {
    &.add-icon {
      margin-right: 8px;
    }

    &.data-download__arrow {
      margin-left: 8px;
    }
  }
}

.data-download {
  &__wrapper {
    padding: 12px;

    .r-button {
      display: flex;
      align-items: center;

      .r-icon {
        margin-right: 8px;
      }
    }
  }
}

::v-deep .r-popover__content {
  padding: 8px !important;
}

::v-deep .ya-map-point {
  position: absolute;
  left: -4px;
  top: -4px;
  display: block;
  width: 8px;
  height: 8px;
  background-color: #fff;
  border: 2px solid var(--rir-rocky);
  border-radius: 50%;
}

::v-deep .ya-map-point--edge {
  cursor: pointer !important;
  position: absolute;
  left: -3px;
  top: -3px;
  display: block;
  width: 6px;
  height: 6px;
  background-color: var(--rir-rocky);
  border-radius: 50%;
  opacity: 0.8;
}

::v-deep .r-popover__content {
  background: red;
}
</style>
