<template>
  <div>
    <report-item
      v-for="report of filteredReports"
      :key="report.id"
      :report="report"
    />
  </div>
</template>

<script>
import reportItem from './ReportItem';

export default {
  name: 'ReportTab',
  components: {
    reportItem
  },
  props: {
    reports: {
      required: true,
      type: Array
    }
  },
  computed: {
    filteredReports() {
      return this.$props.reports;
    }
  }
};
</script>

<style scoped>

</style>
