<template>
  <div>
    <div class="objects__filter">
      <div class="icon-div">
        <r-date-picker
          click-close
          label="Дата"
          v-model="filterDate"
        />
        <div
          style="display: table"
          @click="deleteFilterDate"
        >
          <r-icon
            size="16"
            v-show="filterDate"
            icon="close"
            fill="titanic"
            class="anie icon-close"
          />
        </div>
      </div>
      <r-select
        class="ml-4 mr-4"
        :items="sortStatus"
        label="Статус"
        v-model="selectedSortStatus"
      />

      <r-input
        class="flex-1"
        label="Поиск"
        v-model.trim="search"
        before-icon="search"
      />
      <!--
      <r-button
        class="ml-4"
        icon="icon-view"
        @click="selectedView = 'table'"
        :color="selectedView === 'map' ? 'secondary' : 'primary'"
      />
      <r-button
        class="ml-2"
        icon="geopoint"
        :color="selectedView === 'map' ? 'primary' : 'secondary'"
        iconSize="20"
        @click="selectedView = 'map'"
      />-->
    </div>
    <div class="mt-6 flex justify-content-between align-items-center">
      <r-checkbox
        v-model="showOnlyExpired"
        title="Показывать только просроченные"
      />
      <div class="ml-auto parmigiano color-titanic opacity-48">
        {{ !!searchText ? 'Найдено' : 'Всего' }} {{ countRow }}
      </div>
    </div>
    <loader v-if="isLoading" />
    <template v-else>
      <div
        v-if="selectedView === 'map'"
        class="objects__map mt-6"
        ref="map"
      >
        <!-- v-if="filteredWorks.length && isShowMap" -->
        <RirMap
          v-if="isShowMap"
          show-type
          @click="onMapClick"
          :controls="['trafficControl']"
          :show-traffic="isShowTraffic"
        >
          <div v-if="filteredWorks.length">
            <ymap-marker
              v-for="marker in filteredWorks"
              :key="`work_${marker.id}`"
              :coords="marker.coords"
              :marker-id="`marker_${marker.id}`"
              :icon="marker.completed_info.isComplete ? $markerIcon('excavation-done') : $markerIcon('excavation')"
              :options="{ hideIconOnBalloonOpen: false }"
              cluster-name="main"
              @click="onMarkerClick(marker.id)"
              @balloonopen="bindListener"
              @balloonclose="unbindListener"
            >
              <ExcavationBalloon
                slot="balloon"
                v-bind="marker"
                :data-id="marker.id"
              />
            </ymap-marker>
          </div>
        </RirMap>
      </div>
      <template v-else>
        <div
          class="objects__list mt-6"
        >
          <statements-card
            v-for="statement in filteredStatements"
            :active-tab-id="activeTabId"
            :key="statement.id"
            :statement="statement"
          />
        </div>
        <not-found v-if="countRow < 1" />
      </template>
    </template>
  </div>
</template>

<script>
import StatementsCard from './StatementsCard';
import notFound from './notFound';
import Loader from './Loader';

export default {
  name: 'ApprovalsTab',
  components: {
    Loader,
    StatementsCard,
    notFound
  },
  props: ['activeTabId'],
  data() {
    return {
      selectedView: 'table',
      filterDate: null,
      showOnlyExpired: false,
      sortStatus: [
        {
          id: -1,
          value: 'Все'
        },
        {
          id: 2,
          value: 'Согласовано мной'
        },
        {
          id: 0,
          value: 'На согласовании'
        },
        {
          id: 1,
          value: 'Возврат'
        }

      ],
      searchText: null,
      selectedSortStatus: -1
    };
  },
  computed: {
    countRow() {
      return this.filteredStatements.length;
    },
    filteredStatements() {
      let list = this.$store.state.statements.statements;
      if (this.filterDate) {
        const date = new Date(this.filterDate);
        date.setHours(0);
        list = list.filter(el => new Date(el.createdF).getTime() === date.getTime());
      }
      // Если заполнена строка поиска
      if (this.searchText) {
        list = list.filter(
          el => el?.details.address?.toLowerCase().indexOf(this.searchText) > -1
            || el?.details.projectName?.toLowerCase().indexOf(this.searchText) > -1
        );
      }

      // фильтрация по тегам
      if (this.selectedSortStatus !== -1) {
        list = list.filter(el => el.alignment[0].status === String(this.selectedSortStatus));
      }
      if (this.showOnlyExpired) {
        list = list.filter(el => el.alignment[0].status === '0' && el.alignment[0].delayCreated > 0);
      }
      return list;
    },
    search: {
      get() {
        return this.searchText;
      },
      set(val) {
        if (this.timeoutId) clearTimeout(this.timeoutId);
        this.timeoutId = setTimeout(() => {
          this.searchText = typeof val === 'string' ? val.toLowerCase() : null;
        }, 420);
      }
    },
    isLoading() {
      return false;
      return this.$store.getters.getLoading;
    }
  },
  async mounted() {
    await this.$store.dispatch('getStatements', {
      currentMainMenuNumber: 18,
      casse: 4
    });
  },
  methods: {
    deleteFilterDate() {
      this.filterDate = null;
    }
  }

};
</script>

<style scoped lang="scss">
.icon-close {
  cursor: pointer;
  position: absolute;
  margin-left: calc(100% - 60px);
  margin-top: -28px;
  z-index: 1;
}

.icon-div {
  display: table;
  position: relative;
}

.objects {
  &__filter {
    display: flex;
  }

  &__list {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 4px;

    > * {
      min-width: 0;
    }
  }

  &__map {
    height: 700px;
    border-radius: 24px;
    overflow: hidden;
  }
}
</style>
